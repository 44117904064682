import { Box, IconButton, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { ArrayField, Controller, useFormContext } from "react-hook-form";

type Props = {
  zoneIndex: number;
  zipEntryIndex: number;
  remove: (index: number | number[] | undefined) => void;
  field: Partial<ArrayField<Record<string, any>, "key">>;
};

const CompanyZoneZipEntry = ({ zoneIndex, zipEntryIndex, remove, field }: Props) => {
  const { errors, register } = useFormContext();

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center" height={48} width={200} paddingX={1}>
        <input
          name={`zones[${zoneIndex}].zipEntries[${zipEntryIndex}].id`}
          value={field?.id}
          type="hidden"
          ref={register()}
        />
        <Controller
          name={`zones[${zoneIndex}].zipEntries[${zipEntryIndex}].zipFrom`}
          defaultValue={field.zipFrom || ""}
          render={props => (
            <TextField
              {...props}
              margin="none"
              InputProps={{ style: { height: 30 } }}
              error={!!errors?.rangeFrom}
            />
          )}
        />
        <Box p={1}>-</Box>
        <Controller
          name={`zones[${zoneIndex}].zipEntries[${zipEntryIndex}].zipTo`}
          defaultValue={field.zipTo || ""}
          render={props => (
            <TextField
              {...props}
              margin="none"
              InputProps={{ style: { height: 30 } }}
              error={!!errors?.zipTo}
            />
          )}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" height={48}>
        <IconButton size="small" onClick={() => remove(zipEntryIndex)}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CompanyZoneZipEntry;
