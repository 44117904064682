import axios from "config/axios";
import { GenericPageResponse, ShippingPrice } from "shared/types";

const ENDPOINT = "/shippingprice";

export const craeteShippingPrice = (data: {
  packagesStatusId: number;
  packagesId: number;
  vehicleId: number;
  netPrice: number;
}) => axios.post(ENDPOINT, { param: data });

export const getShippingPrices = ({
  page,
  size,
  search = "",
}: {
  page: number;
  size: number;
  search?: string;
}) =>
  axios.get<GenericPageResponse<ShippingPrice>>(
    `${ENDPOINT}/page?page=${page}&size=${size}&search=${search}`,
  );

export const modifyShippingPrice = ({ id, price }: { id: number; price: number }) =>
  axios.post(`${ENDPOINT}/net-price?id=${id}&netPrice=${price}`);
