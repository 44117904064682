import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
  User,
  UserModifyRequest,
  UserPermission,
  UserRegistrationRequest,
} from "shared/types";

const ENDPOINT = "/user";

export const getAccount = () =>
  axios.get<{ user: User; permissions: UserPermission[] }>(`${ENDPOINT}/account`);

export const createUser = (data: UserRegistrationRequest) => axios.post(ENDPOINT, data);

export const userLogout = () => axios.get(`${ENDPOINT}/logout`);

export const userList = () => axios.get<GenericListResponse<User>>(`${ENDPOINT}/list`);

export const signUpFinish = (data: { password: string; token: string }) =>
  axios.post(`${ENDPOINT}/finish-registration`, data, { timeout: 404040140 });

export const getUserByToken = (token: string) =>
  axios.get(`${ENDPOINT}/get-by-token?token=${token}`);

export const listUsersByCompany = (companyId: number) =>
  axios.get<GenericListResponse<User>>(`${ENDPOINT}/list-by-company?companyId=${companyId}`);

export const getUserById = (userId: number) =>
  axios.get<GenericResponse<User>>(`${ENDPOINT}/${userId}`);

export const modifyUser = (data: UserModifyRequest) =>
  axios.put<GenericResponse<User>>(ENDPOINT, data);

export const forgottenPasswordStart = (data: string) =>
  axios.post<GenericResponse<String>>(`${ENDPOINT}/forgotten`, { param: data });

export const forgottenPasswordFinish = (data: {
  newPassword: string;
  newPassword2: string;
  token: string;
}) => axios.post(`${ENDPOINT}/forgotten/finish`, data);

export const forgottenPasswordCancel = (data: { token: string; password: string }) =>
  axios.post(`${ENDPOINT}/forgotten/cancel`, data);

export const getAllUser = (page = 0, size = 10, predicates: string = "") =>
  axios.post<GenericPageResponse<User>>(
    `${ENDPOINT}/page?page=${page}&size=${size}&search=${encodeURIComponent(predicates)}`,
  );

export const banUser = (userId: number) =>
  axios.put<GenericResponse<User>>(`${ENDPOINT}/ban?id=${userId}`);
