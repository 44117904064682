import Layout from "components/layout/Layout";
import CompanyForm, { CompanyFormFields } from "./components/CompanyForm";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SliceStatus } from "shared/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { getCompanyById, modifyCompany } from "shared/network/company.api";
import { useQuery } from "react-query";

const CompanyModify = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(SliceStatus.Idle);
  const id = match.params.id;

  const companyQuery = useQuery("companyQueryCompanyModify", async () => {
    const { data } = await getCompanyById(Number.parseInt(id));
    return data;
  });

  const methods = useForm<CompanyFormFields>();

  useEffect(() => {
    if (companyQuery.data) {
      methods.reset({
        company: companyQuery.data.company,
        shop: companyQuery.data.shop,
        isShop: !!companyQuery.data.company?.isShopKeeper,
        shopAddressList: companyQuery.data.addressList,
      });
    }
  }, [companyQuery?.data]); //eslint-disable-line

  async function onCompanySubmit(values: CompanyFormFields) {
    setStatus(SliceStatus.Loading);
    try {
      if (companyQuery?.data) {
        await modifyCompany({
          company: {
            ...companyQuery?.data?.company,
            ...values.company,
            isShopKeeper: values.isShop,
            billingAddress: {
              ...companyQuery.data.company.billingAddress,
              ...values.company.billingAddress,
            },
            postAddress: {
              ...companyQuery.data.company.postAddress,
              ...values.company.postAddress,
            },
          },
          shop: values.isShop
            ? {
                ...companyQuery?.data?.shop,
                ...values.shop,
              }
            : undefined,
          shopAddressList: values.isShop ? values.shopAddressList : undefined,
          isDefaultDeliveryToWarehouse: values.isDefaultDeliveryToWarehouse,
        });
      }
      history.push("/company");
      setStatus(SliceStatus.Success);
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: t("company.create.subject"),
        }),
        { variant: "success" },
      );
    } catch (err) {
      setStatus(SliceStatus.Error);
      if ((err as any).data.status === "COMPANY_ALREADY_EXISTS") {
        enqueueSnackbar(t("company.modify.COMPANY_ALREADY_EXISTS"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("company.create.subject"),
          }),
          { variant: "error" },
        );
      }
    }
  }

  return (
    <Layout title={t("company.modify.title")} maxWidth="lg">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onCompanySubmit)}>
          <CompanyForm loading={status === SliceStatus.Loading} type="MODIFY" />
        </form>
      </FormProvider>
    </Layout>
  );
};

export default CompanyModify;
