import { makeStyles, Theme } from "@material-ui/core/styles";
import { palette } from "config/theme";

const useStyles = makeStyles(
  ({ spacing }: Theme) => ({
    pagination: {
      justifycontent: "center",
      margin: spacing(3),
      "& .MuiPagination-ul": {
        justifyContent: "center",
      },
    },
    root: {
      borderRadius: 10,
    },
    list: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
      alignItems: "center",
      width: "100%",
      borderBottom: "1px solid #ddd",
      minHeight: 72,
    },
    editor: {
      "& .tox-tinymce": {
        border: `1px solid ${palette.main}`,
        borderRadius: 0,
      },
    },
  }),
  { name: "ArticleForm" },
);

export default useStyles;
