import { useQuery } from "react-query";
import { listCompaniesByUser } from "shared/network/company.api";
import { Button, Popover, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { selectCompany } from "shared/reducers/application";
import { useTranslation } from "react-i18next";
import { createSelector } from "@reduxjs/toolkit";

const CompanyChooser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentCompany, user } = useSelector(
    createSelector(
      (state: RootState) => state.application.currentCompany,
      (state: RootState) => state.authentication.user,
      (currentCompany, user) => ({ currentCompany, user }),
    ),
  );

  const [anchor, setAnchor] = useState<null | HTMLButtonElement>(null);

  const query = useQuery(
    "userCompanies",
    async () => {
      const { data } = await listCompaniesByUser();
      return data.items;
    },
    {},
  );

  useEffect(() => {
    if (user) {
      query.refetch();
    }
  }, [user]); //eslint-disable-line

  useEffect(() => {
    if (query.data) {
      dispatch(selectCompany(query.data[0]));
    }
  }, [query.data, dispatch]);

  return (
    <>
      <Button
        onClick={e => setAnchor(e.currentTarget)}
        style={{ color: "white" }}
        disabled={(query.data?.length ?? 0) < 2}
      >
        {currentCompany?.name ?? t("company.error")}
      </Button>

      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        PaperProps={{
          style: { padding: "1rem" },
        }}
      >
        <Typography style={{ fontSize: "1.25rem", marginBottom: "1rem" }}>
          {t("company.list.title")}
        </Typography>
        {query.data?.map(company => (
          <div key={company.id}>
            <Button
              onClick={() => {
                dispatch(selectCompany(company));
                setAnchor(null);
              }}
            >
              {company.name}
            </Button>
          </div>
        ))}
      </Popover>
    </>
  );
};

export default CompanyChooser;
