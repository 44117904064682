import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Layout from "components/layout/Layout";
import Divider from "@material-ui/core/Divider";
import ReportForm from "./components/ReportForm";
import Typography from "@material-ui/core/Typography";
import {
  getPaymentOnDeliverySummary,
  getLateDeliveries,
  getEarlyDeliveries,
  getCountSuccessfulAndFailed,
} from "shared/network/report.api";
import { Shop } from "shared/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { endOfDay, startOfDay } from "date-fns";

type ReportType = {
  reportDateFrom: Date | string;
  reportDateTo: Date | string;
};

export type SuccessfulAndFailedType = {
  successfulCount: number;
  failedCount: number;
};

export type EarlyDeliveriesType = {
  item: number;
};
export type LateDeliveriesType = {
  item: number;
};
export type PaymentOnDeliverySummary = {
  id?: number;
  shop: Shop;
  summary: number;
};

const Report = () => {
  const { t } = useTranslation();
  const [successfulAndFailed, setSuccessfulAndFailed] = useState<SuccessfulAndFailedType>({
    successfulCount: 0,
    failedCount: 0,
  });
  const [earlyDeliveries, setEarlyDeliveries] = useState<EarlyDeliveriesType>({
    item: 0,
  });
  const [lateDeliveries, setLateDeliveries] = useState<LateDeliveriesType>({
    item: 0,
  });
  const [cardPaymentOnDeliverySummary, setCardPaymentOnDeliverySummary] = useState<
    PaymentOnDeliverySummary[]
  >([]);
  const [cashPaymentOnDeliverySummary, setCashPaymentOnDeliverySummary] = useState<
    PaymentOnDeliverySummary[]
  >([]);

  const onSubmit = async (values: ReportType) => {
    try {
      const { data: cardPaymentOnDeliverySummary } = await getPaymentOnDeliverySummary(
        values.reportDateFrom,
        values.reportDateTo,
        "CARD",
      );
      setCardPaymentOnDeliverySummary(cardPaymentOnDeliverySummary.items);
      const { data: cashPaymentOnDeliverySummary } = await getPaymentOnDeliverySummary(
        values.reportDateFrom,
        values.reportDateTo,
        "CASH",
      );
      setCashPaymentOnDeliverySummary(cashPaymentOnDeliverySummary.items);
      const { data: lateDeliveries } = await getLateDeliveries(
        values.reportDateFrom,
        values.reportDateTo,
      );
      setLateDeliveries(lateDeliveries);
      const { data: earlyDeliveries } = await getEarlyDeliveries(
        values.reportDateFrom,
        values.reportDateTo,
      );
      setEarlyDeliveries(earlyDeliveries);
      const { data: countSuccessfulAndFailed } = await getCountSuccessfulAndFailed(
        values.reportDateFrom,
        values.reportDateTo,
      );
      setSuccessfulAndFailed(countSuccessfulAndFailed);
    } catch {}
  };

  useEffect(() => {
    onSubmit({
      reportDateFrom: startOfDay(new Date()),
      reportDateTo: endOfDay(new Date()),
    });
  }, []);

  return (
    <Layout title={t("report.title")} maxWidth="lg">
      <ReportForm onSubmit={onSubmit} />
      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            paddingBottom: 10,
            paddingTop: 30,
          }}
        >
          {t("report.numberOfPackages")}
        </Typography>
      </Box>

      <Divider style={{ marginBottom: 0 }} />
      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <Grid
          container
          style={{
            alignItems: "left",
            padding: 0,
          }}
        >
          <Grid item xs={6}>
            <Typography
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {t("report.earlyDeliveries")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {t("report.lateDeliveries")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <Grid
          container
          style={{
            alignItems: "left",
            padding: 0,
          }}
        >
          <Grid item xs={6}>
            <Typography style={{ textAlign: "left" }}>
              {t("common:number", { num: earlyDeliveries.item })} db
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ textAlign: "left" }}>
              {t("common:number", { num: lateDeliveries.item })} db
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ margin: 0 }} />

      <Divider style={{ margin: 0 }} />
      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <>
          <Grid
            container
            style={{
              alignItems: "left",
              padding: 0,
            }}
          >
            <Grid item xs={6}>
              <Typography
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: 16,
                  margin: 0,
                }}
              >
                {t("report.successfulCount")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                {t("report.failedCount")}
              </Typography>
            </Grid>
          </Grid>
        </>
      </Box>
      <Divider />
      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <>
          <Grid
            container
            style={{
              alignItems: "left",
              padding: 0,
            }}
          >
            <Grid item xs={6}>
              <Typography style={{ textAlign: "left" }}>
                {t("common:number", {
                  num: successfulAndFailed.successfulCount,
                })}
                db
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "left" }}>
                {t("common:number", {
                  num: successfulAndFailed.failedCount,
                })}
                db
              </Typography>
            </Grid>
          </Grid>
        </>
      </Box>
      <Divider />
      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <Typography variant="h6" style={{ fontWeight: "bold", paddingBottom: 12, marginTop: 80 }}>
          {t("report.cashSummary")}
        </Typography>
      </Box>

      {cashPaymentOnDeliverySummary?.length ? (
        <>
          <Divider style={{ margin: 2 }} />
          <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
            <Grid
              container
              style={{
                alignItems: "left",
                padding: 0,
              }}
            >
              <Grid item xs={6}>
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  {t("report.company")}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  {t("report.summary")}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider style={{ margin: 0 }} />

          {cashPaymentOnDeliverySummary?.map(shop => (
            <Grid container style={{ marginLeft: 3 }}>
              <Grid item xs={6}>
                <Typography style={{ textAlign: "left" }}>{shop.shop.company?.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ textAlign: "right" }}>
                  {t("common:number", { num: shop?.summary })},- Ft
                </Typography>
              </Grid>
            </Grid>
          ))}

          <Divider style={{ margin: 0 }} />
        </>
      ) : (
        <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
          <Grid item xs={6}>
            <Typography style={{ textAlign: "left" }}>{t("report.notFound")}</Typography>
          </Grid>
        </Box>
      )}

      <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
        <Typography variant="h6" style={{ fontWeight: "bold", paddingBottom: 12, marginTop: 80 }}>
          {t("report.cardSummary")}
        </Typography>
      </Box>

      {cardPaymentOnDeliverySummary?.length ? (
        <>
          <Divider style={{ margin: 2 }} />
          <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
            <Grid
              container
              style={{
                alignItems: "left",
                padding: 0,
              }}
            >
              <Grid item xs={6}>
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  {t("report.company")}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  {t("report.summary")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider style={{ margin: 0 }} />

          {cardPaymentOnDeliverySummary?.map(shop => (
            <Grid container style={{ marginLeft: 3 }}>
              <Grid item xs={6}>
                <Typography style={{ textAlign: "left" }}>{shop.shop.company?.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ textAlign: "right" }}>
                  {t("common:number", { num: shop?.summary })},- Ft
                </Typography>
              </Grid>
            </Grid>
          ))}

          <Divider style={{ margin: 0 }} />
        </>
      ) : (
        <Box display="flex" width="100%" minWidth="250px" padding={0.8}>
          <Grid item xs={6}>
            <Typography style={{ textAlign: "left" }}>{t("report.notFound")}</Typography>
          </Grid>
        </Box>
      )}
    </Layout>
  );
};

export default Report;
