import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import Profile from "./Profile";
import ProfileModify from "./ProfileModify";

const ProfileRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={`${match.path}/:id`} component={Profile} />
      <PrivateRoute path={`${match.path}/modify/:id`} component={ProfileModify} />
    </Switch>
  );
};

export default ProfileRoutes;
