import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "config/store";
import { removeAuthenticationToken, saveAuthenticationToken } from "shared/util/user";
import { SliceStatus, User, UserPermission } from "shared/types";
import { getAccount, userLogout } from "shared/network/user.api";
import { authenticate } from "shared/network/authentication.api";
import { getCookie } from "shared/util/user";
import { AUTH_TOKEN_KEY } from "config/constants";

const NAME = "authentication";

export interface AuthenticationState {
  status: SliceStatus;
  error: string | null;
  user: User | null;
  permissions: UserPermission[];
  initialized: boolean;
  isAuthenticated: boolean;
}

const deleteSessionStorage = () => {
  sessionStorage.removeItem("PackagePageNumbe");
  sessionStorage.removeItem("ShippingPricePageNumber");
  sessionStorage.removeItem("CompanyPageNumber");
  sessionStorage.removeItem("UserPageNumber");
  sessionStorage.removeItem("VehiclePageNumber");
  sessionStorage.removeItem("WarehousePageNumber");
  sessionStorage.removeItem("DeliveryExtraChargePageNumber");
};

const initialState: AuthenticationState = {
  status: SliceStatus.Idle,
  error: null,
  user: null!,
  permissions: [],
  initialized: false,
  isAuthenticated: false,
};

const authentication = createSlice({
  name: NAME,
  initialState,
  reducers: {
    start: state => {
      state.status = SliceStatus.Loading;
    },
    error: (state, action: PayloadAction<string>) => {
      state.status = SliceStatus.Error;
      state.error = action.payload;
      state.isAuthenticated = false;
      state.initialized = true;
    },
    success: (state, action: PayloadAction<{ user: User; permissions: any[] }>) => {
      state.status = SliceStatus.Success;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions;
      state.isAuthenticated = true;
      state.initialized = true;
    },
    finishInitializing(state) {
      state.initialized = true;
    },
    resetState: () => ({
      ...initialState,
      initialized: true,
    }),
  },
});

export default authentication.reducer;

const { start, error, success, finishInitializing, resetState } = authentication.actions;

export { finishInitializing, resetState };

export const login = (
  email: string,
  password: string,
  rememberMe = false,
): AppThunk => async dispatch => {
  try {
    dispatch(start());
    const { data } = await authenticate(email, password, rememberMe);
    saveAuthenticationToken(data.id_token, rememberMe);
    dispatch(fetchAccount());
  } catch (err: any) {
    const responseCode = err?.status || (err?.response?.status ?? 0);
    const status = err?.data?.status;
    removeAuthenticationToken();
    if (responseCode === 0) {
      dispatch(error("hostNotFound"));
      return;
    }
    if (responseCode === 401) {
      if (status === "NOT_ACTIVATED") {
        dispatch(error("notActivated"));
        return;
      }
      dispatch(error("invalidCredentials"));
      return;
    }
    if (responseCode === 403) {
      if (status === "INVALID_LOGIN") {
        dispatch(error("invalidCredentials"));
        return;
      }
    }
    dispatch(error("failure"));
  }
};

export const fetchAccount = (): AppThunk => async dispatch => {
  if (getCookie(AUTH_TOKEN_KEY))
    try {
      const { data } = await getAccount();
      dispatch(success(data));
    } catch (err: any) {
      const status = err?.status || (err?.response?.status ?? 0);
      removeAuthenticationToken();
      if (status === 0) {
        dispatch(error("hostNotFound"));
        return;
      }
      dispatch(error("failure"));
    }
};

export const logout = (): AppThunk => async dispatch => {
  try {
    await userLogout();
    removeAuthenticationToken();
    deleteSessionStorage();
    dispatch(resetState());
  } catch (error) {}
};
