import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useQuery } from "react-query";
import { Warehouse } from "shared/types";
import { AREA_TYPES } from "config/constants";
import { useTranslation } from "react-i18next";
import { listCompanyPageable } from "shared/network/company.api";
import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core";

export type WarehouseFormValues = {
  country: string;
  zipCode: string;
  city: string;
  address: string;
  areaName: string;
  areaType: string;
  houseNumber: string;
  floor: string | null;
  name: string;
  space: string;
  height: string;
  companyId: string;
};

const useStyles = makeStyles(() => ({
  title: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: 14,
    paddingBottom: 8,
    borderBottom: "1px solid lightgrey",
    margin: "12px 0 18px",
  },
}));

type Props = {
  defaultValues?: Warehouse;
};

const WarehouseForm = ({ defaultValues }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { register, errors } = useFormContext<WarehouseFormValues>();

  const companyListQuery = useQuery(["companyWarehouseCreateQuery"], async () => {
    const { data } = await listCompanyPageable(0, 1000000, "isWarehouse:TRUE");
    return data.page.content;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className={classes.title}>{t("warehouse.create.data")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="companyId"
              rules={{
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              }}
              render={props => (
                <TextField
                  margin="none"
                  {...props}
                  label={t("common:field.company")}
                  error={!!errors.companyId}
                  helperText={errors.companyId?.message}
                  style={{
                    alignSelf: "center",
                  }}
                  select
                >
                  <MenuItem value="" disabled>
                    <em>{t("common:choose")}</em>
                  </MenuItem>
                  {companyListQuery.data?.map((company, index) => (
                    <MenuItem key={index} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="none"
              name="name"
              type="text"
              label={t("common:field.warehouse.name")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={errors?.name && true}
              helperText={errors?.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="none"
              name="space"
              type="number"
              inputProps={{ step: ".01" }}
              label={t("common:field.warehouse.space")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={errors?.space && true}
              helperText={errors?.space?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="none"
              name="height"
              type="number"
              inputProps={{ step: ".01" }}
              defaultValue={defaultValues?.height}
              label={t("common:field.warehouse.height")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={errors?.height && true}
              helperText={errors?.height?.message}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography className={classes.title}>{t("warehouse.create.address")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              margin="none"
              name="country"
              type="text"
              label={t("common:field.address.country")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.country}
              helperText={errors?.country?.message}
              defaultValue="Magyarország"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="none"
              name="zipCode"
              type="number"
              label={t("common:field.address.zipCode")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={errors?.zipCode && true}
              helperText={errors?.zipCode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="none"
              name="city"
              type="text"
              label={t("common:field.address.city")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={errors?.city && true}
              helperText={errors?.city?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="none"
              name="areaName"
              label={t("common:field.address.areaName")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.areaName}
              helperText={errors?.areaName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="areaType"
              defaultValue="UTCA"
              render={props => (
                <TextField
                  margin="none"
                  {...props}
                  select
                  label={t("common:field.address.areaType")}
                  InputLabelProps={{ shrink: true, required: true }}
                  error={!!errors?.areaType}
                  helperText={errors?.areaType?.message}
                >
                  {AREA_TYPES.map(type => (
                    <MenuItem key={type} value={type}>
                      {t(`common:areaType.${type}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="none"
              name="houseNumber"
              label={t("common:field.address.houseNumber")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.houseNumber}
              helperText={errors?.houseNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="none"
              name="floor"
              label={t("common:field.address.floor")}
              InputLabelProps={{ shrink: true }}
              inputRef={register()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WarehouseForm;
