import AddIcon from "@material-ui/icons/Add";
import Layout from "components/layout/Layout";
import CompanyListItem from "./components/CompanyListItem";
import { useQuery } from "react-query";
import { Link } from "components/router";
import { useTranslation } from "react-i18next";
import { listCompanyPageable } from "shared/network/company.api";
import {
  Typography,
  Box,
  Grid,
  Button,
  Divider,
  CircularProgress,
  TextField,
  MenuItem,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Close, FilterList } from "@material-ui/icons";

const useStyles = makeStyles(
  () => ({
    title: {
      fontWeight: "bold",
    },
  }),
  {
    name: "CompanyListItem",
  },
);

type Select = "YES" | "NO" | "UNDEFINED";

type CompanyFilter = {
  name: string;
  phone: string;
  email: string;
  isShipper: Select;
  isWarehouse: Select;
  isShopKeeper: Select;
};

const CompanyList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [page, setPage] = useState<number>(
    parseInt(window.sessionStorage.getItem("CompanyPageNumber") || JSON.stringify(0)),
  );

  const [filter, setFilter] = useState<string>("");

  const companyListQuery = useQuery(["companyList", page, filter], async () => {
    const { data } = await listCompanyPageable(page, 10, filter);
    return data;
  });

  const { register, handleSubmit, control, setValue } = useForm<CompanyFilter>({
    shouldUnregister: false,
  });

  const onSubmit = (values: CompanyFilter) => {
    setFilter("");
    setPage(0);
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        if (key === "isShipper" || key === "isWarehouse" || key === "isShopKeeper") {
          if (value !== "UNDEFINED") {
            setFilter(state => `${state}${state ? ";" : ""}${key}:${value === "YES"}`);
          }
        } else {
          setFilter(state => `${state}${state ? ";" : ""}${key}:${value}`);
        }
      }
    });
    companyListQuery.refetch();
  };

  useEffect(() => {
    window.sessionStorage.setItem("CompanyPageNumber", JSON.stringify(page));
  }, [page]);

  return (
    <Layout
      title={t("company.list.title")}
      actionButton={
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          component={Link}
          to="/company/create"
          startIcon={<AddIcon />}
        >
          {t("company.create.title")}
        </Button>
      }
    >
      <Box>
        {companyListQuery.isFetching ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <Box style={{ overflow: "auto hidden" }}>
            <Box style={{ minWidth: 1000 }}>
              <Grid
                container
                style={{
                  alignItems: "center",
                  padding: "12px 0",
                }}
              >
                <Grid item xs={2}>
                  <Typography className={classes.title}>{t("company.name")}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.title}>{t("company.phone")}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title}>{t("common:field.email")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography className={classes.title} style={{ textAlign: "center" }}>
                    {t("company.isShipper")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography className={classes.title} style={{ textAlign: "center" }}>
                    {t("company.isWarehouse")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography className={classes.title} style={{ textAlign: "center" }}>
                    {t("company.isShopKeeper")}
                  </Typography>
                </Grid>
              </Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={2} style={{ padding: "0 8px" }}>
                    <TextField
                      name="name"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setValue("name", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ padding: "0 8px" }}>
                    <TextField
                      name="phone"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setValue("phone", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ padding: "0 8px" }}>
                    <TextField
                      name="email"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      inputRef={register}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setValue("email", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ padding: "0 8px" }}>
                    <Controller
                      control={control}
                      name="isShipper"
                      defaultValue={"UNDEFINED"}
                      render={props => (
                        <TextField {...props} select InputLabelProps={{ shrink: true }}>
                          <MenuItem value={"UNDEFINED"}>{t("auditLog.noFilter")}</MenuItem>
                          <MenuItem value={"YES"}>{t("tour.yes")}</MenuItem>
                          <MenuItem value={"NO"}>{t("tour.no")}</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ padding: "0 8px" }}>
                    <Controller
                      control={control}
                      name="isWarehouse"
                      defaultValue={"UNDEFINED"}
                      render={props => (
                        <TextField {...props} select InputLabelProps={{ shrink: true }}>
                          <MenuItem value={"UNDEFINED"}>{t("auditLog.noFilter")}</MenuItem>
                          <MenuItem value={"YES"}>{t("tour.yes")}</MenuItem>
                          <MenuItem value={"NO"}>{t("tour.no")}</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ padding: "0 8px" }}>
                    <Controller
                      control={control}
                      name="isShopKeeper"
                      defaultValue={"UNDEFINED"}
                      render={props => (
                        <TextField {...props} select InputLabelProps={{ shrink: true }}>
                          <MenuItem value={"UNDEFINED"}>{t("auditLog.noFilter")}</MenuItem>
                          <MenuItem value={"YES"}>{t("tour.yes")}</MenuItem>
                          <MenuItem value={"NO"}>{t("tour.no")}</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <DialogActions>
                    <Grid container spacing={2}>
                      <Grid item xs style={{ padding: "0 8px" }}>
                        <Box display="flex" justifyContent="center" m={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<FilterList />}
                          >
                            {t("package.filter")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Grid>
              </form>
              <Divider />
              {!!companyListQuery.data?.page.content.length ? (
                companyListQuery.data?.page.content.map(company => (
                  <CompanyListItem
                    key={company.id}
                    company={company}
                    refetch={companyListQuery.refetch}
                  />
                ))
              ) : (
                <Typography variant="h5" style={{ textAlign: "center", color: "gray" }}>
                  {t("common:noData")}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {companyListQuery.data && companyListQuery?.data?.page.totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "24px 0",
          }}
          count={companyListQuery?.data?.page.totalPages}
          color="primary"
          page={companyListQuery.data?.page.number + 1}
          onChange={(e, page) => {
            sessionStorage.setItem("CompanyPageNumber", JSON.stringify(page - 1));
            setPage(page - 1);
          }}
        />
      )}
    </Layout>
  );
};

export default CompanyList;
