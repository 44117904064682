import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, Storage } from "shared/types";

const ENDPOINT = "/storage";

export const createMultipleStorage = (
  data: {
    warehouseId: number;
    rowName: string;
    numberOfColumns: number;
    numberOfLevels: number;
    numberOfSpaces: number;
    isCustom: boolean;
  }[],
) =>
  axios.post<GenericListResponse<Storage>>(`${ENDPOINT}/multiple`, {
    params: data,
  });

export const getStoragesByWarehouseId = (warehouseId: number) =>
  axios.get<GenericListResponse<Storage>>(`${ENDPOINT}?id=${warehouseId}`);

export const getStoragePagesByWarehouseId = (data: {
  pageNumber: number;
  pageSize: number;
  warehouseId: string;
}) =>
  axios.get<GenericPageResponse<Storage>>(
    `${ENDPOINT}/get-page-by-warehouse-id?page=${data.pageNumber}&size=${data.pageSize}&warehouseId=${data.warehouseId}`,
  );

export const deleteStoragesById = (ids: number[]) =>
  axios.delete(ENDPOINT, { data: { params: ids } });
