import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import WhiteIcon from "pictures/white-logo.svg";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { palette } from "config/theme";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import { Button, TextField } from "@material-ui/core";
import { FormEvent, useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const TrackingSearch = ({
  match,
}: RouteComponentProps<{
  trackingNumber: string;
}>) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [trackingNumber, setTrackingNumber] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("submit");

    return token;
  }, [executeRecaptcha]);

  function onSubmit(event: FormEvent<HTMLElement>) {
    event.preventDefault();
    handleReCaptchaVerify().then(async value => {
      if (value) {
        history.push(`tracking/${trackingNumber}`);
      }
    });
  }

  return (
    <Box height="100vh">
      <Box
        display="flex"
        alignItems="center"
        style={{
          backgroundColor: palette.main,
          height: 64,
          position: "fixed",
          width: "100%",
        }}
      >
        <img src={WhiteIcon} alt="" style={{ width: 36, height: 36, marginLeft: 16 }} />
        <Typography
          variant="h6"
          noWrap
          style={{
            color: "#fff",
            marginLeft: 16,
            fontFamily: "'Montserrat Alternates', sans-serif",
            fontWeight: "bold",
          }}
        >
          {t("common:appName")}
        </Typography>
        <Divider orientation="vertical" flexItem style={{ backgroundColor: "white", margin: 12 }} />
        <Typography
          variant="h6"
          noWrap
          style={{
            color: "#fff",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "bold",
          }}
        >
          {t("package.tracking.followPackage")}
        </Typography>
      </Box>
      <Container maxWidth="xs" style={{ paddingTop: 64, height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          component="form"
          onSubmit={onSubmit}
        >
          <TextField
            label={t("packageFilters.trackingNumber")}
            value={trackingNumber}
            onChange={event => setTrackingNumber(event.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" disabled={!trackingNumber}>
            {t("common:button.search")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default TrackingSearch;
