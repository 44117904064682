import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

type Props = {
  open: boolean;
  onAbort?: () => void;
} & WithStyles<typeof styles>;

const styles = ({ zIndex }: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 2000,
    },
  });

const PageLoading = ({ classes, open }: Props) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(open);
  }, [open]);

  return (
    <Backdrop className={classes.backdrop} open={state} invisible>
      <Box
        width="100%"
        height="100%"
        style={{
          backdropFilter: "blur(4px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="primary" size={48} />
      </Box>
    </Backdrop>
  );
};

export default withStyles(styles)(PageLoading);
