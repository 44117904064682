import clsx from "clsx";
import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { palette } from "config/theme";

type ButtonColor = "primary" | "secondary" | "success" | "error" | "warning";

type Props = {
  loading?: boolean;
  color?: ButtonColor;
} & Omit<ButtonProps, "color">;

const useStyles = makeStyles(
  () => ({
    root: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
    progress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    primary: {
      "&:hover": {
        backgroundColor: `${palette.lightMain}`,
      },
      backgroundColor: `${palette.main}`,
      color: "#fff",
      borderRadius: 5,
    },
  }),
  { name: "Button" },
);

const Button = ({
  loading,
  color,
  style,
  variant = "contained",
  disabled,
  className,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <MuiButton
        {...rest}
        style={style}
        color={color === "success" || color === "error" || color === "warning" ? "primary" : color}
        disabled={loading || disabled}
        variant={variant}
        className={clsx(className, {
          [classes.primary]:
            (color === "primary" || !color) && !(loading || disabled) && variant === "contained",
        })}
      />
      {loading && <CircularProgress size={24} className={clsx(classes.progress)} />}
    </div>
  );
};

export default Button;
