import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import VehicleCreate from "./VehicleCreate";
import VehicleDetails from "./VehicleDetails";
import VehicleList from "./VehicleList";
import VehicleModify from "./VehicleModify";

const VehicleRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={VehicleList} />
      <PrivateRoute path={`${match.path}/create`} component={VehicleCreate} />
      <PrivateRoute path={`${match.path}/modify/:id`} component={VehicleModify} />
      <PrivateRoute path={`${match.path}/:id`} component={VehicleDetails} />
    </Switch>
  );
};

export default VehicleRoutes;
