import { Divider, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { GenericPageResponse, User } from "shared/types";
import { Link } from "components/router";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import BlockIcon from "@material-ui/icons/Block";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import usePermissions from "shared/hooks/usePermissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UseQueryResult } from "react-query";
import { banUser } from "shared/network/user.api";
import { useSnackbar } from "notistack";

type Props = {
  userData: User;
  index: number;
  array: User[];
  usersQuery: UseQueryResult<GenericPageResponse<User>, unknown>;
};

const useStyles = makeStyles(theme => ({
  color: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const UserListItem = ({ userData, index, array, usersQuery }: Props) => {
  const { t } = useTranslation();
  const { isCustomerService, isAdmin } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  async function ban(userId: number) {
    try {
      await banUser(userId);
      usersQuery.refetch();
      enqueueSnackbar(
        t("common:notification.ban.success", {
          subject: t("user.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.ban.failure", {
          subject: t("user.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  return (
    <>
      <Divider />
      <Grid
        key={userData.id}
        container
        style={{
          alignItems: "center",
        }}
        className={useStyles().color}
      >
        <Grid item xs={2}>
          <Typography style={{ padding: 6 }}>
            {userData.lastName + " " + userData.firstName}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ padding: 6 }}>
          <Typography
            style={{
              wordBreak: "break-word",
            }}
          >
            {userData.email}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ padding: 6 }}>
          <Typography>{userData.phone}</Typography>
        </Grid>
        <Grid item xs={2} style={{ padding: 6 }}>
          <Typography>{t(`common:userType.${userData.userType}`)}</Typography>
        </Grid>
        <Grid item xs={2} style={{ padding: "0 15px" }}>
          <Tooltip title={t(`userList.status.${userData.status}`).toString()}>
            {userData.status === "ACTIVE" ? (
              <CheckCircleOutlineIcon style={{ color: "green" }} />
            ) : userData.status === "NEEDS_ACTIVATION" ? (
              <DoneIcon />
            ) : userData.status === "INACTIVE" ? (
              <PersonOutlineIcon />
            ) : (
              <BlockIcon style={{ color: "red" }} />
            )}
          </Tooltip>
        </Grid>
        {(isCustomerService || isAdmin) && (
          <Grid
            item
            xs={2}
            style={{
              textAlign: "right",
            }}
          >
            <Tooltip title={t("profile.ban").toString()}>
              <IconButton onClick={() => ban(userData.id)}>
                <FontAwesomeIcon icon={faBan} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("profile.modify.title").toString()}>
              <IconButton size="medium" component={Link} to={`/user/modify/${userData.id}`}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UserListItem;
