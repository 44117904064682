import Loading from "components/Loading";
import VehicleMapMarker from "./VehicleMapMarker";
import { Vehicle } from "shared/types";
import { useQuery } from "react-query";
import { Box, Grid } from "@material-ui/core";
import { MapContainer, TileLayer } from "react-leaflet";
import { getVehicleCoordinates } from "shared/network/vehicle.api";

const MultipleTourDetailsContent = ({ selected }: { selected: Vehicle[] }) => {
  const vehicleCoordinationsQuery = useQuery(
    ["vehicleCoordinationsQuery", selected],
    async () => {
      const { data } = await getVehicleCoordinates({ vehicles: selected });
      return data;
    },
    { enabled: !!selected },
  );

  return (
    <Grid container spacing={3}>
      {vehicleCoordinationsQuery.isLoading ? (
        <Box width="100%" height={300} display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <Grid item xs={12}>
          <MapContainer
            center={[
              vehicleCoordinationsQuery.data?.vehicleCoordinates?.[0]?.latitude || 47,
              vehicleCoordinationsQuery.data?.vehicleCoordinates?.[0]?.longitude || 20,
            ]}
            zoom={6}
            style={{ height: 680 }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {vehicleCoordinationsQuery.data?.vehicleCoordinates?.map(
              (vehicleCoordinates: any, index: number) => (
                <VehicleMapMarker
                  key={index}
                  vehicle={vehicleCoordinates?.vehicle}
                  longitude={vehicleCoordinates?.longitude || 0}
                  latitude={vehicleCoordinates?.latitude || 0}
                />
              ),
            )}
          </MapContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default MultipleTourDetailsContent;
