import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  endOfDay,
  startOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  addDays,
  addWeeks,
  addMonths,
  addYears,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { FilterList } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

type Props = {
  onSubmit: (values: ReportType) => void;
};

type ReportType = {
  reportDateFrom: Date | string;
  reportDateTo: Date | string;
};

const ReportForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  const { setValue, watch, control, handleSubmit } = useForm<ReportType>({
    defaultValues: {
      reportDateFrom: startOfDay(new Date()),
      reportDateTo: endOfDay(new Date()),
    },
  });

  const { reportDateFrom, reportDateTo } = watch(["reportDateFrom", "reportDateTo"]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5}>
          <Controller
            name="reportDateFrom"
            defaultValue={null}
            control={control}
            render={({ ref, ...props }) => (
              <KeyboardDateTimePicker
                {...props}
                name="reportDateFrom"
                InputLabelProps={{ shrink: true, required: true }}
                margin="none"
                size="small"
                format="yyyy.MM.dd. HH:mm"
                label={t("report.reportDateFrom")}
                views={["year", "month", "date"]}
              />
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            name="reportDateTo"
            defaultValue={null}
            control={control}
            render={({ ref, ...props }) => (
              <KeyboardDateTimePicker
                {...props}
                InputLabelProps={{ shrink: true, required: true }}
                name="reportDateTo"
                margin="none"
                size="small"
                format="yyyy.MM.dd. HH:mm"
                label={t("report.reportDateTo")}
                views={["year", "month", "date"]}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<FilterList />}
            style={{ width: "100%" }}
          >
            {t("package.filter")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignContent={"space-around"}
        alignItems={"center"}
        style={{ marginTop: 4, marginBottom: 0 }}
      >
        <Grid item xs={3}>
          <Tooltip title={t("report.back").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", subDays(startOfDay(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", subDays(endOfDay(new Date(reportDateTo)), 1));

                onSubmit({
                  reportDateFrom: subDays(startOfDay(new Date(reportDateFrom)), 1),
                  reportDateTo: subDays(endOfDay(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Button
            onClick={() => {
              setValue("reportDateFrom", subDays(startOfDay(new Date()), 1));
              setValue("reportDateTo", subDays(endOfDay(new Date()), 1));
              onSubmit({
                reportDateFrom: subDays(startOfDay(new Date()), 1),
                reportDateTo: subDays(endOfDay(new Date()), 1),
              });
            }}
            name="lastDay"
            variant="outlined"
            color="primary"
          >
            {t("report.lastDay")}
          </Button>
          <Tooltip title={t("report.forward").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", addDays(startOfDay(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", addDays(endOfDay(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: addDays(startOfDay(new Date(reportDateFrom)), 1),
                  reportDateTo: addDays(endOfDay(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t("report.back").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", subWeeks(startOfWeek(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", subWeeks(endOfWeek(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: subWeeks(startOfWeek(new Date(reportDateFrom)), 1),
                  reportDateTo: subWeeks(endOfWeek(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Button
            onClick={() => {
              setValue("reportDateFrom", subWeeks(startOfWeek(new Date()), 1));
              setValue("reportDateTo", subWeeks(endOfWeek(new Date()), 1));
              onSubmit({
                reportDateFrom: subWeeks(startOfWeek(new Date()), 1),
                reportDateTo: subWeeks(endOfWeek(new Date()), 1),
              });
            }}
            variant="outlined"
            color="primary"
          >
            {t("report.lastWeek")}
          </Button>
          <Tooltip title={t("report.forward").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", addWeeks(startOfWeek(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", addWeeks(endOfWeek(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: addWeeks(startOfWeek(new Date(reportDateFrom)), 1),
                  reportDateTo: addWeeks(endOfWeek(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t("report.back").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", subMonths(startOfMonth(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", subMonths(endOfMonth(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: subMonths(startOfMonth(new Date(reportDateFrom)), 1),
                  reportDateTo: subMonths(endOfMonth(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Button
            onClick={() => {
              setValue("reportDateFrom", subMonths(startOfMonth(new Date()), 1));
              setValue("reportDateTo", subMonths(endOfMonth(new Date()), 1));
              onSubmit({
                reportDateFrom: subMonths(startOfMonth(new Date()), 1),
                reportDateTo: subMonths(endOfMonth(new Date()), 1),
              });
            }}
            variant="outlined"
            color="primary"
          >
            {t("report.lastMonth")}
          </Button>
          <Tooltip title={t("report.forward").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", addMonths(startOfMonth(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", addMonths(endOfMonth(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: addMonths(startOfMonth(new Date(reportDateFrom)), 1),
                  reportDateTo: addMonths(endOfMonth(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title={t("report.back").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", subYears(startOfYear(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", subYears(endOfYear(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: subYears(startOfYear(new Date(reportDateFrom)), 1),
                  reportDateTo: subYears(endOfYear(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Button
            onClick={() => {
              setValue("reportDateFrom", subYears(startOfYear(new Date()), 1));
              setValue("reportDateTo", subYears(endOfYear(new Date()), 1));
              onSubmit({
                reportDateFrom: subYears(startOfYear(new Date()), 1),
                reportDateTo: subYears(endOfYear(new Date()), 1),
              });
            }}
            variant="outlined"
            color="primary"
          >
            {t("report.lastYear")}
          </Button>
          <Tooltip title={t("report.forward").toString()}>
            <IconButton
              onClick={() => {
                setValue("reportDateFrom", addYears(startOfYear(new Date(reportDateFrom)), 1));
                setValue("reportDateTo", addYears(endOfYear(new Date(reportDateTo)), 1));
                onSubmit({
                  reportDateFrom: addYears(startOfYear(new Date(reportDateFrom)), 1),
                  reportDateTo: addYears(endOfYear(new Date(reportDateTo)), 1),
                });
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportForm;
