import { subMinutes } from "date-fns";

export function utcDate(args?: string | Date) {
  let date: Date;

  if (args) {
    date = new Date(args);
  } else {
    date = new Date();
  }

  return subMinutes(date, date.getTimezoneOffset());
}

export function toUTCDate(date: Date): Date {
  return subMinutes(date, date.getTimezoneOffset());
}
