import { Collapse } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import SnackbarDismiss from "components/snackbar";
import SplashScreen from "components/splashScreen/SplashScreen";
import { CAPTCHA_KEY } from "config/constants";
import queryClient from "config/query";
import store from "config/store";
import theme from "config/theme";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";

ReactDOM.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <ReduxProvider store={store}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                action={key => <SnackbarDismiss itemKey={key} />}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                TransitionComponent={Collapse}
              >
                <React.Suspense fallback={<SplashScreen />}>
                  <CssBaseline />
                  <App />
                </React.Suspense>
              </SnackbarProvider>
            </ThemeProvider>
          </ReduxProvider>
        </DndProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </CookiesProvider>,
  document.getElementById("root"),
);
