import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputMask from "react-input-mask";
import Button from "components/form/Button";
import AddIcon from "@material-ui/icons/Add";
import useLocale from "shared/hooks/useLocale";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AREA_TYPES, EDITOR_MENUBAR, EDITOR_PLUGINS, EDITOR_TOOLBAR } from "config/constants";
import { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Address, Company } from "shared/types";
import { Editor } from "@tinymce/tinymce-react";
import { EMAIL_REGEX } from "shared/util/validation";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import usePermissions from "shared/hooks/usePermissions";

export type CompanyFormFields = {
  company: Company;
  shop: {
    webpage: string;
    ip: string;
    prefix: string;
    comment: string;
  };
  isShop: boolean;
  shopAddressList: Partial<Address[]>;
  isDefaultDeliveryToWarehouse: boolean;
};

type Props = {
  loading?: boolean;
  addressChecked?: (checked: boolean) => void;
  type?: "CREATE" | "MODIFY";
};

const useStyles = makeStyles(() => ({
  title: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: 14,
    paddingBottom: 8,
    borderBottom: "1px solid lightgrey",
    margin: "12px 0 6px 0",
  },
}));

const CompanyForm = ({ loading, addressChecked, type }: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const { register, errors, watch, control } = useFormContext();

  const isVolumetricWeight = watch("company.isVolumetricWeight");

  const { append, fields, remove } = useFieldArray({
    control,
    name: "shopAddressList",
    keyName: "key",
  });

  const { isAdmin, isCustomerService } = usePermissions();

  return (
    <>
      <Grid container spacing={2} style={{ padding: "12px 0" }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="none"
            name="company.name"
            label={t("common:field.name")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
            })}
            error={errors?.company?.name && true}
            helperText={errors?.company?.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="none"
            name="company.email"
            label={t("common:field.email")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
              pattern: {
                value: EMAIL_REGEX,
                message: t("validation:invalidFormat", {
                  subject: t("validation:caption.subject.email"),
                }),
              },
            })}
            error={errors.company?.email && true}
            helperText={errors.company?.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputMask mask="99999999-9-99" alwaysShowMask={false}>
            <TextField
              margin="none"
              name="company.taxNumber"
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: { value: true, message: t("validation:required") },
              })}
              label={t("common:field.taxNumber")}
              error={errors?.company?.taxNumber && true}
              helperText={errors?.company?.taxNumber?.message}
            />
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputMask mask="99-99-999999" alwaysShowMask={false}>
            <TextField
              margin="none"
              name="company.registrationNumber"
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: { value: true, message: t("validation:required") },
              })}
              label={t("common:field.registrationNumber")}
              error={errors?.company?.registrationNumber && true}
              helperText={errors?.company?.registrationNumber?.message}
            />
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputMask mask="99999999-99999999-99999999" alwaysShowMask={false}>
            <TextField
              margin="none"
              name="company.bankaccount"
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: { value: true, message: t("validation:required") },
              })}
              label={t("common:field.bankaccount")}
              error={errors?.company?.bankaccount && true}
              helperText={errors?.company?.bankaccount?.message}
            />
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="none"
            name="company.phone"
            InputLabelProps={{ shrink: true }}
            placeholder="+36"
            defaultValue="+36"
            inputRef={register({
              required: { value: true, message: t("validation:required") },
            })}
            label={t("common:field.phone")}
            error={errors?.company?.phone && true}
            helperText={errors?.company?.phone?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="none"
            name="company.rangeOfDays"
            InputLabelProps={{ shrink: true }}
            defaultValue=""
            inputRef={register()}
            label={t("common:field.rangeOfDays")}
            error={errors?.company?.rangeOfDays && true}
            helperText={errors?.company?.rangeOfDays?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="none"
            name="company.description"
            label={t("common:field.description")}
            inputRef={register()}
            error={errors?.company?.description && true}
            helperText={errors?.company?.description?.message}
            multiline
            rowsMax={5}
          />
        </Grid>
        {(isAdmin || isCustomerService) && (
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="company.isDefaultDeliveryToWarehouse"
              defaultValue={false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  }
                  label={t("company.isDefaultDeliveryToWarehouse")}
                  labelPlacement="end"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name="company.isVolumetricWeight"
            defaultValue={false}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...props}
                    color="primary"
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                }
                label={t("company.isVolumetricWeight")}
                labelPlacement="end"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {isVolumetricWeight && (
            <TextField
              margin="none"
              name="company.volumetricWeightVariable"
              InputLabelProps={{ shrink: true, required: isVolumetricWeight }}
              inputRef={register({
                required: { value: isVolumetricWeight, message: t("validation:required") },
              })}
              label={t("company.volumetricWeightVariable")}
              error={errors?.company?.volumetricWeightVariable && true}
              helperText={errors?.company?.volumetricWeightVariable?.message}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="company.isShipper"
            defaultValue={false}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...props}
                    color="primary"
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                }
                label={t("company.create.isShipper")}
                labelPlacement="end"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="company.isWarehouse"
            defaultValue={false}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...props}
                    color="primary"
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                }
                label={t("company.create.isWarehouse")}
                labelPlacement="end"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="isShop"
            defaultValue={false}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...props}
                    color="primary"
                    checked={props.value}
                    onChange={e => {
                      props.onChange(e.target.checked);
                    }}
                  />
                }
                label={t("company.create.isShop")}
                labelPlacement="end"
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography className={classes.title}>{t("company.create.billingAddress")}</Typography>
      {type === "CREATE" && (
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={checked} onChange={() => setChecked(!checked)} />
          }
          label={t("company.create.addressesAreEqual")}
          labelPlacement="end"
        />
      )}
      <Grid container spacing={2} style={{ padding: "12px 0" }}>
        <Grid item xs={12} sm={6} md={5}>
          <TextField
            margin="none"
            name="company.billingAddress.country"
            label={t("common:field.address.country")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.billingAddress?.country && true}
            helperText={errors?.billingAddress?.country?.message}
            defaultValue="Magyarország"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            margin="none"
            name="company.billingAddress.zipCode"
            type="number"
            label={t("common:field.address.zipCode")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.company?.billingAddress?.zipCode && true}
            helperText={errors?.company?.billingAddress?.zipCode?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <TextField
            margin="none"
            name="company.billingAddress.city"
            label={t("common:field.address.city")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.company?.billingAddress?.city && true}
            helperText={errors?.company?.billingAddress?.city?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <TextField
            margin="none"
            name="company.billingAddress.areaName"
            label={t("common:field.address.areaName")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.company?.billingAddress?.areaName && true}
            helperText={errors?.company?.billingAddress?.areaName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Controller
            name="company.billingAddress.areaType"
            defaultValue="UTCA"
            render={props => (
              <TextField
                {...props}
                margin="none"
                select
                label={t("common:field.address.areaType")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!errors?.company?.billingAddress?.areaType}
                helperText={errors?.company?.billingAddress?.areaType?.message}
              >
                {AREA_TYPES.map(type => (
                  <MenuItem key={type} value={type}>
                    {t(`common:areaType.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: { value: true, message: t("validation:required") },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            margin="none"
            name="company.billingAddress.houseNumber"
            label={t("common:field.address.houseNumber")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.company?.billingAddress?.houseNumber}
            helperText={errors?.company?.billingAddress?.houseNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            margin="none"
            name="company.billingAddress.floor"
            label={t("common:field.address.floor")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
          />
        </Grid>
      </Grid>
      {!checked && (
        <>
          <Typography className={classes.title}>{t("company.create.postAddress")}</Typography>
          <Grid container spacing={2} style={{ padding: "12px 0" }}>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                margin="none"
                name="company.postAddress.country"
                label={t("common:field.address.country")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.company?.postAddress?.country && true}
                helperText={errors?.company?.postAddress?.country?.message}
                defaultValue="Magyarország"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                margin="none"
                name="company.postAddress.zipCode"
                type="number"
                label={t("common:field.address.zipCode")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.company?.postAddress?.zipCode && true}
                helperText={errors?.company?.postAddress?.zipCode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                margin="none"
                name="company.postAddress.city"
                label={t("common:field.address.city")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.company?.postAddress?.city && true}
                helperText={errors?.company?.postAddress?.city?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                margin="none"
                name="company.postAddress.areaName"
                label={t("common:field.address.areaName")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.company?.postAddress?.areaName && true}
                helperText={errors?.company?.postAddress?.areaName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name="company.postAddress.areaType"
                defaultValue="UTCA"
                render={props => (
                  <TextField
                    {...props}
                    margin="none"
                    select
                    label={t("common:field.address.areaType")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!errors?.company?.postAddress?.areaType}
                    helperText={errors?.company?.postAddress?.areaType?.message}
                  >
                    {AREA_TYPES.map(type => (
                      <MenuItem key={type} value={type}>
                        {t(`common:areaType.${type}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: { value: true, message: t("validation:required") },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                margin="none"
                name="company.postAddress.houseNumber"
                label={t("common:field.address.houseNumber")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.company?.postAddress?.houseNumber}
                helperText={errors?.company?.postAddress?.houseNumber?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                margin="none"
                name="company.postAddress.floor"
                label={t("common:field.address.floor")}
                InputLabelProps={{ shrink: true }}
                inputRef={register()}
              />
            </Grid>
          </Grid>
        </>
      )}
      {watch("isShop") && (
        <>
          <Typography className={classes.title}>{t("company.create.shopData")}</Typography>

          <Grid container spacing={2} style={{ padding: "12px 0" }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                margin="none"
                name="shop.webpage"
                label={t("common:field.webpage")}
                inputRef={register()}
                error={errors?.shop?.webpage && true}
                helperText={errors?.shop?.webpage?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                margin="none"
                name="shop.ip"
                label={t("common:field.ip")}
                inputRef={register()}
                error={errors?.shop?.ip && true}
                helperText={errors?.shop?.ip?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                margin="none"
                name="shop.prefix"
                label={t("shop.properties.prefix")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.shop?.prefix && true}
                helperText={errors?.shop?.prefix?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="shop.comment"
                defaultValue=""
                margin={6}
                render={props => (
                  <Editor
                    initialValue={props.value}
                    init={{
                      min_height: 300,
                      max_height: 400,
                      plugins: EDITOR_PLUGINS,
                      imagetools_cors_hosts: ["picsum.photos"],
                      entity_encoding: "raw",
                      menubar: EDITOR_MENUBAR,
                      toolbar: EDITOR_TOOLBAR,
                      toolbar_sticky: true,
                      autosave_ask_before_unload: true,
                      autosave_interval: "30s",
                      autosave_prefix: "{path}{query}-{id}-",
                      autosave_restore_when_empty: false,
                      autosave_retention: "2m",
                      language: locale.options.tinyMCELang,
                      insertdatetime_formats: locale.options.tinyMCEDates,
                    }}
                    value={props.value}
                    onEditorChange={(content, editor) => {
                      props.onChange(content);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <Typography className={classes.title} style={{ marginBottom: 12 }}>
              {t("company.create.packagePickUp")}
            </Typography>
            {fields.map((field, index) => (
              <Fragment key={field.key}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {t("common:notification.packagePoint", {
                      index: index + 1,
                    })}
                  </Typography>
                  <Tooltip title={t("common:button.delete").toString()}>
                    <IconButton size="small" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex">
                  <Box display="flex" flexDirection="column" gridGap={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={5}>
                        <TextField
                          margin="none"
                          defaultValue={field.title}
                          name={`shopAddressList[${index}].title`}
                          label={t("common:field.title")}
                          InputLabelProps={{ shrink: true }}
                          inputRef={register()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <input
                          name={`shopAddressList[${index}].id`}
                          value={field.id}
                          type="hidden"
                          ref={register()}
                        />
                        <TextField
                          margin="none"
                          defaultValue={field.country || "Magyarország"}
                          name={`shopAddressList[${index}].country`}
                          label={t("common:field.address.country")}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputRef={register({
                            required: {
                              value: true,
                              message: t("validation:required"),
                            },
                          })}
                          error={errors?.company?.shopAddressList?.[index]?.country && true}
                          helperText={errors?.company?.shopAddressList?.[index]?.country?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="none"
                          defaultValue={field.zipCode}
                          name={`shopAddressList[${index}].zipCode`}
                          type="number"
                          label={t("common:field.address.zipCode")}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputRef={register({
                            required: {
                              value: true,
                              message: t("validation:required"),
                            },
                          })}
                          error={errors?.company?.shopAddressList?.[index]?.zipCode && true}
                          helperText={errors?.company?.shopAddressList?.[index]?.zipCode?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <TextField
                          margin="none"
                          defaultValue={field.city}
                          name={`shopAddressList[${index}].city`}
                          label={t("common:field.address.city")}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputRef={register({
                            required: {
                              value: true,
                              message: t("validation:required"),
                            },
                          })}
                          error={errors?.company?.shopAddressList?.[index]?.city && true}
                          helperText={errors?.company?.shopAddressList?.[index]?.city?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <TextField
                          margin="none"
                          defaultValue={field.areaName}
                          name={`shopAddressList[${index}].areaName`}
                          label={t("common:field.address.areaName")}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputRef={register({
                            required: {
                              value: true,
                              message: t("validation:required"),
                            },
                          })}
                          error={errors?.company?.shopAddressList?.[index]?.areaName && true}
                          helperText={errors?.company?.shopAddressList?.[index]?.areaName?.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Controller
                          defaultValue={field.areaType || "UTCA"}
                          name={`shopAddressList[${index}].areaType`}
                          render={props => (
                            <TextField
                              {...props}
                              margin="none"
                              select
                              label={t("common:field.address.areaType")}
                              InputLabelProps={{ shrink: true, required: true }}
                              error={!!errors?.company?.shopAddressList?.[index]?.areaType}
                              helperText={
                                errors?.company?.shopAddressList?.[index]?.areaType?.message
                              }
                            >
                              {AREA_TYPES.map(type => (
                                <MenuItem key={type} value={type}>
                                  {t(`common:areaType.${type}`)}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: t("validation:required"),
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          margin="none"
                          defaultValue={field.houseNumber}
                          name={`shopAddressList[${index}].houseNumber`}
                          label={t("common:field.address.houseNumber")}
                          InputLabelProps={{ shrink: true, required: true }}
                          inputRef={register({
                            required: {
                              value: true,
                              message: t("validation:required"),
                            },
                          })}
                          error={!!errors?.company?.shopAddressList?.[index]?.houseNumber}
                          helperText={
                            errors?.company?.shopAddressList?.[index]?.houseNumber?.message
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          margin="none"
                          defaultValue={field.floor}
                          name={`shopAddressList[${index}].floor`}
                          label={t("common:field.address.floor")}
                          InputLabelProps={{ shrink: true }}
                          inputRef={register()}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Divider style={{ width: "100%", margin: "24px 0 12px 0" }} />
              </Fragment>
            ))}
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "12px auto" }}
              onClick={event => {
                event.stopPropagation();
                append({
                  country: "",
                  zipCode: "",
                  city: "",
                  areaName: "",
                  areaType: "",
                  houseNumber: "",
                  floor: "",
                });
              }}
              startIcon={<AddIcon />}
            >
              {t("common:button.newPoint")}
            </Button>
          </Box>
        </>
      )}
      <Button
        style={{ marginTop: 20 }}
        loading={loading}
        color="primary"
        type="submit"
        onClick={() => {
          if (addressChecked) {
            addressChecked(checked);
          }
        }}
      >
        {type === "CREATE" ? t("common:button.create") : t("common:button.save")}
      </Button>
    </>
  );
};

export default CompanyForm;
