import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Divider, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Company } from "shared/types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DescriptionOutlined, Edit } from "@material-ui/icons";
import usePermissions from "shared/hooks/usePermissions";
import { faBan, faBoxes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { banCompany } from "shared/network/company.api";
import { useSnackbar } from "notistack";

type Props = {
  company: Company;
  refetch: () => void;
};

const useStyles = makeStyles(theme => ({
  color: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CompanyListItem = ({ company, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isCustomerService, isAdmin } = usePermissions();

  async function ban(companyId: number, unbanAllUsers: boolean) {
    try {
      await banCompany(companyId, unbanAllUsers);
      refetch();
      enqueueSnackbar(
        t("common:notification.ban.success", {
          subject: t("company.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.ban.failure", {
          subject: t("company.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  return (
    <>
      <Grid
        key={company.id}
        container
        style={{
          alignItems: "center",
          padding: "4px 0",
        }}
        className={useStyles().color}
      >
        <Grid item xs={2}>
          <Typography>{company.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{company.phone}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{company.email}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ lineHeight: 0, textAlign: "center" }}>
            {company?.isShipper ? (
              <CheckRoundedIcon style={{ color: "green" }} />
            ) : (
              <CloseRoundedIcon style={{ color: "red" }} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ lineHeight: 0, textAlign: "center" }}>
            {company?.isWarehouse ? (
              <CheckRoundedIcon style={{ color: "green" }} />
            ) : (
              <CloseRoundedIcon style={{ color: "red" }} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ lineHeight: 0, textAlign: "center" }}>
            {company?.isShopKeeper ? (
              <CheckRoundedIcon style={{ color: "green" }} />
            ) : (
              <CloseRoundedIcon style={{ color: "red" }} />
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "center",
          }}
        >
          {(isAdmin || isCustomerService) && (
            <Tooltip title={t("profile.ban").toString()}>
              <IconButton onClick={() => ban(company.id, false)}>
                <FontAwesomeIcon icon={faBan} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t("common:button.modify").toString()}>
            <IconButton component={Link} to={`/company/${company.id}/modify`}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("common:button.details").toString()}>
            <IconButton component={Link} to={`/company/${company.id}/details`}>
              <DescriptionOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("common:button.pickup").toString()}>
            <IconButton
              component={Link}
              to={`/company/${company.id}/relshopaddress`}
              disabled={!company.isShopKeeper}
            >
              <FontAwesomeIcon icon={faBoxes} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default CompanyListItem;
