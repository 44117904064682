import { Box, Button } from "@material-ui/core";
import { Cancel, Check, Edit } from "@material-ui/icons";
import Layout from "components/layout/Layout";
import PageLoading from "components/PageLoading";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { getDeliveryZoneListByCompanyId } from "shared/network/deliveryzone.api";
import {
  createRelZipZoneEntry,
  getDeliveryZoneZipListByCompanyId,
} from "shared/network/relzipzone.api";
import { RelZipZoneEntry } from "shared/types";
import { Zone } from "views/company/CompanyZone";
import CompanyZoneColumn from "./CompanyZoneColumn";
import { MatrixType } from "shared/network/deliveryprice.api";

type Params = {
  id: string;
};

export type ZoneZipFormValues = {
  zones: ZipZoneEntry[];
};

export type ZipZoneEntry = {
  zone: Zone;
  zipEntries: RelZipZoneEntry[];
};

const CompanyZoneList = ({ match }: RouteComponentProps<Params>) => {
  const companyId = match.params.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const matrixType = urlParams.get("type") as MatrixType;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const companyZoneZipListQuery = useQuery(["companyZoneZipListQuery", companyId], async () => {
    const { data } = await getDeliveryZoneZipListByCompanyId(companyId);
    return data.items;
  });

  const companyZoneQuery = useQuery(
    ["companyZoneQueryForZipList", companyId, companyZoneZipListQuery],
    async () => {
      const { data } = await getDeliveryZoneListByCompanyId(companyId, matrixType);
      return (
        data.items?.map(entry => {
          let id = entry.id?.toString() || "";
          return {
            zone: entry,
            zipEntries:
              companyZoneZipListQuery.data?.filter(zipEntry => zipEntry.zoneId.toString() === id) ||
              [],
          };
        }) || []
      );
    },
    {
      enabled: !!companyZoneZipListQuery.data,
      onSuccess: data => {
        form.reset({ zones: data });
      },
    },
  );

  const form = useForm<ZoneZipFormValues>();

  async function onSubmit(values: ZoneZipFormValues) {
    try {
      setLoading(true);
      let zipEntries: RelZipZoneEntry[] = [];
      values.zones.forEach((groupEntry, index) => {
        let zoneId = companyZoneQuery.data?.[index].zone.id?.toString() || "";
        groupEntry.zipEntries.forEach((entry, index) => {
          zipEntries.push({ ...entry, zoneId: zoneId, companyId: companyId, type: matrixType });
        });
      });
      await createRelZipZoneEntry(zipEntries);
      setLoading(false);
      enqueueSnackbar(
        t("common:notification.save.success", {
          subject: t(""),
        }),
        { variant: "success" },
      );
      setIsEditing(false);
      companyZoneZipListQuery.refetch();
      companyZoneQuery.refetch();
    } catch (e: any) {
      setLoading(false);
      if (e.data.status === "CONFLICT") {
        enqueueSnackbar(
          t("common:notification.zipConflict", {
            subject:
              e.data.items[0].zipFrom +
              "-" +
              e.data.items[0].zipTo +
              "[ " +
              (companyZoneQuery.data?.find(
                entry => entry.zone.id?.toString() === e.data.items[0].zoneId.toString(),
              )?.zone.name || "") +
              " ]" +
              " / " +
              e.data.items[1].zipFrom +
              "-" +
              e.data.items[1].zipTo +
              "[ " +
              (companyZoneQuery.data?.find(
                entry => entry.zone.id?.toString() === e.data.items[1].zoneId.toString(),
              )?.zone.name || "") +
              " ]",
          }),
          { variant: "error" },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.save.failure", {
            subject: t(""),
          }),
          { variant: "error" },
        );
      }
    }
  }

  return (
    <>
      <PageLoading
        open={companyZoneZipListQuery.isFetching || companyZoneQuery.isFetching || loading}
      />
      {!!companyZoneQuery.data?.length && (
        <FormProvider {...form}>
          <form id="zoneZipForm" onSubmit={form.handleSubmit(onSubmit)}>
            <Layout
              title={t("zoneZipList.title")}
              maxWidth="xl"
              actionButton={
                <>
                  {isEditing && (
                    <Box display="flex" gridGap={8}>
                      <Button
                        size="medium"
                        color="primary"
                        variant="contained"
                        type="submit"
                        form="zoneZipForm"
                        startIcon={<Check />}
                      >
                        {t("common:button.save")}
                      </Button>
                      <Button
                        size="medium"
                        color="primary"
                        variant="outlined"
                        startIcon={<Cancel />}
                        onClick={() => {
                          setIsEditing(false);
                          companyZoneZipListQuery.refetch();
                          companyZoneQuery.refetch();
                        }}
                      >
                        {t("common:button.cancel")}
                      </Button>
                    </Box>
                  )}
                  {!isEditing && (
                    <Box display="flex" gridGap={8}>
                      <Button
                        size="medium"
                        color="primary"
                        variant="outlined"
                        startIcon={<Edit />}
                        onClick={() => setIsEditing(true)}
                      >
                        {t("common:button.modify")}
                      </Button>
                    </Box>
                  )}
                </>
              }
            >
              <Box
                display="flex"
                justifyContent={"flex-start"}
                width={((companyZoneQuery.data?.length || 0) + 1) * 200}
              >
                {companyZoneQuery.data?.map((zoneEntry, index) => {
                  return (
                    <CompanyZoneColumn
                      zipEntries={zoneEntry.zipEntries}
                      zone={zoneEntry.zone}
                      isEditing={isEditing}
                      fieldIndex={index}
                    />
                  );
                })}
              </Box>
            </Layout>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default CompanyZoneList;
