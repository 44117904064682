import { Company, Contact } from "shared/types";
import { useQuery } from "react-query";
import { getContactsByCompanyId } from "shared/network/contact.api";
import { useState } from "react";
import ContactModify from "components/contact/ContactModify";
import { Box, Divider, Grid, IconButton, Tooltip } from "@material-ui/core";
import { ChatOutlined, Edit } from "@material-ui/icons";
import ContactCreate from "components/contact/ContactCreate";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { palette } from "config/theme";

type Props = { company: Company };

const CompanyContactList = ({ company }: Props) => {
  const { t } = useTranslation();
  const [toModify, setToModify] = useState<Contact | null>(null);

  const query = useQuery(["companyContacts", company.id], async () => {
    const { data } = await getContactsByCompanyId(company.id);
    return data.items;
  });

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography
          style={{
            color: palette.main,
            fontSize: 16,
          }}
        >
          {t("contact.list")}
        </Typography>
        <ContactCreate companyId={company.id} onSubmitSuccess={query.refetch} />
      </Box>
      <Divider
        style={{
          width: "100%",
          marginBottom: 16,
          borderBottom: "1px solid darkgray",
        }}
      />
      {query.data?.map(contact => (
        <Grid
          container
          key={contact.id}
          spacing={2}
          alignItems="center"
          style={{ fontSize: 16, marginBottom: 8 }}
        >
          <Grid item xs={4}>
            {contact.name}
          </Grid>
          <Grid item xs={4}>
            {contact.email}
          </Grid>
          <Grid item xs={3}>
            {contact.phone}
          </Grid>
          <Grid item xs={1} style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Grid item>
              <Tooltip title={parse(contact.comment)}>
                <Box padding={1} mt={1} color={palette.main}>
                  <ChatOutlined />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setToModify(contact)}>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <ContactModify
        contact={toModify}
        onClose={() => setToModify(null)}
        onSubmitSuccess={query.refetch}
      />
    </>
  );
};

export default CompanyContactList;
