import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@material-ui/core";
import Button from "components/form/Button";
import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Room } from "@material-ui/icons";
import { Package } from "shared/types";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import CloseIcon from "@material-ui/icons/Close";

type Props = {
  pack: Package;
};

const PackageStatusHistory = ({ pack }: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState<null | {
    longitude: number;
    latitude: number;
  }>(null);

  const statuses = useMemo(() => {
    const temp = [...pack.statuses];
    temp.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
    return temp;
  }, [pack]);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined" color="primary">
        {t("package.status.history")}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"md"} fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            {t("package.status.history")}
            <Tooltip title={t("common:button.close").toString()}>
              <IconButton color="primary" onClick={() => setOpen(false)} size="small">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 24, paddingTop: 0 }}>
          <Card variant="outlined">
            {statuses.map((packageStatus, index, array) => (
              <Fragment key={packageStatus.id}>
                <ListItem>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={5}>
                      {`${t(`package.status.${packageStatus.status}`)} ${
                        packageStatus?.storage?.storageCode
                          ? ` (${packageStatus?.storage?.storageCode})`
                          : ""
                      }`}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {packageStatus.createdBy.lastName} {packageStatus.createdBy.firstName}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {format(new Date(packageStatus.createdOn), "Pp", {
                        locale: hu,
                      })}
                      {!!packageStatus.latitude && !!packageStatus.longitude && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setCoords({
                              latitude: packageStatus.latitude,
                              longitude: packageStatus.longitude,
                            })
                          }
                        >
                          <Room />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
                {array.length !== index + 1 && <Divider />}
              </Fragment>
            ))}
          </Card>
        </DialogContent>
        <Dialog open={!!coords} onClose={() => setCoords(null)}>
          <MapContainer
            center={[coords?.latitude || 0, coords?.longitude || 0]}
            zoom={15}
            style={{ height: 500, width: 500 }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[coords?.latitude || 0, coords?.longitude || 0]} />
          </MapContainer>
        </Dialog>
      </Dialog>
    </>
  );
};

export default PackageStatusHistory;
