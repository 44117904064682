import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PackageRow from "components/package/PackageRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import StorageCodeModal from "components/storage/StorageCodeModal";
import PackageStatusModal from "components/package/PackageStatusModal";
import { useState } from "react";
import { Package } from "shared/types";
import { useTranslation } from "react-i18next";

export type ColumnType = {
  [key: string]: boolean;
  status: boolean;
  companyName: boolean;
  packageName: boolean;
  volume: boolean;
  weight: boolean;
  trackingNumber: boolean;
  shopPackageNumber: boolean;
  icons: boolean;
  customerComment: boolean;
  customerEmail: boolean;
  customerName: boolean;
  customerPhone: boolean;
  sourceAddress: boolean;
  currentAddress: boolean;
  targetAddress: boolean;
  targetFromDate: boolean;
  targetToDate: boolean;
  sourceFromDate: boolean;
  sourceToDate: boolean;
  storageCode: boolean;
  returnGoods: boolean;
};

type Props = {
  columns: ColumnType;
  packages?: Package[];
  refetch: () => void;
  loading?: boolean;
  enableMultiSelect?: boolean;
  selectedPackageList?: Package[];
  addPackage?: (pack: Package) => void;
  removePackage?: (pack: Package) => void;
};

const PackageList = ({
  columns,
  packages,
  refetch,
  loading,
  enableMultiSelect,
  selectedPackageList,
  addPackage,
  removePackage,
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Package | null>(null);
  const [selectedStorageCode, setSelectedStorageCode] = useState<Package | null>(null);

  return !!packages?.length ? (
    <Box style={{ overflow: "auto hidden" }}>
      <Box width="fit-content" minWidth="100%">
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              borderBottom="1px solid rgba(224, 224, 224, 1)"
              minHeight={50}
              pl={4}
            >
              {columns.packageName && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.name")}
                  </Typography>
                </Box>
              )}
              {columns.trackingNumber && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.trackingNumber")}
                  </Typography>
                </Box>
              )}
              {columns.cdunumber && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.cdunumber")}
                  </Typography>
                </Box>
              )}{" "}
              {columns.orderShopNumber && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.orderShopNumber")}
                  </Typography>
                </Box>
              )}{" "}
              {columns.orderExternalNumber && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.orderExternalNumber")}
                  </Typography>
                </Box>
              )}
              {columns.volume && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.volume")}
                  </Typography>
                </Box>
              )}
              {columns.weight && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.weight")}
                  </Typography>
                </Box>
              )}
              {columns.status && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.status.title")}
                  </Typography>
                </Box>
              )}
              {columns.storageCode && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("package.storageCode")}</Typography>
                </Box>
              )}
              {columns.companyName && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.companyName")}</Typography>
                </Box>
              )}
              {columns.customerName && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("packageFilters.customerName")}
                  </Typography>
                </Box>
              )}
              {columns.customerEmail && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.customerEmail")}
                  </Typography>
                </Box>
              )}
              {columns.customerPhone && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.customerPhone")}
                  </Typography>
                </Box>
              )}
              {columns.customerComment && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("package.properties.customerComment")}
                  </Typography>
                </Box>
              )}
              {columns.sourceAddress && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.sourceAddress")}</Typography>
                </Box>
              )}
              {columns.currentAddress && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.currentAddress")}</Typography>
                </Box>
              )}
              {columns.targetAddress && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.targetAddress")}</Typography>
                </Box>
              )}
              {columns.targetFromDate && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.targetFromDate")}</Typography>
                </Box>
              )}
              {columns.targetToDate && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.targetToDate")}</Typography>
                </Box>
              )}
              {columns.sourceFromDate && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.sourceFromDate")}</Typography>
                </Box>
              )}
              {columns.sourceToDate && (
                <Box display="flex" width="100%" minWidth="250px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("tour.sourceToDate")}</Typography>
                </Box>
              )}
              {columns.icons && (
                <Box display="flex" width="100%" minWidth="500px" padding={0.5}>
                  <Typography style={{ fontWeight: "bold" }}>{t("package.prop")}</Typography>
                </Box>
              )}
              <Box width="100%" minWidth="250px" padding={0.5}></Box>
            </Box>
            {packages.map((pack, index) => (
              <PackageRow
                key={index}
                pack={pack}
                columns={columns}
                setSelected={setSelected}
                setSelectedStorageCode={setSelectedStorageCode}
                enableMultiSelect={enableMultiSelect || false}
                selectedPackageList={selectedPackageList}
                addPackage={addPackage}
                removePackage={removePackage}
              />
            ))}
          </>
        )}
        <PackageStatusModal pack={selected} setSelected={setSelected} refetch={refetch} />
        <StorageCodeModal
          pack={selectedStorageCode}
          setSelectedStorageCode={setSelectedStorageCode}
          refetch={refetch}
        />
      </Box>
    </Box>
  ) : (
    <Typography>{t("common:noData")}</Typography>
  );
};

export default PackageList;
