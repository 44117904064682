import { FormProvider, useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import ContactForm, { ContactFormValues } from "./ContactForm";
import { useMutation } from "react-query";
import { Add } from "@material-ui/icons";
import { createContact } from "shared/network/contact.api";
import { useSnackbar } from "notistack";

type Props = {
  companyId: number;
  onSubmitSuccess?: () => void;
};

const ContactCreate = ({ companyId, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const form = useForm<ContactFormValues>();

  const mutation = useMutation<any, any, ContactFormValues>(
    async values => {
      await createContact({ companyId, ...values });
    },
    {
      onSuccess: () => {
        onSubmitSuccess?.();
        setOpen(false);
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("contact.subject"),
          }),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("contact.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined" color="primary" startIcon={<Add />}>
        {t("contact.create")}
      </Button>
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit: form.handleSubmit(values => mutation.mutate(values)),
          }}
        >
          <DialogTitle>{t("contact.create")}</DialogTitle>
          <DialogContent>
            <ContactForm />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              {t("common:button.save")}
            </Button>
            <Button onClick={() => setOpen(false)}>{t("common:button.cancel")}</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default ContactCreate;
