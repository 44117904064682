import { Button } from "@material-ui/core";
import Layout from "components/layout/Layout";
import Loading from "components/Loading";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { relshopaddressCompany } from "shared/network/relshopaddress.api";
import { Address, SliceStatus } from "shared/types";
import CompanyPickUpPoint from "./CompanyPickUpPoint";

export type CompanyPickUpForms = {
  shopAddressList: Partial<Address[]>;
};

const CompanyPickUpPointCreate = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(SliceStatus.Idle);
  const id = match.params.id;

  const methods = useForm<CompanyPickUpForms>();

  async function onCompanySubmit(values: CompanyPickUpForms) {
    setStatus(SliceStatus.Loading);
    try {
      await relshopaddressCompany({
        addressList: values.shopAddressList,
        companyId: id,
      });
      history.push("/company");
      setStatus(SliceStatus.Success);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("company.relshopaddress.subject"),
        }),
        { variant: "success" },
      );
    } catch (err) {
      setStatus(SliceStatus.Error);
      if ((err as any).data.status === "PICKUPPOINT_ALREADY_EXISTS") {
        enqueueSnackbar(t("company.relshopaddress.PICKUPPOINT_ALREADY_EXISTS"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("company.relshopaddress.subject"),
          }),
          { variant: "error" },
        );
      }
    }
  }

  return (
    <Layout title={t("company.relshopaddress.title")} maxWidth="lg">
      {status === "loading" && <Loading />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onCompanySubmit)}>
          <CompanyPickUpPoint />
          <Button type="submit" color="primary" variant="contained" style={{ height: 40 }}>
            {t("company.save")}
          </Button>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default CompanyPickUpPointCreate;
