import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import PackageCreate from "views/package/PackageCreate";
import PackageListView from "views/package/PackageListView";
import PackageDetails from "views/package/PackageDetails";
import PackageModify from "./PackageModify";

const PackageRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute
        exact
        path={match.path}
        component={PackageListView}
        permissions={["SHOPKEEPER", "SHIPORGANIZER", "WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"]}
      />
      <PrivateRoute path={`${match.path}/create`} component={PackageCreate} />
      <PrivateRoute exact path={`${match.path}/:id`} component={PackageDetails} />
      <PrivateRoute path={`${match.path}/:id/modify`} component={PackageModify} />
    </Switch>
  );
};

export default PackageRoutes;
