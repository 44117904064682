import { Box, Button, FormHelperText, Typography } from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { packageFileUpload } from "shared/network/package.api";
import { getPropertyByName } from "shared/network/property.api";

type PackageFileFormValues = {
  file: FileList;
};

const PackageFileUpload = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currentCompany = useSelector((state: RootState) => state.application.currentCompany);

  const mutation = useMutation<any, any, PackageFileFormValues>(
    async values => {
      if (values.file?.[0] && currentCompany?.id) {
        const formData = new FormData();
        formData.set("file", values.file[0]);
        formData.set("companyId", currentCompany.id.toString());
        return await packageFileUpload(formData);
      }
      return Promise.reject();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Sikeres feltöltés", { variant: "success" });
        history.push("/package");
      },
      onError: () => {
        enqueueSnackbar("Sikertelen feltöltés", { variant: "error" });
      },
    },
  );

  const form = useForm<PackageFileFormValues>();
  const fileName = form.watch("file")?.[0]?.name;

  const getPropertyByNameQuery = useQuery("max_upload_size_mb", async () => {
    const { data } = await getPropertyByName("max_upload_size_mb");
    return data.item?.value;
  });
  const fileSize = Number(getPropertyByNameQuery?.data || 50);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(values => mutation.mutate(values))}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gridGap={8}
        >
          <Button variant="outlined" color="primary" component="label" htmlFor="packageFile">
            {t("Fájl választása")}
            <input
              id="packageFile"
              style={{ display: "none" }}
              name="file"
              type="file"
              accept=".csv"
              ref={form.register({
                required: {
                  value: false,
                  message: t("validation:required"),
                },
                validate: value => {
                  if (value?.[0]?.size >= fileSize * 1000 * 1000) {
                    return t("validation:maxSize", {
                      size: fileSize,
                    }).toString();
                  }
                },
              })}
            />
          </Button>
          <Typography>{fileName ?? t("package.note.error")}</Typography>
          <FormHelperText error>{form.errors.file?.message}</FormHelperText>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" gridGap={8} mt={2}>
          <Button variant="contained" color="primary" type="submit">
            {t("common:button.upload")}
          </Button>
        </Box>
        {mutation.isLoading && <Loading />}
      </form>
    </FormProvider>
  );
};

export default PackageFileUpload;
