import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import ShippingPriceListView from "views/shipping-price/ShippingPriceListView";

const ShippingPriceRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute
        exact
        path={match.path}
        component={ShippingPriceListView}
        permissions={["ADMIN", "CUSTOMERSERVICE"]}
      />
    </Switch>
  );
};

export default ShippingPriceRoutes;
