import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Edit, EditLocation } from "@material-ui/icons";
import Layout from "components/layout/Layout";
import ModifyCoordinates from "components/ModifyCoordinates";
import { palette } from "config/theme";
import parse from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, RouteComponentProps } from "react-router-dom";
import usePermissions from "shared/hooks/usePermissions";
import { getCompanyById } from "shared/network/company.api";
import displayAddress from "shared/util/displayAddress";
import CompanyContactList from "./components/CompanyContactList";

const useStyles = makeStyles(
  () => ({
    header: {
      color: palette.main,
      fontSize: 16,
      borderBottom: "1px solid darkgray",
      marginBottom: 16,
    },
    title: {
      color: "grey",
    },
    data: {
      fontSize: 16,
    },
    iconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginBottom: 8,
    },
  }),
  { name: "CompanyDetails" },
);

const CompanyDetails = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const id = match.params.id;

  const companyQuery = useQuery("companyQueryCompanyDetails", async () => {
    const { data } = await getCompanyById(Number.parseInt(id));
    return data;
  });
  const company = companyQuery.data?.company;
  const shop = companyQuery.data?.shop;

  const [open, setOpen] = useState<"current" | "target" | "source" | boolean>(false);

  const { isCustomerService, isAdmin } = usePermissions();

  return (
    <Layout
      title={t("company.details.title")}
      maxWidth="lg"
      actionButton={
        <>
          {(isAdmin || isCustomerService) && (
            <Box display="flex" gridGap={8} flexWrap="wrap">
              {company?.isShopKeeper && (
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  to={`/company/${company?.id}/zones?type=STORE`}
                  startIcon={<FontAwesomeIcon icon={faGlobe} />}
                >
                  {t("zoneZipList.storeZones")}
                </Button>
              )}
              {company?.isShipper && (
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  to={`/company/${company?.id}/zones?type=SHIPPER`}
                  startIcon={<FontAwesomeIcon icon={faGlobe} />}
                >
                  {t("zoneZipList.shipperZones")}
                </Button>
              )}
              <Button
                component={Link}
                variant="outlined"
                to={`/company/${company?.id}/modify`}
                startIcon={<Edit />}
              >
                {t("common:button.modify")}
              </Button>
            </Box>
          )}
        </>
      }
    >
      <Typography className={classes.header}>{t("company.details.data")}</Typography>
      <Grid container spacing={2} style={{ marginBottom: 12 }}>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("common:field.name")}</div>
          <div className={classes.data}>{company?.name}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("common:field.email")}</div>
          <div className={classes.data}>{company?.email}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("common:field.taxNumber")}</div>
          <div className={classes.data}>{company?.taxNumber}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("common:field.registrationNumber")}</div>
          <div className={classes.data}>{company?.registrationNumber}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("common:field.bankaccount")}</div>
          <div className={classes.data}>{company?.bankaccount}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("common:field.phone")}</div>
          <div className={classes.data}>{company?.phone}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("company.isDefaultDeliveryToWarehouse")}</div>
          <div className={classes.data}>
            {company?.isDefaultDeliveryToWarehouse ? `${t("tour.yes")}` : `${t("tour.no")}`}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.title}>{t("company.create.type")} </div>
          <div className={classes.data}>
            {!company?.isShipper && !company?.isWarehouse && !company?.isShopKeeper ? (
              t("company.create.error")
            ) : (
              <>
                <div>{company?.isShipper && `${t("company.create.isShipper")}`}</div>
                <div>{company?.isWarehouse && `${t("company.create.isWarehouse")}`}</div>
                <div>{company?.isShopKeeper && `${t("company.create.isShop")} `}</div>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.title}>{t("company.volumetricWeightVariable")}</div>
          <div className={classes.data}>
            {company?.isVolumetricWeight
              ? company?.volumetricWeightVariable || "0"
              : t("company.noVolumetricWeight")}
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.title}>{t("common:field.description")}</div>
          <div className={classes.data}>{company?.description}</div>
        </Grid>
        {!isAdmin && (
          <>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("company.create.billingAddress")}</div>
              <div className={classes.data}>{displayAddress(company?.billingAddress)}</div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("company.create.postAddress")}</div>
              <div className={classes.data}>{displayAddress(company?.postAddress)}</div>
            </Grid>
          </>
        )}
        {isAdmin && (
          <>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("company.create.billingAddress")}</div>
              <Box display="flex" alignItems="center">
                <Tooltip title={t("map.coordinatesModify").toString()}>
                  <IconButton size="small" onClick={() => setOpen(true)}>
                    <EditLocation />
                  </IconButton>
                </Tooltip>
                <div className={classes.data}>{displayAddress(company?.billingAddress)}</div>
              </Box>
              {company && (
                <ModifyCoordinates address={company.billingAddress} open={open} setOpen={setOpen} />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("company.create.postAddress")}</div>
              <Box display="flex" alignItems="center">
                <Tooltip title={t("map.coordinatesModify").toString()}>
                  <IconButton size="small" onClick={() => setOpen(true)}>
                    <EditLocation />
                  </IconButton>
                </Tooltip>
                <div className={classes.data}>{displayAddress(company?.postAddress)}</div>
              </Box>
              {company && (
                <ModifyCoordinates address={company.postAddress} open={open} setOpen={setOpen} />
              )}
            </Grid>
          </>
        )}
      </Grid>
      {shop && (
        <>
          <Typography className={classes.header}>{t("company.create.shopData")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("common:field.webpage")}</div>
              <div className={classes.data}>{shop.webpage}</div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("common:field.ip")}</div>
              <div className={classes.data}>{shop.ip}</div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>{t("shop.properties.prefix")}</div>
              <div className={classes.data}>{shop.prefix}</div>
            </Grid>
            {isAdmin && (
              <Grid item xs={12} sm={6}>
                <div className={classes.title}>API kulcs</div>
                <div className={classes.data}>{shop.apiKey}</div>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <div className={classes.title}>{t("shop.properties.comment")}</div>
              <div className={classes.data}>{shop.comment && parse(shop.comment)}</div>
            </Grid>
          </Grid>
        </>
      )}
      {isAdmin && (
        <>
          <Typography className={classes.header}>
            {t("company.deliveryExtraCharge.title")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.with_loader_extra_charge")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.withLoaderExtraCharge
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.hazardous_extra_charge_percent")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.hazardousExtraChargePercent
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.fragile_extra_charge")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.fragileExtraCharge
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.cash_on_delivery_minimum_price")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.cashOnDeliveryMinimumPrice
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.cash_on_delivery_percent")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.cashOnDeliveryPercent
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.expressDeliveryPrice")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.expressDeliveryPrice
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.title}>
                {t("company.deliveryExtraCharge.uniqueInsurancePercent")}
              </div>
              <div className={classes.data}>
                {company?.deliveryExtraChargeEntry
                  ? company?.deliveryExtraChargeEntry?.uniqueInsurancePercent
                  : "-"}
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {company && <CompanyContactList company={company} />}
    </Layout>
  );
};

export default CompanyDetails;
