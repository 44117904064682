import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useMutation } from "react-query";
import { createNote } from "shared/network/notes.api";
import { Package } from "shared/types";
import { Add } from "@material-ui/icons";
import PackageNoteForm, { PackageNoteFormValues } from "components/package/PackageNoteForm";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Button from "components/form/Button";

type Props = {
  pack: Package;
  onSubmitSuccess?: () => void;
};

const PackageNoteCreate = ({ pack, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<PackageNoteFormValues>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const mutation = useMutation<any, any, PackageNoteFormValues>(
    async values => {
      setLoading(true);
      if (values.file[0] || values.note)
        return await createNote({
          subjectId: pack.shipment?.id,
          subjectType: "SHIPMENT",
          type: "COMMENT",
          file: values.file[0],
          note: values.note,
        });

      return Promise.reject();
    },
    {
      onSuccess: () => {
        setOpen(false);
        form.reset();
        onSubmitSuccess?.();
        enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
        setLoading(false);
      },
      onError: error => {
        if (error?.data?.status === "FILE_SIZE_EXCEEDED") {
          enqueueSnackbar("A fájl mérete meghaladja a maximumot.", { variant: "error" });
        } else {
          enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
        }
        setLoading(false);
      },
    },
  );

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)} startIcon={<Add />}>
        {t("package.newComment")}
      </Button>
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit: form.handleSubmit(values => mutation.mutate(values)),
          }}
        >
          <DialogTitle style={{ paddingBottom: 0 }}>{t("package.newComment")}</DialogTitle>
          <DialogContent>
            <PackageNoteForm />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit" loading={loading}>
              {t("common:button.upload")}
            </Button>
            <Button variant="text" onClick={() => setOpen(false)}>
              {t("common:button.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default PackageNoteCreate;
