import Layout from "components/layout/Layout";
import Loading from "components/Loading";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getVehicleById, modifyVehicle } from "shared/network/vehicle.api";
import { SliceStatus } from "shared/types";
import VehicleForm, { VehicleFormFields } from "./components/VehicleForm";
import { FormProvider, useForm } from "react-hook-form";
import Button from "components/form/Button";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const VehicleModify = ({ match }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const id = Number.parseInt(match.params.id);

  const [status, setStatus] = React.useState(SliceStatus.Idle);

  const vehicleQuery = useQuery(["vehicleQueryModify", id], async () => {
    const { data } = await getVehicleById(id);
    return data.item;
  });

  const methods = useForm<VehicleFormFields>();

  useEffect(() => {
    methods.reset({
      ...vehicleQuery.data,
      companyId: vehicleQuery.data?.company?.id,
      userId: vehicleQuery.data?.user?.id,
    });
  }, [vehicleQuery?.data]); //eslint-disable-line

  async function onModifySubmit(values: VehicleFormFields) {
    setStatus(SliceStatus.Loading);
    try {
      await modifyVehicle({
        ...values,
        id: id,
        companyId: values.companyId,
        licensePlateNumber: values.licensePlateNumber,
        brand: values.brand,
        type: values.type,
        weightCapacity: values.weightCapacity,
        volume: values.height * values.width * values.length,
        firstRegisteredDate: values.firstRegisteredDate,
        carDocumentsExpirationDate: values.carDocumentsExpirationDate,
        registeredDateInHungary: values.registeredDateInHungary,
        tachographExpirationDate: values.tachographExpirationDate,
        userId: values.userId,
      });
      history.push("/vehicle");
      setStatus(SliceStatus.Success);
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: t("vehicle.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      setStatus(SliceStatus.Error);
      enqueueSnackbar(
        t("common:notification.update.failure", {
          subject: t("vehicle.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  return (
    <Layout title={t("vehicle.modify.title")} maxWidth="lg">
      {vehicleQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onModifySubmit)}>
            <VehicleForm />
            <Button
              style={{ marginTop: 20 }}
              loading={status === SliceStatus.Loading}
              color="primary"
              type="submit"
            >
              {t("common:button.save")}
            </Button>
          </form>
        </FormProvider>
      )}
    </Layout>
  );
};

export default VehicleModify;
