import { Switch, RouteComponentProps } from "react-router-dom";
import PrivateRoute from "components/router/PrivateRoute";
import ListArticle from "./ListArticle";
import CreateArticle from "./Create/CreateArticle";
import ModifyArticle from "./Modify/ModifyArticle";

type Props = RouteComponentProps;

const ArticleRoutes = ({ location, match }: Props) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={ListArticle} />
      <PrivateRoute path={`${match.path}/create`} component={CreateArticle} />
      <PrivateRoute path={`${match.path}/:id/modify`} component={ModifyArticle} />
    </Switch>
  );
};

export default ArticleRoutes;
