import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import { WarningDataType } from "../Tour";

type Props = {
  warningData: WarningDataType;
  setWarningData: (warningData: WarningDataType) => void;
  onSubmit: (data: any) => void;
};

const WarningModal = ({ warningData, setWarningData, onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={!!warningData} onClose={() => setWarningData(null)}>
      <DialogTitle>{t("tour.addressTitle")}</DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 16 }}>{t("tour.body1")}</Typography>
        <Typography>{t("tour.body2")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setWarningData(null)}>{t("common:button.cancel")}</Button>
        <Button variant="contained" color="primary" onClick={() => onSubmit(warningData)}>
          {t("tour.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
