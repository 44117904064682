import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { EMAIL_REGEX } from "shared/util/validation";
import { useTranslation } from "react-i18next";
import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import useLocale from "shared/hooks/useLocale";
import { EDITOR_MENUBAR, EDITOR_PLUGINS, EDITOR_TOOLBAR } from "config/constants";

export type ContactFormValues = {
  name: string;
  email: string;
  phone: string;
  comment: string;
};

const ContactForm = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const form = useFormContext<ContactFormValues>();

  return (
    <>
      <TextField
        name="name"
        label={t("common:field.name")}
        inputRef={form.register({
          required: { value: true, message: t("validation:required") },
        })}
        error={form.errors.name && true}
        helperText={form.errors.name?.message}
      />
      <TextField
        name="email"
        type="email"
        label={t("common:field.email")}
        inputRef={form.register({
          required: { value: true, message: t("validation:required") },
          pattern: {
            value: EMAIL_REGEX,
            message: t("validation:invalidFormat", {
              subject: t("validation:caption.subject.email"),
            }),
          },
        })}
        error={form.errors.email && true}
        helperText={form.errors.email?.message}
      />
      <TextField
        name="phone"
        placeholder="+36"
        defaultValue="+36"
        label={t("common:field.phone")}
        InputLabelProps={{ required: true, shrink: true }}
        inputRef={form.register({
          required: {
            value: true,
            message: t("validation:required"),
          },
        })}
        error={form.errors?.phone && true}
        helperText={form.errors?.phone?.message}
      />
      <Controller
        name="comment"
        defaultValue=""
        render={props => (
          <Editor
            initialValue={props.value}
            init={{
              min_height: 300,
              max_height: 400,
              plugins: EDITOR_PLUGINS,
              imagetools_cors_hosts: ["picsum.photos"],
              entity_encoding: "raw",
              menubar: EDITOR_MENUBAR,
              toolbar: EDITOR_TOOLBAR,
              toolbar_sticky: true,
              autosave_ask_before_unload: true,
              autosave_interval: "30s",
              autosave_prefix: "{path}{query}-{id}-",
              autosave_restore_when_empty: false,
              autosave_retention: "2m",
              language: locale.options.tinyMCELang,
              insertdatetime_formats: locale.options.tinyMCEDates,
            }}
            value={props.value}
            onEditorChange={(content, editor) => {
              props.onChange(content);
            }}
          />
        )}
      />
    </>
  );
};

export default ContactForm;
