import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import {
  EDITOR_MENUBAR,
  EDITOR_PLUGINS,
  EDITOR_TOOLBAR,
  PACKAGE_NOTE_TYPES,
} from "config/constants";
import { palette } from "config/theme";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useLocale from "shared/hooks/useLocale";
import { getPropertyByName } from "shared/network/property.api";

export type PackageNoteFormValues = {
  file: FileList;
  note: string;
  type?: string;
  noteHelper: boolean;
};

type Props = { noteRequired?: boolean; fileRequired?: boolean };

const PackageNoteForm = ({ noteRequired, fileRequired }: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const form = useFormContext<PackageNoteFormValues>();
  const fileName = form.watch("file")?.[0]?.name;
  const type = form.watch("type");

  const getPropertyByNameQuery = useQuery("max_upload_size_mb", async () => {
    const { data } = await getPropertyByName("max_upload_size_mb");
    return data.item?.value;
  });

  useEffect(() => {
    form.register("noteHelper", {
      validate: () => {
        return !form.watch("file")?.[0] && !form.watch("note")
          ? "Megjegyzés létrehozásához szükséges vagy egy fájl vagy egy hozzászólás."
          : true;
      },
    });
  }, []); //eslint-disable-line

  const fileSize = Number(getPropertyByNameQuery?.data || 50);

  return (
    <>
      {noteRequired && (
        <Controller
          name="type"
          defaultValue=""
          rules={{
            required: noteRequired
              ? {
                  value: true,
                  message: t("validation:required"),
                }
              : undefined,
          }}
          render={props => (
            <TextField
              {...props}
              label={t("note.type")}
              InputLabelProps={{ shrink: true, required: true }}
              SelectProps={{ displayEmpty: true }}
              error={form.errors?.type && true}
              helperText={form.errors?.type?.message}
              select
            >
              <MenuItem value="" disabled>
                <em>{t("common:choose")}</em>
              </MenuItem>
              {PACKAGE_NOTE_TYPES.map(type => (
                <MenuItem key={type} value={type}>
                  {t(`note.types.${type}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      )}
      <Box>
        <FormLabel>{t("package.note.comment")}</FormLabel>
        <Controller
          name="note"
          defaultValue=""
          rules={{
            required: noteRequired
              ? {
                  value: true,
                  message: t("validation:required"),
                }
              : undefined,
          }}
          render={props => (
            <Editor
              initialValue={props.value}
              init={{
                min_height: 300,
                max_height: 400,
                plugins: EDITOR_PLUGINS,
                imagetools_cors_hosts: ["picsum.photos"],
                entity_encoding: "raw",
                menubar: EDITOR_MENUBAR,
                toolbar: EDITOR_TOOLBAR,
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: "30s",
                autosave_prefix: "{path}{query}-{id}-",
                autosave_restore_when_empty: false,
                autosave_retention: "2m",
                language: locale.options.tinyMCELang,
                insertdatetime_formats: locale.options.tinyMCEDates,
              }}
              value={props.value}
              onEditorChange={(content, editor) => {
                props.onChange(content);
              }}
            />
          )}
        />
        <FormHelperText error>{form.errors.note?.message}</FormHelperText>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gridGap={8}
        marginY={2}
      >
        <Button
          variant="outlined"
          color="primary"
          component="label"
          htmlFor="noteFile"
          style={
            form.errors.file
              ? { color: palette.brickRed, borderColor: palette.brickRed }
              : undefined
          }
        >
          {t("package.note.fileChoose")}
          <input
            id="noteFile"
            style={{ display: "none" }}
            name="file"
            type="file"
            ref={form.register({
              required:
                fileRequired || type === "PACKAGE_DAMAGED"
                  ? {
                      value: true,
                      message: t("validation:required"),
                    }
                  : undefined,
              validate: value => {
                if (value?.[0]?.size >= fileSize * 1000 * 1000) {
                  return t("validation:maxSize", {
                    size: fileSize,
                  }).toString();
                }
              },
            })}
          />
        </Button>
        <Typography
          noWrap
          style={{
            maxWidth: 250,
            color: form.errors.file ? palette.brickRed : undefined,
          }}
        >
          {fileName ?? t("package.note.error")}
        </Typography>
      </Box>
      <FormHelperText error>{form.errors.file?.message}</FormHelperText>
    </>
  );
};

export default PackageNoteForm;
