import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { modifyShippingPrice } from "shared/network/shippingprices.api";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { ShippingPrice } from "shared/types";
import { useSnackbar } from "notistack";

type ShoppingPriceModifyFormValues = {
  price: string;
};

type Props = {
  shippingPrice: ShippingPrice;
  onSubmitSuccess?: () => void;
};

const ShippingPriceModifyModal = ({ shippingPrice, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const form = useForm<ShoppingPriceModifyFormValues>();

  const mutation = useMutation<any, any, ShoppingPriceModifyFormValues>(
    async values => {
      await modifyShippingPrice({
        id: shippingPrice.id,
        price: Number(values.price),
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        onSubmitSuccess?.();
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: t("shippingPrice.subject"),
          }),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("shippingPrice.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <>
      <Tooltip title={t("common:button.modify").toString()}>
        <IconButton onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: form.handleSubmit(values => mutation.mutate(values)),
        }}
      >
        <DialogTitle>{t("shippingPrice.modify")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("common:field.price")}
            name="price"
            defaultValue={shippingPrice.netPrice}
            type="number"
            inputRef={form.register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!form.errors?.price}
            helperText={form.errors?.price?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            {t("common:button.save")}
          </Button>
          <Button color="primary" variant="text" onClick={() => setOpen(false)}>
            {t("common:button.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShippingPriceModifyModal;
