import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";

type Props = {
  itemKey: string | number;
};

const SnackbarDismiss = ({ itemKey }: Props) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton style={{ color: "inherit" }} onClick={() => closeSnackbar(itemKey)}>
      <CloseIcon />
    </IconButton>
  );
};

export default SnackbarDismiss;
