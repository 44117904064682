import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/form/Button";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { createDeliveryExtraChargeToCompany } from "shared/network/deliveryextracharge.api";
import { Company, DeliveryRequest } from "shared/types";
import DeliveryExtraChargeForm from "./DeliveryExtraChargeForm";

type Props = {
  company?: Company;
  onSubmitSuccess?: () => void;
  refetch: () => void;
};

const DeliveryExtraChargeCreate = ({ onSubmitSuccess, company, refetch }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const form = useForm<DeliveryRequest>();

  const mutation = useMutation<any, any, DeliveryRequest>(
    async values => {
      await createDeliveryExtraChargeToCompany(values);
    },
    {
      onSuccess: () => {
        onSubmitSuccess?.();
        setOpen(false);
        history.push("/deliveryextracharge");
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("company.deliveryExtraCharge.report"),
          }),
          { variant: "success" },
        );
        refetch();
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("company.deliveryExtraCharge.report"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <>
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        disabled={!company || !!company?.deliveryExtraChargeEntry}
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
        style={{ height: 40 }}
      >
        {t("company.deliveryExtraCharge.create")}
      </Button>
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit: form.handleSubmit(values => mutation.mutate(values)),
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{t("company.deliveryExtraCharge.create")}</DialogTitle>
          <DialogContent>
            <DeliveryExtraChargeForm companyId={company?.id.toString()} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="text">
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("common:button.save")}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};
export default DeliveryExtraChargeCreate;
