import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import MapIcon from "@material-ui/icons/Map";
import { Alert } from "@material-ui/lab";
import Button from "components/form/Button";
import { RootState } from "config/store";
import { format, isAfter, isToday, startOfDay } from "date-fns";
import update from "immutability-helper";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer } from "react-leaflet";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { modifyPackageStatus } from "shared/network/package.api";
import { modifyTourOrder, setTourToFinal, tourListByVehicle } from "shared/network/tour.api";
import { getVehicleCoordinates } from "shared/network/vehicle.api";
import { Address, Package } from "shared/types";
import { TourPlan } from "../Tour";
import MapMarker from "./MapMarker";
import TourSortingListItem from "./TourSortingListItem";
import VehicleMapMarker from "./VehicleMapMarker";

type Props = {
  open: boolean;
  tourPlanProp?: TourPlan;
  onClose: () => void;
  refetch: () => void;
  date: Date;
};

export type Tour = {
  id: number;
  address: Address;
  vehicleId: number;
  deliveryDate: string;
  tourOrder: number;
  status: string;
  relTourPackages: {
    id: number;
    tour: null;
    packages: Package;
    packagesType: "UP" | "DOWN";
    createdBy: number;
    updatedBy: number;
  }[];
  deliveryTimeFrom: string;
  deliveryTimeTo: string;
  notesList: [] | null;
  createdBy: number;
  updatedBy: number;
  isFinal: boolean;
};

const TourSortingModal = ({ open, onClose, tourPlanProp, refetch, date }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [cards, setCards] = useState<Tour[]>([]);
  const [wrongOrder, setWrongOrder] = useState<number[]>([]);
  const [orderChanged, setOrderChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const companyId = useSelector((state: RootState) => state.application.currentCompany?.id);

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const dragEnabled = isAfter(startOfDay(date), new Date()) || isToday(date);

  const toursQuery = useQuery(
    ["tourSortingModalQuery", tourPlanProp?.vehicleEntry?.id, date, open],
    async () => {
      if (tourPlanProp?.vehicleEntry?.id) {
        const { data } = await tourListByVehicle({
          vehicleId: tourPlanProp.vehicleEntry.id,
          date: format(date, "yyyy-MM-dd"),
        });
        return data;
      }
      return Promise.reject();
    },
    { enabled: open },
  );
  const tourPlan = toursQuery.data;

  const isFinal = !!tourPlan?.tourEntryList?.find(tour => tour.isFinal !== true);

  useEffect(() => {
    if (tourPlan?.tourEntryList) {
      let sorted = [...tourPlan?.tourEntryList].sort((a, b) => a.tourOrder - b.tourOrder);
      setCards(sorted);
    }
  }, [tourPlan]);

  useEffect(() => {
    const packageList = cards
      .map(card =>
        card.relTourPackages.map(rel => ({
          packageId: rel.packages.id,
          packageType: rel.packagesType,
        })),
      )
      .flat();

    let idList: number[] = [];
    let tempWrongIds: number[] = [];

    packageList.forEach(pack => {
      if (pack.packageType === "UP") {
        idList.push(pack.packageId);
      } else if (!idList.includes(pack.packageId)) {
        tempWrongIds.push(pack.packageId);
      }
    });
    setWrongOrder(tempWrongIds);
  }, [cards]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      let temp = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      const modifiedOrder = temp.map((row, index) => {
        return { ...row, tourOrder: index + 1 };
      });
      setCards(modifiedOrder);
      if (!orderChanged) {
        setOrderChanged(true);
      }
    },
    [cards, orderChanged],
  );

  const categorized = useMemo(() => {
    return (
      tourPlan?.tourEntryList?.reduce(
        (
          acc: {
            coords: { longitude: number | null; latitude: number | null };
            tours: Tour[];
          }[],
          tour,
        ) => {
          const item = acc.find(
            item =>
              item.coords.latitude === tour.address.latitude &&
              item.coords.longitude === tour.address.longitude,
          );
          if (item) {
            item.tours.push(tour);
            item.tours.sort((a, b) => a.tourOrder - b.tourOrder);
          } else {
            acc.push({
              coords: {
                latitude: tour.address.latitude,
                longitude: tour.address.longitude,
              },
              tours: [tour],
            });
          }
          return acc;
        },
        [],
      ) ?? []
    );
  }, [tourPlan?.tourEntryList]);

  const vehicleCoordinatesQuery = useQuery(
    ["vehicleCoordinatesQuery", tourPlanProp?.vehicleEntry],
    async () => {
      if (tourPlanProp?.vehicleEntry) {
        const { data } = await getVehicleCoordinates({
          vehicles: [tourPlanProp?.vehicleEntry],
        });
        return data;
      }
    },
    {
      enabled: !!tourPlanProp?.vehicleEntry,
      refetchInterval: 60 * 1000,
    },
  );
  const coordinates = vehicleCoordinatesQuery.data?.vehicleCoordinates?.[0];

  const onSubmit = async () => {
    setStatusLoading(true);
    let packageIds: number[] = [];
    cards.forEach(card =>
      card.relTourPackages.forEach(rel => {
        if (rel.packagesType === "UP") {
          packageIds.push(rel.packages.id);
        }
      }),
    );
    try {
      if (companyId) {
        await modifyPackageStatus({
          companyId: companyId,
          status: "TAKEN_FROM_STOCK",
          type: "PACKAGE",
          packageIds,
        });
      }
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: t("package.status.subject"),
        }),
        { variant: "success" },
      );
      refetch?.();
      setStatusOpen(false);
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.update.failure", {
          subject: t("package.subject"),
        }),
        { variant: "error" },
      );
    }
    setStatusLoading(false);
  };

  const [statusOpen, setStatusOpen] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <DialogTitle style={{ width: md && showMap ? 800 : 450 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            {tourPlanProp?.vehicleEntry?.licensePlateNumber} ({format(date, "yyyy. MM. dd.")})
          </Typography>
          <Box display="flex" alignItems="center">
            <Tooltip title={"Összes csomag raktárról felvéve státuszra állítása"}>
              <IconButton onClick={() => setStatusOpen(true)}>
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
            </Tooltip>
            <Dialog open={statusOpen} onClose={() => setStatusOpen(false)} fullWidth maxWidth="xs">
              <DialogTitle>Biztos benne?</DialogTitle>
              <DialogActions>
                <Button variant="text" onClick={() => setStatusOpen(false)}>
                  Mégse
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmit()}
                  loading={statusLoading}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="Térkép">
              <IconButton onClick={() => setShowMap(!showMap)}>
                <MapIcon />
              </IconButton>
            </Tooltip>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {toursQuery.isLoading ? (
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent style={{ width: md && showMap ? 800 : 450, height: "100%" }} dividers>
            <Grid container spacing={2}>
              {showMap && (
                <Grid item xs={12} sm={12} md={6}>
                  <MapContainer
                    center={[coordinates?.latitude || 47, coordinates?.longitude || 20]}
                    zoom={7}
                    style={{ height: md ? 600 : 300 }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {categorized.map(catTour => (
                      <MapMarker key={catTour.tours[0]?.id} categorizedTours={catTour} />
                    ))}
                    <VehicleMapMarker
                      vehicle={tourPlanProp?.vehicleEntry}
                      longitude={coordinates?.longitude}
                      latitude={coordinates?.latitude}
                    />
                  </MapContainer>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={showMap ? 6 : 12}>
                {cards.length ? (
                  cards?.map((tour, index, array) => {
                    let temp: number[] = [];
                    tour.relTourPackages.forEach(pack => {
                      if (wrongOrder.includes(pack.packages.id)) {
                        temp.push(pack.packages.id);
                      }
                    });
                    return (
                      <Fragment key={tour.id}>
                        <TourSortingListItem
                          cards={cards}
                          tour={tour}
                          index={index}
                          moveCard={moveCard}
                          address={tour?.address}
                          wrongOrderIds={temp}
                          refetch={() => {
                            refetch();
                            toursQuery.refetch();
                          }}
                          date={date}
                          mergeable={
                            array[index - 1]?.address.id === tour.address.id
                              ? array[index - 1].id
                              : undefined
                          }
                          orderChanged={orderChanged}
                        />
                        <Divider style={{ margin: "12px 0" }} />
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography variant="body1">{t("vehicle.error.empty")}</Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          {!orderChanged && isFinal && dragEnabled && (
            <Box display="flex" justifyContent="center" width="100%" p={3}>
              <Button
                loading={loading}
                color="secondary"
                variant="contained"
                onClick={async () => {
                  setLoading(true);
                  try {
                    await setTourToFinal({ params: tourPlan?.tourEntryList });
                    enqueueSnackbar(t("tour.successfulFinalization"), {
                      variant: "success",
                    });
                    refetch();
                    toursQuery.refetch();
                  } catch {
                    enqueueSnackbar(t("tour.failedFinalization"), {
                      variant: "error",
                    });
                  }
                  setLoading(false);
                }}
              >
                {t("tour.finalize")}
              </Button>
            </Box>
          )}
          <DialogActions style={{ display: "flex", padding: 0 }}>
            <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%">
              {!!wrongOrder.length && (
                <Alert
                  icon={<CloseIcon />}
                  variant="filled"
                  color="error"
                  style={{ width: "100%" }}
                >
                  {t("tour.wrongOrderAlert")}
                </Alert>
              )}
              {orderChanged && !!tourPlan?.tourEntryList && (
                <Box display="flex" margin="12px" gridGap={12}>
                  <Button
                    variant="text"
                    onClick={() => {
                      setCards(
                        [...tourPlan?.tourEntryList].sort((a, b) => a.tourOrder - b.tourOrder),
                      );
                      setOrderChanged(false);
                    }}
                  >
                    {t("tour.cancel")}
                  </Button>
                  <Button
                    loading={loading}
                    variant="contained"
                    color="primary"
                    disabled={!!wrongOrder.length}
                    onClick={async () => {
                      setLoading(true);
                      try {
                        await modifyTourOrder(cards);
                        enqueueSnackbar(t("tour.saveSuccess"), {
                          variant: "success",
                        });
                        refetch();
                        toursQuery.refetch();
                        setOrderChanged(false);
                      } catch {
                        enqueueSnackbar(t("tour.saveError"), {
                          variant: "error",
                        });
                      }
                      setLoading(false);
                    }}
                    startIcon={<CheckIcon />}
                  >
                    {t("common:button.save")}
                  </Button>
                </Box>
              )}
            </Box>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
};

export default TourSortingModal;
