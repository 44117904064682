import { Grid, TextField, Typography } from "@material-ui/core";
import Layout from "components/layout/Layout";
import { RootState } from "config/store";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { User } from "shared/types";
import React from "react";
import { getUserById, modifyUser } from "shared/network/user.api";
import { enqueueSnackbar } from "shared/reducers/application";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { fetchAccount } from "shared/reducers/authentication";
import { useQuery } from "react-query";
import Button from "components/form/Button";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const ProfileModify = ({ match }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = match.params;

  const [loading, setLoading] = React.useState(false);

  const { user } = useSelector((state: RootState) => state.authentication);

  const userQuery = useQuery(
    ["userQuery", id],
    async () => {
      const { data } = await getUserById(Number.parseInt(id));
      return data.item;
    },
    { enabled: !!id },
  );

  const { register, errors, handleSubmit, reset } = useForm<User>({
    defaultValues: userQuery.data,
  });

  async function onSubmit(values: User) {
    setLoading(true);
    try {
      await modifyUser({
        param: {
          ...values,
          id: Number(match.params.id),
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: userQuery.data?.password,
          token: userQuery.data?.token,
          status: userQuery.data?.status,
          userType: userQuery.data?.userType,
          phone: values.phone,
          identityCardNumber: values.identityCardNumber,
          addressCard: values.addressCard,
          birthDate: userQuery.data?.birthDate,
          permanentAddressId: userQuery.data?.permanentAddressId,
          permanentAddress: userQuery.data?.permanentAddress,
          temporaryAddressId: userQuery.data?.temporaryAddressId,
          temporaryAddress: userQuery.data?.temporaryAddress,
          postAddressId: userQuery.data?.postAddressId,
          postAddress: userQuery.data?.postAddress,
          isAdmin: userQuery.data?.isAdmin,
          isCustomerService: userQuery.data?.isCustomerService,
          validityOfMedicalFitness: userQuery.data?.validityOfMedicalFitness,
          relUserCompanies: userQuery.data?.relUserCompanies,
        },
      });
      setLoading(false);
      dispatch(fetchAccount());
      history.push(`/profile/${user?.id}`);
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.update.success", {
            subject: t("profile.subject"),
          }),
          options: { variant: "success" },
        }),
      );
    } catch (e) {
      setLoading(false);
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.update.failure", {
            subject: t("profile.subject"),
          }),
          options: { variant: "error" },
        }),
      );
    }
  }

  React.useEffect(() => {
    reset(userQuery.data);
  }, [reset, userQuery.data]);

  return (
    <Layout title={t("profile.modify.title")} maxWidth="md">
      {user?.id.toString() === id ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ marginBottom: 20 }} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                type="text"
                label={t("common:field.lastName")}
                InputLabelProps={{ required: true, shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.lastName && true}
                helperText={errors?.lastName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                type="text"
                label={t("common:field.firstName")}
                InputLabelProps={{ required: true, shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.firstName && true}
                helperText={errors?.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                type="text"
                label={t("common:field.email")}
                InputLabelProps={{ required: true, shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.email && true}
                helperText={errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="phone"
                type="text"
                placeholder="+36"
                defaultValue="+36"
                label={t("common:field.phone")}
                InputLabelProps={{ required: true, shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.phone && true}
                helperText={errors?.phone?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="identityCardNumber"
                type="text"
                label={t("common:field.identityCardNumber")}
                InputLabelProps={{ required: true, shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.identityCardNumber && true}
                helperText={errors?.identityCardNumber?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="addressCard"
                type="text"
                label={t("common:field.addressCard")}
                InputLabelProps={{ required: true, shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.addressCard && true}
                helperText={errors?.addressCard?.message}
              />
            </Grid>
          </Grid>
          <Button color="primary" type="submit" loading={loading}>
            {t("common:button.save")}
          </Button>
        </form>
      ) : (
        <Typography style={{ color: "gray", textAlign: "center" }}>
          {t("profile.modify.unauthorized")}
        </Typography>
      )}
    </Layout>
  );
};

export default ProfileModify;
