import { Grid, makeStyles, Typography } from "@material-ui/core";
import { palette } from "config/theme";
import { useTranslation } from "react-i18next";
import { Company } from "shared/types";

type Props = {
  company?: Company;
  refetch: () => void;
};

const useStyles = makeStyles(
  () => ({
    header: {
      color: palette.main,
      fontSize: 16,
      borderBottom: "1px solid darkgray",
      marginBottom: 16,
    },
    title: {
      color: "grey",
    },
    data: {
      fontSize: 16,
    },
    iconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginBottom: 8,
    },
  }),
  { name: "CompanyDetails" },
);

const DeliveryExtraChargeListItem = ({ company, refetch }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.with_loader_extra_charge")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.withLoaderExtraCharge
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.hazardous_extra_charge_percent")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.hazardousExtraChargePercent
              : "-"}
            {" %"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.fragile_extra_charge")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.fragileExtraCharge
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.cash_on_delivery_minimum_price")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.cashOnDeliveryMinimumPrice
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.cash_on_delivery_percent")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.cashOnDeliveryPercent
              : "-"}
            {" %"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.expressDeliveryPrice")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.expressDeliveryPrice
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.uniqueInsurancePercent")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.uniqueInsurancePercent
              : "-"}
            {" %"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.authorityToLeavePercent")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.authorityToLeavePercent
              : "-"}
            {" %"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.weekendDelivery")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.weekendDelivery
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.weekendDeliveryWithLoader")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.weekendDeliveryWithLoader
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.storagePriceDaily")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.storagePriceDaily
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.freeStorageDays")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.freeStorageDays
              : "-"}
            {t("common:dayPostfix")}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>
            {t("company.deliveryExtraCharge.electricCarExtraPercent")}
          </div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.electricCarExtraPercent
              : "-"}
            {" %"}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
            marginTop: "8px",
            marginBottom: "4px",
            borderBottom: "1px solid grey",
          }}
        >
          <Typography variant="h6">{t("company.deliveryExtraCharge.removeGoodsTitle")}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.whiteGoods")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.whiteGoods
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalBathroom")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalBathroom
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalMattress")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalMattress
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalSofas")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalSofas
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalKitchen")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalKitchen
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalElectronics")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalElectronics
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalFurniture")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalFurniture
              : "-"}
            {" Ft"}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.title}>{t("company.deliveryExtraCharge.removalAdditional")}</div>
          <div className={classes.data}>
            {company?.deliveryExtraChargeEntry
              ? company?.deliveryExtraChargeEntry?.removalAdditional
              : "-"}
            {" Ft"}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DeliveryExtraChargeListItem;
