import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getDeliveryPriceForPackage } from "shared/network/deliveryprice.api";
import { Package } from "shared/types";

const PackageDeliveryPriceModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setValue, trigger, watch } = useFormContext();

  const { targetAddress, companyId, packages, value, isCashOnDelivery } = watch([
    "targetAddress",
    "companyId",
    "packages",
    "value",
    "isCashOnDelivery",
  ]);

  async function getDeliverPrice() {
    try {
      const { data } = await getDeliveryPriceForPackage(
        packages.map((entry: Package) => {
          return { ...entry, value: value, isCashOnDelivery: isCashOnDelivery };
        }),
        companyId,
        targetAddress.zipCode,
      );
      setValue("deliveryPriceToWebshop", data.allPrice);
    } catch (error: any) {
      if (error?.data?.status === "MISSING_EXTRA_PRICE") {
        enqueueSnackbar("Nem található extra költség a céghez.", {
          variant: "error",
        });
      } else if (error?.data?.status === "MISSING_ZIP_ZONE") {
        enqueueSnackbar("Nem található ilyen irányítószám a céghez.", {
          variant: "error",
        });
      } else if (error?.data?.status === "MISSING_VOLUME_MATRIX") {
        enqueueSnackbar("Nem található ilyen térfogat a céghez.", {
          variant: "error",
        });
      } else if (error?.data?.status === "MISSING_WEIGHT_MATRIX") {
        enqueueSnackbar("Nem található ilyen súly a céghez.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("common:notification.noPackagePriceData"), { variant: "error" });
      }
    }
  }

  return (
    <Button
      style={{ marginTop: 8 }}
      color="primary"
      variant="contained"
      onClick={async () => {
        const packs = packages?.map((value: any, index: number) => {
          return [
            `packages.${index}.weight`,
            `packages.${index}.length`,
            `packages.${index}.width`,
            `packages.${index}.height`,
          ];
        });
        packs.push(["targetAddress.zipCode", "companyId", "value", "isCashOnDelivery"]);
        const result = await trigger(packs.flat());
        if (result) {
          getDeliverPrice();
        }
      }}
    >
      {t("common:button.packagePrice")}
    </Button>
  );
};

export default PackageDeliveryPriceModal;
