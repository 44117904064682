import axios from "config/axios";
import { Address, GenericListResponse, GenericResponse, Note } from "shared/types";

const ENDPOINT = "/notes";

export const createNote = ({
  file,
  note,
  subjectType,
  subjectId,
  type,
}: {
  subjectType: string;
  type: string;
  subjectId: number;
  file?: File;
  note?: string;
}) => {
  const formData = new FormData();
  formData.set("subjectType", subjectType);
  formData.set("type", type);
  formData.set("subjectId", subjectId.toString());
  file && formData.set("file", file);
  note && formData.set("note", note);
  return axios.post<GenericResponse<Address>>(`${ENDPOINT}/with-file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getNotes = (data: { subjectType: string; subjectId: number }) =>
  axios.post<GenericListResponse<Note>>(`${ENDPOINT}/list-by-subject`, data);

export const getNoteFile = (id: number) =>
  axios.get(`${ENDPOINT}/file?id=${id}`, {
    responseType: "blob",
  });
