import { AUTH_TOKEN_KEY } from "config/constants";
import { add } from "date-fns";

export function getCookie(name: string) {
  return document.cookie
    ?.split("; ")
    ?.find(row => row.startsWith(name))
    ?.split("=")[1];
}
function removeCookie(name: string) {
  document.cookie = name + "=; Max-Age=-99999999;";
}

function setCookie(name: string, value: string) {
  if (getCookie("rememberMe")) {
    const expires = add(new Date(), { days: 30 }).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}`;
  } else {
    const expires = add(new Date(), { days: 1 }).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}`;
  }
}

export function getAuthenticationToken() {
  return getCookie(AUTH_TOKEN_KEY);
}

export function autheticationTokenExists() {
  return getCookie(AUTH_TOKEN_KEY);
}

export function saveAuthenticationToken(token: string, longTerm?: boolean) {
  setCookie(AUTH_TOKEN_KEY, token);
}

export function removeAuthenticationToken() {
  removeCookie(AUTH_TOKEN_KEY);
  removeCookie("rememberMe");
}
