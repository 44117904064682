import { Address } from "shared/types";
import i18next from "i18next";

export default function displayAddress(address?: Address) {
  return address
    ? `${address.zipCode} ${address.city}, ${address.areaName} ${
        !!address.houseNumber ? i18next.t(`common:areaType.${address.areaType}`) : ""
      } ${address.houseNumber}. ${address.floor ? address.floor : ""}`
    : "";
}
