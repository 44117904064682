import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import Tour from "./Tour";
import TourDetails from "./TourDetails";

const TourRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={Tour} permissions={["SHIPORGANIZER"]} />
      <PrivateRoute
        path={`${match.path}/vehicle/:id`}
        component={TourDetails}
        permissions={["SHIPORGANIZER", "SHIPPER"]}
      />
    </Switch>
  );
};

export default TourRoutes;
