import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Storage, Warehouse } from "shared/types";
import StorageItemCreateModal from "components/storage/StorageItemCreateModal";
import { useImmer } from "use-immer";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { deleteStoragesById } from "shared/network/storage.api";

type Props = {
  warehouse: Warehouse;
  storages?: Storage[];
  onSubmitSuccess?: () => void;
};

const StorageItemList = ({ warehouse, storages, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [checkedStorageIds, setCheckedStorageIds] = useImmer<number[]>([]);

  const mutation = useMutation(
    async () => {
      const { data } = await deleteStoragesById(checkedStorageIds);
      return data;
    },
    {
      onSuccess: () => {
        onSubmitSuccess?.();
        enqueueSnackbar(
          t("common:notification.delete.success", {
            subject: t("storage.subject"),
          }),
          { variant: "success" },
        );
        setCheckedStorageIds([]);
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("storage.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          alignItems: "center",
          fontSize: 12,
          position: "sticky",
          top: 0,
          background: "#fafafa",
          zIndex: 1,
        }}
      >
        <Grid item xs={8}>
          <Typography variant="h6">{t("storage.Storages")}</Typography>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
          {mutation.isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Tooltip title={t("storage.delete").toString()}>
                <>
                  <IconButton
                    color="primary"
                    onClick={() => mutation.mutate()}
                    size="small"
                    disabled={!checkedStorageIds.length}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              </Tooltip>
            </>
          )}

          <StorageItemCreateModal warehouse={warehouse} onSubmitSuccess={onSubmitSuccess} />
        </Grid>
      </Grid>

      {storages?.length ? (
        <>
          <Grid
            container
            spacing={2}
            style={{
              alignItems: "center",
              fontSize: 12,
              position: "sticky",
              top: 0,
              background: "#fafafa",
              zIndex: 1,
              padding: "12px 0",
            }}
          >
            <Grid item xs={2}>
              <Checkbox
                checked={checkedStorageIds.length === storages.length}
                onChange={() => {
                  if (checkedStorageIds.length === storages.length) {
                    setCheckedStorageIds([]);
                  } else {
                    setCheckedStorageIds(storages.map(storage => storage.id));
                  }
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>{t("storage.name")}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>{t("storage.storageCode")}</Typography>
            </Grid>
          </Grid>

          {storages.map(storage => (
            <Grid
              container
              key={storage.id}
              style={{
                alignItems: "center",
                fontSize: 12,
                position: "sticky",
                top: 0,
                background: "#fafafa",
                zIndex: 1,
                borderTop: "1px solid #ddd",
                padding: "12px 0",
              }}
            >
              <Grid item xs={2}>
                <Checkbox
                  checked={checkedStorageIds.includes(storage.id)}
                  onChange={() => {
                    if (checkedStorageIds.includes(storage.id)) {
                      setCheckedStorageIds(state => {
                        state.splice(state.indexOf(storage.id), 1);
                        return state;
                      });
                    } else {
                      setCheckedStorageIds(state => {
                        state.push(storage.id);
                        return state;
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography>{storage.name}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{storage.storageCode}</Typography>
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Typography>{t("common:noData")}</Typography>
      )}
    </>
  );
};

export default StorageItemList;
