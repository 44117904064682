import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import WarehouseDetails from "views/warehouse/WarehouseDetails";
import WarehouseModify from "views/warehouse/WarehouseModify";
import WarehouseCreate from "./WarehouseCreate";
import WarehouseList from "./WarehouseList";

const WarehouseRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={WarehouseList} />
      <PrivateRoute path={`${match.path}/create`} component={WarehouseCreate} />
      <PrivateRoute path={`${match.path}/modify/:id`} component={WarehouseModify} />
      <PrivateRoute path={`${match.path}/details/:id`} component={WarehouseDetails} />
    </Switch>
  );
};

export default WarehouseRoutes;
