import { Box, IconButton, TextField } from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";
import PageLoading from "components/PageLoading";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MatrixType, saveDeliveryPrice } from "shared/network/deliveryprice.api";
import { DeliveryPrice, Zone } from "views/company/CompanyZone";
import { PriceRow } from "./DeliveryPriceTable";

type Props = {
  type: "WEIGHT" | "VOLUME";
  row: PriceRow;
  zoneList?: Zone[];
  isEditingId: number | null | undefined;
  setEditingId: Dispatch<SetStateAction<number | null | undefined>>;
  index: number;
  companyId: string;
  rowData: PriceRow[];
  matrixType: MatrixType;
};

type FormValues = {
  id?: number;
  weightFrom: string;
  weightTo: string;
  volumeFrom: string;
  volumeTo: string;
  deliveryPrice: DeliveryPrice[];
};

const DeliveryPriceRow = ({
  type,
  row,
  zoneList,
  isEditingId,
  setEditingId,
  index,
  companyId,
  rowData,
  matrixType,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>();

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (type === "WEIGHT") {
        let data = rowData.filter(entry => entry.id !== row.id);
        data.push({
          id: row.id,
          weightFrom: values.weightFrom,
          weightTo: values.weightTo,
          priceEntries: values.deliveryPrice,
        });
        data = data.sort((a, b) => {
          if (!!a.weightFrom && !!b.weightFrom) {
            let startA = Number.parseFloat(a?.weightFrom);
            let startB = Number.parseFloat(b?.weightFrom);
            return startA < startB ? -1 : startB < startA ? 1 : 0;
          }
          return 0;
        });
        for (let i = 1; i < data.length; i++) {
          if (
            Number.parseFloat(data[i - 1].weightTo || "0") >
            Number.parseFloat(data[i].weightFrom || "0")
          ) {
            throw new Error("INTERVAL_ERROR");
          }
        }
        await saveDeliveryPrice({
          companyId,
          deliveryPriceListWeight: values.deliveryPrice.map((item, index) => {
            return {
              ...item,
              fromZone: zoneList?.[index]?.id,
              weightFrom: values.weightFrom,
              weightTo: values.weightTo,
              type: matrixType,
            };
          }),
          deliveryPriceListVolume: undefined,
        });
      }

      if (type === "VOLUME") {
        let data = rowData.filter(entry => entry.id !== row.id);
        data.push({
          id: row.id,
          volumeFrom: values.volumeFrom,
          volumeTo: values.volumeTo,
          priceEntries: values.deliveryPrice,
        });
        data = data.sort((a, b) => {
          if (!!a.volumeFrom && !!b.volumeFrom) {
            let startA = Number.parseFloat(a?.volumeFrom);
            let startB = Number.parseFloat(b?.volumeFrom);
            return startA < startB ? -1 : startB < startA ? 1 : 0;
          }
          return 0;
        });
        for (let i = 1; i < data.length; i++) {
          if (
            Number.parseFloat(data[i - 1].volumeTo || "0") >
            Number.parseFloat(data[i].volumeFrom || "0")
          ) {
            throw new Error("INTERVAL_ERROR");
          }
        }
        await saveDeliveryPrice({
          companyId,

          deliveryPriceListWeight: undefined,
          deliveryPriceListVolume: values.deliveryPrice.map((item, index) => {
            return {
              ...item,
              fromZone: zoneList?.[index]?.id,
              volumeFrom: values.volumeFrom,
              volumeTo: values.volumeTo,
              type: matrixType,
            };
          }),
        });
      }
      setEditingId(null);
      queryClient.invalidateQueries("deliveryPriceListQuery");
      enqueueSnackbar(
        t("common:notification.save.success", {
          subject: "ár",
        }),
        { variant: "success" },
      );
    } catch (e: any) {
      if (e.message === "INTERVAL_ERROR") {
        enqueueSnackbar(t("common:notification.zoneRangeConflict"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.save.failure", {
            subject: "ár",
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box width={((zoneList?.length || 0) + 1) * 180} marginLeft="auto" marginRight="auto">
          <Box display="flex" alignItems="center" justifyContent="center">
            <PageLoading open={loading} />
            <Box
              display="flex"
              alignItems="center"
              height={48}
              width={180}
              paddingX={1}
              borderBottom="1px solid grey"
            >
              {isEditingId === index ? (
                <>
                  {type === "WEIGHT" ? (
                    <>
                      <TextField
                        name="weightFrom"
                        margin="none"
                        defaultValue={
                          row?.weightFrom?.toString() === "0" ? "0" : row?.weightFrom || ""
                        }
                        InputProps={{ style: { height: 30 } }}
                        inputRef={form.register({
                          required: {
                            value: true,
                            message: t("validation:required"),
                          },
                        })}
                        error={!!form.errors?.weightFrom}
                      />
                      <Box p={1}>-</Box>
                      <TextField
                        name="weightTo"
                        margin="none"
                        defaultValue={row?.weightTo || ""}
                        InputProps={{ style: { height: 30 } }}
                        inputRef={form.register({
                          required: {
                            value: true,
                            message: t("validation:required"),
                          },
                        })}
                        error={!!form.errors?.weightTo}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        name="volumeFrom"
                        margin="none"
                        defaultValue={
                          row?.volumeFrom?.toString() === "0" ? "0" : row?.volumeFrom || ""
                        }
                        InputProps={{ style: { height: 30 } }}
                        inputRef={form.register({
                          required: {
                            value: true,
                            message: t("validation:required"),
                          },
                        })}
                        error={!!form.errors?.volumeFrom}
                      />
                      <Box p={1}>-</Box>
                      <TextField
                        name="volumeTo"
                        margin="none"
                        defaultValue={row?.volumeTo || ""}
                        InputProps={{ style: { height: 30 } }}
                        inputRef={form.register({
                          required: {
                            value: true,
                            message: t("validation:required"),
                          },
                        })}
                        error={!!form.errors?.volumeTo}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {type === "WEIGHT" ? (
                    <>
                      {row?.weightFrom}
                      <Box p={1}>-</Box>
                      {row?.weightTo}
                    </>
                  ) : (
                    <>
                      {row?.volumeFrom}
                      <Box p={1}>-</Box>
                      {row?.volumeTo}
                    </>
                  )}
                </>
              )}
            </Box>
            {zoneList?.map((zone, idx) => {
              const deliveryPrice = row.priceEntries.find(
                deliveryPrice => deliveryPrice.fromZone === zone.id,
              );

              return isEditingId === index ? (
                <Box
                  key={zone.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gridGap={8}
                  height={48}
                  width={180}
                  paddingX={1}
                  borderBottom="1px solid grey"
                >
                  <input
                    name={`deliveryPrice.${idx}.id`}
                    value={deliveryPrice?.id}
                    type="hidden"
                    ref={form.register()}
                  />
                  <TextField
                    name={`deliveryPrice.${idx}.price`}
                    margin="none"
                    defaultValue={deliveryPrice?.price || ""}
                    InputProps={{
                      style: { height: 30, paddingRight: 8 },
                    }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={!!form.errors?.deliveryPrice?.[idx]?.price}
                  />
                </Box>
              ) : (
                <Box
                  key={zone.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gridGap={8}
                  height={48}
                  width={180}
                  paddingX={1}
                  borderBottom="1px solid grey"
                >
                  {t("common:number", { num: deliveryPrice?.price })}
                </Box>
              );
            })}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gridGap={8}
              height={48}
              width={80}
              paddingX={1}
              borderBottom="1px solid grey"
            >
              {isEditingId !== index && (
                <IconButton id="edit" size="small" onClick={() => setEditingId(index)}>
                  <Edit />
                </IconButton>
              )}
              {isEditingId === index && (
                <>
                  <IconButton type="submit" size="small">
                    <Check />
                  </IconButton>
                  <IconButton size="small" onClick={() => setEditingId(null)}>
                    <Close />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default DeliveryPriceRow;
