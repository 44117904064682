import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import PageLoading from "components/PageLoading";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MatrixType } from "shared/network/deliveryprice.api";
import { createDeliveryZone } from "shared/network/deliveryzone.api";

type Props = {
  id: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  matrixType: MatrixType;
};

type FormValues = {
  name: string;
};

const CreateZoneModal = ({ id, open, setOpen, matrixType }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>();

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createDeliveryZone({ ...values, companyId: parseInt(id), type: matrixType });
      setOpen(false);
      queryClient.invalidateQueries("companyZoneQuery");
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: "zóna",
        }),
        { variant: "success" },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: "zóna",
        }),
        { variant: "error" },
      );
    }
    setLoading(false);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <PageLoading open={loading} />
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth disablePortal>
          <DialogTitle>{t("companyZone.createTitle")}</DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              label={t("companyZone.formValues.name")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={form.register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!form.errors?.name}
              helperText={form.errors?.name?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setOpen(false)}>
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t("common:button.ok")}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};
export default CreateZoneModal;
