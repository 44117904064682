import Layout from "components/layout/Layout";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import VehicleForm, { VehicleFormFields } from "./components/VehicleForm";
import { createVehicle } from "shared/network/vehicle.api";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import Button from "components/form/Button";
import React from "react";

const VehicleCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<VehicleFormFields>();

  const mutation = useMutation<any, any, VehicleFormFields>(
    async values => {
      await createVehicle({
        ...values,
        companyId: values.companyId,
        licensePlateNumber: values.licensePlateNumber,
        brand: values.brand,
        type: values.type,
        weightCapacity: values.weightCapacity,
        volume: values.height * values.width * values.length,
        firstRegisteredDate: values.firstRegisteredDate,
        carDocumentsExpirationDate: values.carDocumentsExpirationDate,
        registeredDateInHungary: values.registeredDateInHungary,
        tachographExpirationDate: values.tachographExpirationDate,
        userId: values.userId,
      });
    },
    {
      onSuccess: () => {
        history.push("/vehicle");
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("vehicle.subject"),
          }),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("vehicle.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <Layout title={t("vehicle.create.title")} maxWidth="lg">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(values => mutation.mutate(values))}>
          <VehicleForm />
          <Button style={{ margin: 20 }} loading={mutation.isLoading} color="primary" type="submit">
            {t("common:button.create")}
          </Button>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default VehicleCreate;
