import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { palette } from "config/theme";

const PasswordTextField = (props: TextFieldProps) => {
  const [show, setShow] = React.useState(false);

  function toggleVisibility() {
    setShow(prev => !prev);
  }

  return (
    <TextField
      {...props}
      type={show ? "text" : "password"}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end" style={{ position: "absolute", right: 0 }}>
            <IconButton aria-label="toggle password visibility" onClick={toggleVisibility}>
              {show ? (
                <VisibilityOutlinedIcon style={{ color: palette.lightMain }} />
              ) : (
                <VisibilityOffOutlinedIcon style={{ color: palette.lightMain }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
