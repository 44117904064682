import axios from "config/axios";
import { GenericListResponse } from "shared/types";
import { Zone } from "views/company/CompanyZone";
import { MatrixType } from "./deliveryprice.api";

const ENDPOINT = "/deliveryzone";

export const createDeliveryZone = (param: Zone) => axios.post(ENDPOINT, { param });

export const modifyDeliveryZone = (param: Zone) => axios.put(ENDPOINT, { param });

export const getDeliveryZoneListByCompanyId = (companyId: string, type: MatrixType) =>
  axios.post<GenericListResponse<Zone>>(`${ENDPOINT}/by-company-and-type`, {
    companyId,
    type,
  });
