const urlCreator = window.URL || window.webkitURL;

const fileDownload = (data: string, filename?: string) => {
  const blob = new Blob([data], { type: "octet/stream" });
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(blob);
  link.setAttribute("download", filename ?? "");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default fileDownload;
