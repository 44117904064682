import axios from "config/axios";
import { DeliveryRequest, GenericResponse } from "shared/types";

const ENDPOINT = "/deliveryextracharge";

export const createDeliveryExtraChargeToCompany = (data: DeliveryRequest & { companyId: number }) =>
  axios.post<GenericResponse<DeliveryRequest>>(ENDPOINT, { param: data });

export const getCompanyDeliveryExtraCharge = (companyId: number) =>
  axios.get<GenericResponse<DeliveryRequest>>(`/deliveryextracharge?companyId=${companyId}`);

export const modifyCompanyDeliveryExtraCharge = (data: DeliveryRequest) =>
  axios.put<GenericResponse<DeliveryRequest>>(ENDPOINT, { param: data });
