import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/form/Button";
import PasswordTextField from "components/form/PasswordTextField";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalState } from "shared/hooks/useModal";
import { modifyUser } from "shared/network/user.api";
import { User } from "shared/types";
import { PASSWORD_POLICY_REGEX } from "shared/util/validation";

type PasswordFieldValues = {
  password: string;
  confirmPassword: string;
};

type Props = {
  state: ModalState<User>;
  onClose: () => void;
};

const ChangePasswordModal = ({ state, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const { handleSubmit, errors, register, watch } = useForm<PasswordFieldValues>();

  async function onSubmit(values: PasswordFieldValues) {
    setLoading(true);
    try {
      await modifyUser({
        param: {
          ...state?.defaultValues,
          password: values.confirmPassword,
        },
      });
      setLoading(false);
      onClose();
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: t("profile.changePassword.subject"),
        }),
        { variant: "success" },
      );
    } catch (e: any) {
      enqueueSnackbar(
        t("common:notification.update.failure", {
          subject: t("profile.changePassword.subject"),
        }),
        { variant: "error" },
      );
      setError(e?.data?.status);
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={!!state?.type}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
        style: { padding: 20 },
      }}
    >
      <DialogTitle style={{ textAlign: "center" }}>{t("profile.changePassword.title")}</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{t("profile.changePassword.error")}</Alert>}
        <PasswordTextField
          name="password"
          type="password"
          label={t("common:field.profile.newPassword")}
          inputRef={register({
            required: { value: true, message: t("validation:required") },
            validate: value => {
              if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                return t("validation:passwordPolicy").toString();
              }
              if (value !== watch("confirmPassword")) {
                return t("validation:passwordMismatch").toString();
              }
            },
          })}
          error={errors.password && true}
          helperText={t("validation:passwordPolicy")}
        />
        <PasswordTextField
          name="confirmPassword"
          type="password"
          label={t("common:field.profile.newPasswordConfirm")}
          inputRef={register({
            required: { value: true, message: t("validation:required") },
            validate: value => {
              if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                return t("validation:passwordPolicy").toString();
              }
              if (value !== watch("password")) {
                return t("validation:passwordMismatch").toString();
              }
            },
          })}
          error={errors.password && true}
          helperText={errors.password?.message}
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "space-evenly",
          paddingBottom: 16,
        }}
      >
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary" variant="contained" loading={loading}>
          {t("common:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
