import axios from "config/axios";
import { Company, Contact, GenericListResponse, GenericResponse } from "shared/types";

const ENDPOINT = "/contact";

export const sendEmail = (data: {
  contactIdList: number[];
  emails: string[];
  description: string;
}) => axios.post<GenericResponse<Company>>(`${ENDPOINT}/send-email`, data);

export const createContact = (data: {
  companyId: number;
  name: string;
  email: string;
  phone: string;
  comment: string;
}) => axios.post(ENDPOINT, { param: data });

export const modifyContact = (data: Contact) => axios.put(ENDPOINT, { param: data });

export const getContactsByCompanyId = (id: number) =>
  axios.get<GenericListResponse<Contact>>(`${ENDPOINT}/company?companyId=${id}`);
export const getContactsByShopId = (id: number) =>
  axios.get<GenericListResponse<Contact>>(`${ENDPOINT}/shop?shopId=${id}`);
