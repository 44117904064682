import axios from "config/axios";
import { Tour } from "views/tour/components/TourSortingModal";

const ENDPOINT = "/tour";

export const createTour = (data: any) => axios.post(ENDPOINT, data);

export const createMultiSelectTour = (data: any) => axios.post(`${ENDPOINT}/create-multiple`, data);

export const modifyTourOrder = (data: any) => axios.post(`${ENDPOINT}/order`, { params: data });

export const tourListByVehicle = (data: any) =>
  axios.post<{ tourEntryList: Tour[] }>(`${ENDPOINT}/list-by-vehicle-id-and-date`, data);

export const modifyTourAddress = (data: any) => axios.post(`${ENDPOINT}/address`, data);

export const setTourToFinal = (data: any) => axios.post(`${ENDPOINT}/set-to-final`, data);

export const splitTour = (data: { packagesId: number; tourId: number }) =>
  axios.put("/reltourpackages", data);

export const mergeTour = (data: { firstTourId: number; secondTourId: number }) =>
  axios.put(`${ENDPOINT}/merge?firstTourId=${data.firstTourId}&secondTourId=${data.secondTourId}`);

export const exportTour = (data: { date: string; vehicleId: number }) =>
  axios.post(`${ENDPOINT}/export`, data, {
    responseType: "blob",
  });
