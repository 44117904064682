import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Layout from "components/layout/Layout";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { getVehicleById } from "shared/network/vehicle.api";
import { makeStyles } from "@material-ui/core/styles";
import { palette } from "config/theme";
import { format } from "date-fns";
import hu from "date-fns/esm/locale/hu/index.js";
import { isNil } from "lodash";

const useStyles = makeStyles(
  () => ({
    header: {
      color: palette.main,
      fontSize: 16,
      borderBottom: "1px solid darkgray",
      marginBottom: 16,
    },
    title: {
      color: "grey",
    },
    data: {
      fontSize: 16,
      marginBottom: 8,
    },
    iconData: {
      display: "flex",
      alignItems: "center",
      gridGap: 8,
      fontSize: 16,
      marginBottom: 8,
    },
  }),
  { name: "VehicleDetails" },
);

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const VehicleDetails = ({ match }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const id = Number.parseInt(match.params.id);

  const vehicleQuery = useQuery(["vehicleQueryDetails", id], async () => {
    const { data } = await getVehicleById(id);
    return data.item;
  });
  return (
    <Layout title={t("vehicle.details.title")} maxWidth="lg">
      {vehicleQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography className={classes.header}>Általános adatok</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.user")}</div>
              <div className={classes.data}>
                {vehicleQuery.data?.user
                  ? `${vehicleQuery.data?.user?.lastName} ${vehicleQuery.data?.user?.firstName}`
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.phone")}</div>
              <div className={classes.data}>{vehicleQuery.data?.phone || "-"}</div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.licensePlateNumber")}</div>
              <div className={classes.data}>{vehicleQuery.data?.licensePlateNumber}</div>
              <div className={classes.title}>{t("vehicle.properties.brand")}</div>
              <div className={classes.data}>{vehicleQuery.data?.brand}</div>
              <div className={classes.title}>{t("vehicle.properties.type")}</div>
              <div className={classes.data}>{vehicleQuery.data?.type}</div>
              <div className={classes.title}>{t("vehicle.properties.vehicleColor")}</div>
              <div className={classes.data}>{vehicleQuery.data?.vehicleColor || "-"}</div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.chassisNumber")}</div>
              <div className={classes.data}>{vehicleQuery.data?.chassisNumber || "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.motorCode")}</div>
              <div className={classes.data}>{vehicleQuery.data?.motorCode || "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.cylinderCapacity")}</div>
              <div className={classes.data}>
                {isNil(vehicleQuery.data?.cylinderCapacity)
                  ? "-"
                  : `${t("common:number", {
                      num: vehicleQuery.data?.cylinderCapacity,
                    })} cm³`}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.productionYear")}</div>
              <div className={classes.data}>{vehicleQuery.data?.productionYear ?? "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.gearboxType")}</div>
              <div className={classes.data}>{vehicleQuery.data?.gearboxType || "-"}</div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.seatNumber")}</div>
              <div className={classes.data}>{vehicleQuery.data?.seatNumber ?? "-"}</div>
            </Grid>
          </Grid>

          <Typography className={classes.header}>{t("vehicle.create.cargospaceData")}</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.height")}</div>
              <div className={classes.data}>
                {`${t("common:number", {
                  num: vehicleQuery.data?.height,
                })} m`}
              </div>
              <div className={classes.title}>{t("vehicle.properties.width")}</div>
              <div className={classes.data}>
                {`${t("common:number", {
                  num: vehicleQuery.data?.width,
                })} m`}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.length")}</div>
              <div className={classes.data}>
                {`${t("common:number", {
                  num: vehicleQuery.data?.length,
                })} m`}
              </div>
              <div className={classes.title}>{t("vehicle.properties.volume")}</div>
              <div className={classes.data}>
                {`${t("common:number", {
                  num: vehicleQuery.data?.volume,
                })} m³`}
              </div>
            </Grid>
          </Grid>

          <Typography className={classes.header}>{t("vehicle.create.technicalData")}</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.weightCapacity")}</div>
              <div className={classes.data}>
                {`${t("common:number", {
                  num: vehicleQuery.data?.weightCapacity,
                })} kg`}
              </div>
              <div className={classes.title}>{t("vehicle.properties.vehicleCategory")}</div>
              <div className={classes.data}>
                {vehicleQuery.data?.vehicleCategory
                  ? t(`common:vehicleType.${vehicleQuery.data?.vehicleCategory}`)
                  : "-"}
              </div>
              <div className={classes.title}>{t("vehicle.properties.propellant")}</div>
              <div className={classes.data}>
                {vehicleQuery.data?.propellant
                  ? t(`common:fuelTypes.${vehicleQuery.data?.propellant}`)
                  : "-"}
              </div>
              <div className={classes.title}>{t("vehicle.properties.co2Amount")}</div>
              <div className={classes.data}>
                {vehicleQuery.data?.co2Amount
                  ? `${t("common:number", {
                      num: vehicleQuery.data?.co2Amount,
                    })} g / km`
                  : "-"}
              </div>
              <div className={classes.title}>
                {t("vehicle.properties.carDocumentsExpirationDate")}
              </div>
              <div className={classes.data}>
                {vehicleQuery.data?.firstRegisteredDate
                  ? format(new Date(vehicleQuery.data?.carDocumentsExpirationDate), "P", {
                      locale: hu,
                    })
                  : "-"}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>
                {t("vehicle.properties.tachographExpirationDate")}
              </div>
              <div className={classes.data}>
                {vehicleQuery.data?.firstRegisteredDate
                  ? format(new Date(vehicleQuery.data?.tachographExpirationDate), "P", {
                      locale: hu,
                    })
                  : "-"}
              </div>
              <div className={classes.title}>{t("vehicle.properties.allWeight")}</div>
              <div className={classes.data}>
                {isNil(vehicleQuery.data?.allWeight)
                  ? "-"
                  : `${t("common:number", {
                      num: vehicleQuery.data?.allWeight,
                    })} kg`}
              </div>
              <div className={classes.title}>{t("vehicle.properties.selfWeight")}</div>
              <div className={classes.data}>
                {isNil(vehicleQuery.data?.selfWeight)
                  ? "-"
                  : `${t("common:number", {
                      num: vehicleQuery.data?.selfWeight,
                    })} kg`}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.maxPerformance")}</div>
              <div className={classes.data}>
                {isNil(vehicleQuery.data?.maxPerformance)
                  ? "-"
                  : `${t("common:number", {
                      num: vehicleQuery.data?.maxPerformance,
                    })} kW`}
              </div>
            </Grid>
          </Grid>

          <Typography className={classes.header}>{t("vehicle.create.otherData")}</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.environmentalCategory")}</div>
              <div className={classes.data}>{vehicleQuery.data?.environmentalCategory || "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.performanceWeightRatio")}</div>
              <div className={classes.data}>
                {isNil(vehicleQuery.data?.performanceWeightRatio)
                  ? "-"
                  : t("common:number", {
                      num: vehicleQuery.data?.performanceWeightRatio,
                    })}
              </div>
              <div className={classes.title}>{t("vehicle.properties.axleNumber")}</div>
              <div className={classes.data}>{vehicleQuery.data?.axleNumber ?? "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.typeApproveNumber")}</div>
              <div className={classes.data}>{vehicleQuery.data?.typeApproveNumber || "-"}</div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.haulData")}</div>
              <div className={classes.data}>{vehicleQuery.data?.haulData || "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.colEncumbrance")}</div>
              <div className={classes.data}>
                {isNil(vehicleQuery.data?.colEncumbrance)
                  ? "-"
                  : t("common:number", {
                      num: vehicleQuery.data?.colEncumbrance,
                    })}
              </div>
              <div className={classes.title}>{t("vehicle.properties.eurotaxCode")}</div>
              <div className={classes.data}>{vehicleQuery.data?.eurotaxCode || "-"}</div>
              <div className={classes.title}>{t("vehicle.properties.euroPaletteCapacity")}</div>
              <div className={classes.data}>{vehicleQuery.data?.euroPaletteCapacity || "-"}</div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.firstRegisteredDate")}</div>
              <div className={classes.data}>
                {vehicleQuery.data?.firstRegisteredDate
                  ? format(new Date(vehicleQuery.data?.firstRegisteredDate), "P", { locale: hu })
                  : "-"}
              </div>
              <div className={classes.title}>{t("vehicle.properties.registeredDateInHungary")}</div>
              <div className={classes.data}>
                {vehicleQuery.data?.firstRegisteredDate
                  ? format(new Date(vehicleQuery.data?.registeredDateInHungary), "P", {
                      locale: hu,
                    })
                  : "-"}
              </div>
              {vehicleQuery.data?.bodyType && (
                <>
                  <div className={classes.title}>{t("vehicle.properties.bodyType")}</div>
                  <div className={classes.data}>
                    {t(`vehicle.bodyType.${vehicleQuery.data?.bodyType}`)}
                  </div>
                </>
              )}
              <div className={classes.title}>{t("vehicle.properties.tailLift")}</div>
              <div className={classes.data}>{vehicleQuery.data?.tailLift ? "Van" : "Nincs"}</div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
              <div className={classes.title}>{t("vehicle.properties.comerenceDescription")}</div>
              <div className={classes.data}>{vehicleQuery.data?.comerenceDescription || "-"}</div>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default VehicleDetails;
