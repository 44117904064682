import { FormProvider, useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import ContactForm, { ContactFormValues } from "./ContactForm";
import { useMutation } from "react-query";
import { modifyContact } from "shared/network/contact.api";
import { Contact } from "shared/types";
import { useSnackbar } from "notistack";

type Props = {
  contact: Contact | null;
  onClose: () => void;
  onSubmitSuccess?: () => void;
};

const ContactModify = ({ contact, onClose, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<ContactFormValues>();

  const mutation = useMutation<any, any, ContactFormValues>(
    async values => {
      if (contact) {
        await modifyContact({ ...contact, ...values });
      }
    },
    {
      onSuccess: () => {
        onClose();
        onSubmitSuccess?.();
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: t("contact.subject"),
          }),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("contact.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  useEffect(() => {
    if (contact) {
      form.reset(contact);
    }
  }, [contact]); //eslint-disable-line

  return contact ? (
    <FormProvider {...form}>
      <Dialog
        open
        onClose={onClose}
        PaperProps={{
          component: "form",
          onSubmit: form.handleSubmit(values => mutation.mutate(values)),
        }}
      >
        <DialogTitle>{t("contact.modify")}</DialogTitle>
        <DialogContent>
          <ContactForm />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            {t("common:button.save")}
          </Button>
          <Button onClick={onClose}>{t("common:button.cancel")}</Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  ) : null;
};

export default ContactModify;
