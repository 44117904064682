import Layout from "components/layout/Layout";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getWarehouseById, modifyWarehouse } from "shared/network/warehouse.api";
import WarehouseForm, { WarehouseFormValues } from "views/warehouse/components/WarehouseForm";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Button from "components/form/Button";
import Loading from "components/Loading";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const WarehouseModify = ({ match }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;

  const form = useForm<WarehouseFormValues>({
    defaultValues: {
      companyId: "",
    },
  });

  const warehouseQuery = useQuery(["warehouseQueryModify", id], async () => {
    const { data } = await getWarehouseById(Number.parseInt(id));
    return data.item;
  });

  const mutation = useMutation<any, any, WarehouseFormValues>(
    async values => {
      if (warehouseQuery.data?.address?.id && id)
        await modifyWarehouse({
          address: {
            id: warehouseQuery.data?.address?.id,
            country: values?.country,
            zipCode: Number.parseInt(values.zipCode),
            city: values.city,
            areaName: values.areaName,
            areaType: values.areaType,
            houseNumber: values.houseNumber,
            floor: values.floor,
          },
          warehouse: {
            ...warehouseQuery.data,
            name: values.name,
            height: Number.parseFloat(values.height),
            space: Number.parseFloat(values.space),
            companyId: values.companyId,
          },
        });
    },
    {
      onSuccess: () => {
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: t("warehouse.subject"),
          }),
          { variant: "success" },
        );
        history.push("/warehouse");
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("warehouse.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  useEffect(() => {
    if (warehouseQuery.data) {
      const { companyId, height, name, space, address } = warehouseQuery.data;
      form.reset({
        companyId: companyId?.toString(),
        name: name,
        height: height?.toString(),
        space: space?.toString(),
        country: address?.country,
        zipCode: address?.zipCode?.toString(),
        city: address?.city,
        areaName: address?.areaName,
        areaType: address?.areaType,
        houseNumber: address?.houseNumber,
        floor: address?.floor,
      });
    }
  }, [warehouseQuery.data]); //eslint-disable-line

  return (
    <Layout title={t("warehouse.modify.title")} maxWidth="lg">
      {warehouseQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(values => mutation.mutate(values))}>
            <WarehouseForm />
            <Box marginTop={2}>
              <Button loading={mutation.isLoading} color="primary" type="submit">
                {t("common:button.save")}
              </Button>
            </Box>
          </form>
        </FormProvider>
      )}
    </Layout>
  );
};

export default WarehouseModify;
