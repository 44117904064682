import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "components/form/Button";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { modifyCompanyDeliveryExtraCharge } from "shared/network/deliveryextracharge.api";
import { Company } from "shared/types";
import DeliveryExtraChargeForm, { DeliveryExtraChargeFormValues } from "./DeliveryExtraChargeForm";

type Props = {
  company?: Company;
  onSubmitSuccess?: () => void;
};

const DeliveryExtraChargeModify = ({ company, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<DeliveryExtraChargeFormValues>();
  const [open, setOpen] = useState(false);

  const mutation = useMutation<any, any, DeliveryExtraChargeFormValues>(
    async values => {
      await modifyCompanyDeliveryExtraCharge(values);
    },
    {
      onSuccess: () => {
        setOpen(false);
        onSubmitSuccess?.();
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: t("company.deliveryExtraCharge.report"),
          }),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("company.deliveryExtraCharge.report"),
          }),
          { variant: "error" },
        );
      },
    },
  );
  useEffect(() => {
    if (company?.deliveryExtraChargeEntry) {
      form.reset(company?.deliveryExtraChargeEntry);
    }
  }, [company?.deliveryExtraChargeEntry]); //eslint-disable-line

  return (
    <>
      <Box marginTop="40px">
        <Button
          type="submit"
          color="primary"
          disabled={!company?.deliveryExtraChargeEntry}
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          {t("common:button.modify")}
        </Button>
      </Box>
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit: form.handleSubmit(values => mutation.mutate(values)),
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{t("company.deliveryExtraCharge.modify")}</DialogTitle>
          <DialogContent>
            <DeliveryExtraChargeForm />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              {t("common:button.save")}
            </Button>
            <Button onClick={() => setOpen(false)}>{t("common:button.cancel")}</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default DeliveryExtraChargeModify;
