const urlCreator = window.URL || window.webkitURL;

const fileOpen = (data: string) => {
  const blob = new Blob([data], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = urlCreator.createObjectURL(blob);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
};

export default fileOpen;
