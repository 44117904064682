import * as React from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import usePermissions from "shared/hooks/usePermissions";

type Props = RouteProps & {
  fallbackRoute?: string;
  permissions?: string[];
  customerService?: boolean;
  admin?: boolean;
};

const PrivateRoute = ({
  component,
  fallbackRoute = "/login",
  permissions,
  customerService,
  admin,
  ...props
}: Props) => {
  const { user, checkPermissions } = usePermissions();

  function renderRedirect(routeProps: RouteComponentProps<any>) {
    if (
      (!customerService && !permissions) ||
      checkPermissions({
        requestedPermissions: permissions,
        customerService,
        admin,
      })
    ) {
      if (!!user && component) {
        return React.createElement(component, routeProps);
      }
    } else {
      if (routeProps?.location?.key) {
        return (
          <Redirect
            to={{
              pathname: "/dashboard",
              search: routeProps.location.search,
              state: { from: routeProps.location },
            }}
          />
        );
      }
    }

    return (
      <Redirect
        to={{
          pathname: fallbackRoute,
          search: routeProps.location.search,
          state: { from: routeProps.location },
        }}
      />
    );
  }

  if (!component) {
    throw new Error(`A component needs to be specified for private route for path ${props.path}`);
  }

  return <Route {...props} render={renderRedirect} />;
};

export default PrivateRoute;
