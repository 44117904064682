import * as React from "react";
import Box from "@material-ui/core/Box";
import { useQuery } from "react-query";
import { Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getGdpr } from "shared/network/article.api";
import UnauthorizedLayout from "components/layout/UnauthorizedLayout";

const Gdpr = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data } = useQuery(["fetchArticleGdrpQuery"], async () => {
    const { data } = await getGdpr();
    return data.item;
  });

  if (!data) {
    return <div />;
  }

  return (
    <UnauthorizedLayout title="GDPR">
      <Box pt={5}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="column" overflow="auto" maxHeight="500px">
            <div dangerouslySetInnerHTML={{ __html: data?.content ?? "" }} />
          </Box>
        </Box>
        <Box style={{ padding: "24px 0" }}>
          <Link onClick={history.goBack} style={{ cursor: "pointer" }}>
            {t("common:button.back")}
          </Link>
        </Box>
      </Box>
    </UnauthorizedLayout>
  );
};

export default Gdpr;
