import { useQuery } from "react-query";
import { getAllProperties } from "shared/network/property.api";
import React, { useMemo } from "react";
import { Property } from "shared/types";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import Layout from "components/layout/Layout";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import PropertySettingsRow from "views/property/PropertySettingsRow";

const PropertySettings = () => {
  const { t } = useTranslation();

  const query = useQuery("properties", async () => {
    const { data } = await getAllProperties();
    return data.items;
  });

  const categorized = useMemo(() => {
    return query.data?.reduce((acc: { [key: string]: Property[] }, property) => {
      if (!acc[property.moduleName]) {
        acc[property.moduleName] = [];
      }
      acc[property.moduleName].push(property);
      return acc;
    }, {});
  }, [query.data]);

  return (
    <Layout title={t("property.title")}>
      {categorized &&
        Object.entries(categorized).map(([module, properties]) => (
          <Accordion elevation={0} style={{ background: "transparent" }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{t(`property.modules.${module}`)}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column", gap: 16 }}>
              {properties.map(property => (
                <PropertySettingsRow property={property} onSubmitSuccess={query.refetch} />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
    </Layout>
  );
};

export default PropertySettings;
