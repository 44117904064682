import * as React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { enqueueSnackbar } from "shared/reducers/application";
import ArticleForm, { ArticleFormValues } from "../components/ArticleForm/ArticleForm";
import Layout from "components/layout/Layout";
import { saveArticle } from "shared/network/article.api";
import { Box } from "@material-ui/core";

type Props = RouteComponentProps;

const CreateArticle = ({ history }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  async function onSubmit(values: ArticleFormValues) {
    setLoading(true);
    try {
      await saveArticle({ ...values });
      setLoading(false);
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.create.success", {
            subject: t("article.subject"),
          }),
          options: { variant: "success" },
        }),
      );
      history.replace("/article");
    } catch (e) {
      setLoading(false);
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.create.failure", {
            subject: t("article.subject"),
          }),
          options: { variant: "error" },
        }),
      );
    }
  }

  return (
    <Layout style={{ textTransform: "uppercase" }} title={t("article.create")} maxWidth="lg">
      <Box display="flex" flexDirection="column" width="100%">
        <Box p={3}>
          <ArticleForm onSubmit={onSubmit} isLoading={loading} />
        </Box>
      </Box>
    </Layout>
  );
};

export default CreateArticle;
