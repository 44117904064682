import * as L from "leaflet";
import MarkerIcon from "./marker.png";
import Typography from "@material-ui/core/Typography";
import displayAddress from "shared/util/displayAddress";
import { Tour } from "./TourSortingModal";
import { Marker, Popup } from "react-leaflet";
import { makeStyles } from "@material-ui/core";

export type CategorizedTours = {
  coords: { longitude: number | null; latitude: number | null };
  tours: Tour[];
};

type Props = {
  categorizedTours: CategorizedTours;
};

const useStyles = makeStyles(
  () => ({
    divIcon: {
      background: "transparent",
      border: "none",
    },
    markerContainer: {
      position: "absolute",
      top: "4px",
      fontSize: "12px",
      width: "25px",
      textAlign: "center",
      fontWeight: "bold",
    },
    marker: {
      position: "relative",
      fontSize: "12px",
      width: "25px",
      textAlign: "center",
    },
  }),
  { name: "TourDetails" },
);

const MapMarker = ({ categorizedTours }: Props) => {
  const classes = useStyles();
  // @ts-ignore
  L.NumberedDivIcon = L.Icon.extend({
    options: {
      iconUrl: MarkerIcon,
      number: "",
      shadowUrl: null,
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      popupAnchor: [0, -33],
      className: classes.divIcon,
    },
    createIcon: function () {
      var div = document.createElement("div");
      var img = this._createImg(this.options["iconUrl"]);
      var numdiv = document.createElement("div");
      numdiv.setAttribute("class", classes.markerContainer);
      img.setAttribute("class", classes.marker);
      numdiv.innerHTML = this.options["number"] || "";
      div.appendChild(img);
      div.appendChild(numdiv);
      this._setIconStyles(div, "icon");
      return div;
    },
  });

  return (
    <Marker
      position={[categorizedTours.coords.latitude || 0, categorizedTours.coords.longitude || 0]}
      icon={
        // @ts-ignore
        new L.NumberedDivIcon({
          number: categorizedTours.tours.length === 1 ? categorizedTours.tours[0].tourOrder : "*",
        })
      }
    >
      <Popup>
        {categorizedTours.tours.map(tour => (
          <Typography key={tour.id}>
            <b>{tour.tourOrder}.</b> {displayAddress(tour.address)}
          </Typography>
        ))}
      </Popup>
    </Marker>
  );
};

export default MapMarker;
