import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Property } from "shared/types";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { modifyProperty } from "shared/network/property.api";
import { Tooltip } from "@material-ui/core";

type Props = {
  property: Property;
  onSubmitSuccess?: () => void;
};

const PropertySettingsRow = ({ property, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm();

  const mutation = useMutation<any, any, { [p: string]: any }>(
    async values => {
      await modifyProperty({ ...property, ...values });
    },
    {
      onSuccess: () => {
        onSubmitSuccess?.();
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: "beállítás",
          }),
          { variant: "success" },
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: "beállítás",
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <form
      onSubmit={form.handleSubmit(values => mutation.mutate(values))}
      style={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
    >
      <TextField
        label={t(`property.properties.${property.name}`)}
        name="value"
        defaultValue={property.value}
        inputRef={form.register({
          required: {
            value: true,
            message: t("validation:required"),
          },
        })}
        error={!!form.errors?.value}
        helperText={form.errors?.value?.message}
        margin="none"
        size="small"
        InputProps={{
          style: { paddingRight: 6 },
          endAdornment: (
            <InputAdornment position="end" style={{ marginLeft: 6 }}>
              <Tooltip title={t("common:button.save").toString()}>
                <IconButton type="submit" color="primary">
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default PropertySettingsRow;
