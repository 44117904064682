import {
  faBath,
  faCouch,
  faFireBurner,
  faKitchenSet,
  faMattressPillow,
  faPlugCircleBolt,
  faShapes,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export default function getReturnGoodsIcon(type: string, style?: CSSProperties): React.ReactNode {
  switch (type) {
    case "WHITE_GOODS":
      return <FontAwesomeIcon icon={faFireBurner} style={style} size={"lg"} />;
    case "REMOVAL_BATHROOM":
      return <FontAwesomeIcon icon={faBath} style={style} size={"lg"} />;
    case "REMOVAL_MATTRESS":
      return <FontAwesomeIcon icon={faMattressPillow} style={style} size={"lg"} />;
    case "REMOVAL_SOFAS":
      return <FontAwesomeIcon icon={faCouch} style={style} size={"lg"} />;
    case "REMOVAL_KITCHEN":
      return <FontAwesomeIcon icon={faKitchenSet} style={style} size={"lg"} />;
    case "REMOVAL_ELECTRONICS":
      return <FontAwesomeIcon icon={faPlugCircleBolt} style={style} size={"lg"} />;
    case "REMOVAL_ADDITIONAL":
      return <FontAwesomeIcon icon={faShapes} style={style} size={"lg"} />;
    default:
      return null;
  }
}

export function getReturnGoodsBaseIcon(type: string): IconDefinition {
  switch (type) {
    case "WHITE_GOODS":
      return faFireBurner;
    case "REMOVAL_BATHROOM":
      return faBath;
    case "REMOVAL_MATTRESS":
      return faMattressPillow;
    case "REMOVAL_SOFAS":
      return faCouch;
    case "REMOVAL_KITCHEN":
      return faKitchenSet;
    case "REMOVAL_ELECTRONICS":
      return faPlugCircleBolt;
    case "REMOVAL_ADDITIONAL":
      return faShapes;
    default:
      return faFireBurner;
  }
}
