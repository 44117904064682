import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import displayAddress from "shared/util/displayAddress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useState } from "react";
import { useQuery } from "react-query";
import { WarningDataType } from "../Tour";
import { useTranslation } from "react-i18next";
import { createMultiSelectTour, createTour } from "shared/network/tour.api";
import { getAllWarehouse } from "shared/network/warehouse.api";
import { useSnackbar } from "notistack";

type Props = {
  addressData: any;
  setAddressData: any;
  setWarningData: (warningData: WarningDataType) => void;
  vehicleRefetch: () => void;
  packageRefetch: () => void;
  toWarehouseChecked: boolean;
  setToWarehouseChecked: (toWarehouseChecked: boolean) => void;
  enableMultiSelect?: boolean;
  resetMultiSelection?: () => void;
};

const AddressModal = ({
  addressData,
  setAddressData,
  setWarningData,
  vehicleRefetch,
  packageRefetch,
  toWarehouseChecked,
  setToWarehouseChecked,
  enableMultiSelect,
  resetMultiSelection,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rememberChecked, setRememberChecked] = useState(false);
  const [targetWarehouse, setTargetWarehouse] = useState(0);

  const warehouseQuery = useQuery(["warehouseQueryAddressModal"], async () => {
    const { data } = await getAllWarehouse(0, 10000000);
    return data.page.content;
  });

  return (
    <Dialog open={!!addressData} onClose={() => setAddressData(null)}>
      <DialogTitle>
        {enableMultiSelect ? t("tour.multiSelectTitle") : t("tour.address")}
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Typography
          style={{
            fontSize: 20,
            marginBottom: 12,
            color: toWarehouseChecked ? "lightGrey" : "black",
          }}
        >
          {displayAddress(addressData?.param?.targetAddress)}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={toWarehouseChecked}
              //@ts-ignore
              onClick={() => setToWarehouseChecked(prevState => !prevState)}
            />
          }
          label={t("tour.toWarehouse")}
          labelPlacement="end"
        />
        {toWarehouseChecked && (
          <>
            <TextField
              label={t("warehouse.list.title")}
              defaultValue={localStorage.getItem("target-warehouse") || 0}
              onChange={event => setTargetWarehouse(Number.parseInt(event.target.value))}
              select
            >
              <MenuItem value={0} disabled>
                <em>{t("common:choose")}</em>
              </MenuItem>
              {warehouseQuery.data?.map((warehouse, index) => {
                return (
                  <MenuItem key={index} value={warehouse.address.id}>
                    {warehouse && ` ${warehouse.name} (${displayAddress(warehouse.address)})`}
                  </MenuItem>
                );
              })}
            </TextField>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={rememberChecked}
                  //@ts-ignore
                  onClick={() => setRememberChecked(prevState => !prevState)}
                />
              }
              label={t("tour.remember")}
              labelPlacement="end"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddressData(null)}>{t("common:button.cancel")}</Button>
        <Button
          variant="contained"
          disabled={toWarehouseChecked && targetWarehouse === 0}
          color="primary"
          onClick={async () => {
            try {
              if (enableMultiSelect) {
                if (toWarehouseChecked && addressData) {
                  await createMultiSelectTour({
                    param: {
                      ...addressData.param,
                      targetAddress: warehouseQuery.data?.find(
                        warehouse => warehouse.address.id === targetWarehouse,
                      )?.address,
                    },
                  });
                } else {
                  await createMultiSelectTour(addressData);
                }
                if (toWarehouseChecked && rememberChecked) {
                  setToWarehouseChecked(true);
                  localStorage.setItem("target-warehouse", targetWarehouse.toString());
                  setRememberChecked(true);
                }
                enqueueSnackbar(t("tour.saveSuccess"), { variant: "success" });
              } else {
                if (toWarehouseChecked && addressData) {
                  await createTour({
                    param: {
                      ...addressData.param,
                      targetAddress: warehouseQuery.data?.find(
                        warehouse => warehouse.address.id === targetWarehouse,
                      )?.address,
                    },
                  });
                } else {
                  await createTour(addressData);
                }
                if (toWarehouseChecked && rememberChecked) {
                  setToWarehouseChecked(true);
                  localStorage.setItem("target-warehouse", targetWarehouse.toString());
                  setRememberChecked(true);
                }
                enqueueSnackbar(t("tour.saveSuccess"), { variant: "success" });
              }
              vehicleRefetch();
              packageRefetch();
              setWarningData(null);
              setAddressData(null);
              resetMultiSelection && resetMultiSelection();
              if (rememberChecked) {
                localStorage.setItem("target-warehouse", targetWarehouse.toString());
              } else {
                localStorage.removeItem("target-warehouse");
                setTargetWarehouse(0);
                setToWarehouseChecked(false);
              }
            } catch (e) {
              enqueueSnackbar(t("tour.saveError"), { variant: "error" });
            }
          }}
        >
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressModal;
