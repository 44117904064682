import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAuthenticationToken, removeAuthenticationToken } from "shared/util/user";
import { BACKEND_URL } from "./constants";

const instance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 30000,
});

function onRequestSuccess(config: AxiosRequestConfig) {
  const bearerToken = getAuthenticationToken();

  if (bearerToken) {
    config.headers.Authorization = `Bearer ${bearerToken}`;
  }

  return config;
}

function onResponseSuccess(response: AxiosResponse) {
  return response;
}

function onResponseError(error: any) {
  const status = error.status || (error.response ? error.response.status : 0);
  if (status === 401) {
    removeAuthenticationToken();
    window.location.pathname = "/login";
  }
  return Promise.reject(error.response);
}

instance.interceptors.request.use(onRequestSuccess);
instance.interceptors.response.use(onResponseSuccess, onResponseError);

export default instance;
