import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@material-ui/core";
import { ShippingPrice } from "shared/types";
import ShippingPriceListItem from "./ShippingPriceListItem";

type Props = {
  shippingPrices: ShippingPrice[];
  modifySubmitSuccess?: () => void;
  setPage?: (page: number) => void;
  page?: number;
  totalPages?: number;
};

const ShippingPriceList = ({
  shippingPrices,
  modifySubmitSuccess,
  setPage,
  page,
  totalPages,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box style={{ overflow: "auto hidden" }}>
        <Grid
          container
          alignItems="center"
          style={{
            fontWeight: "bold",
            padding: 15,
            minWidth: 1000,
          }}
        >
          <Grid item xs={3}>
            {t("shippingPrice.packageName")}
          </Grid>
          <Grid item xs={3}>
            {t("shippingPrice.packageTrackingNumber")}
          </Grid>
          <Grid item xs={2}>
            {t("shippingPrice.vehiclelicensePlateNumber")}
          </Grid>
          <Grid item xs={3}>
            {t("shippingPrice.price")}
          </Grid>
          <Grid item xs={1} />
        </Grid>
        {shippingPrices.map((shippingPrice, index) => (
          <ShippingPriceListItem
            shippingPrice={shippingPrice}
            modifySubmitSuccess={modifySubmitSuccess}
          />
        ))}
      </Box>
      {page && setPage && totalPages && totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "24px 0",
          }}
          count={totalPages}
          color="primary"
          page={page}
          onChange={(e, page) => {
            sessionStorage.setItem("ShippingPricePageNumber", JSON.stringify(page - 1));
            setPage(page - 1);
          }}
        />
      )}
    </>
  );
};

export default ShippingPriceList;
