import * as React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { logout } from "shared/reducers/authentication";
import { selectCompany } from "shared/reducers/application";

const Logout = ({ history }: RouteComponentProps) => {
  const dispatch = useDispatch();

  const deleteSessionStorage = () => {
    sessionStorage.removeItem("PackagePageNumber");
    sessionStorage.removeItem("ShippingPricePageNumber");
    sessionStorage.removeItem("CompanyPageNumber");
    sessionStorage.removeItem("UserPageNumber");
    sessionStorage.removeItem("VehiclePageNumber");
    sessionStorage.removeItem("WarehousePageNumber");
    sessionStorage.removeItem("DeliveryExtraChargePageNumber");
  };

  React.useEffect(() => {
    try {
      dispatch(logout());
      deleteSessionStorage();
      dispatch(selectCompany(null));
    } catch (e) {}
    history.replace("/login");
  }, [dispatch, history]);

  return <div />;
};

export default Logout;
