import { Box, IconButton, TextField } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import PageLoading from "components/PageLoading";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MatrixType, saveDeliveryPrice } from "shared/network/deliveryprice.api";
import { DeliveryPrice, Zone } from "views/company/CompanyZone";
import { PriceRow } from "./DeliveryPriceTable";

type Props = {
  zoneList?: Zone[];
  type: "WEIGHT" | "VOLUME";
  companyId: string;
  setOpenCreateRow: Dispatch<SetStateAction<boolean>>;
  rowData: PriceRow[];
  matrixType: MatrixType;
};

type FormValues = {
  weightFrom: string;
  weightTo: string;
  volumeFrom: string;
  volumeTo: string;
  deliveryPrice: DeliveryPrice[];
};

const CreateDeliveryPriceRange = ({
  zoneList,
  type,
  companyId,
  setOpenCreateRow,
  rowData,
  matrixType,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>();

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (type === "WEIGHT") {
        let data = rowData.filter(entry => true);
        data.push({
          id: 999999,
          weightFrom: values.weightFrom,
          weightTo: values.weightTo,
          priceEntries: values.deliveryPrice,
        });
        data = data.sort((a, b) => {
          if (!!a.weightFrom && !!b.weightFrom) {
            let startA = Number.parseFloat(a?.weightFrom);
            let startB = Number.parseFloat(b?.weightFrom);
            return startA < startB ? -1 : startB < startA ? 1 : 0;
          }
          return 0;
        });
        for (let i = 1; i < data.length; i++) {
          if (
            Number.parseFloat(data[i - 1].weightTo || "0") >
            Number.parseFloat(data[i].weightFrom || "0")
          ) {
            throw new Error("INTERVAL_ERROR");
          }
        }
        await saveDeliveryPrice({
          companyId,
          deliveryPriceListWeight: values.deliveryPrice.map((item, index) => {
            return {
              ...item,
              fromZone: zoneList?.[index]?.id,
              weightFrom: values.weightFrom,
              weightTo: values.weightTo,
              type: matrixType,
            };
          }),
          deliveryPriceListVolume: undefined,
        });
      }

      if (type === "VOLUME") {
        let data = rowData.filter(entry => true);
        data.push({
          id: 999999,
          volumeFrom: values.volumeFrom,
          volumeTo: values.volumeTo,
          priceEntries: values.deliveryPrice,
        });
        data = data.sort((a, b) => {
          if (!!a.volumeFrom && !!b.volumeFrom) {
            let startA = Number.parseFloat(a?.volumeFrom);
            let startB = Number.parseFloat(b?.volumeFrom);
            return startA < startB ? -1 : startB < startA ? 1 : 0;
          }
          return 0;
        });
        for (let i = 1; i < data.length; i++) {
          if (
            Number.parseFloat(data[i - 1].volumeTo || "0") >
            Number.parseFloat(data[i].volumeFrom || "0")
          ) {
            throw new Error("INTERVAL_ERROR");
          }
        }
        await saveDeliveryPrice({
          companyId,
          deliveryPriceListWeight: undefined,
          deliveryPriceListVolume: values.deliveryPrice.map((item, index) => {
            return {
              ...item,
              fromZone: zoneList?.[index]?.id,
              volumeFrom: values.volumeFrom,
              volumeTo: values.volumeTo,
              type: matrixType,
            };
          }),
        });
      }
      queryClient.invalidateQueries("deliveryPriceListQuery");
      setOpenCreateRow(false);
      enqueueSnackbar(
        t("common:notification.save.success", {
          subject: "ár",
        }),
        { variant: "success" },
      );
    } catch (e: any) {
      if (e.message === "INTERVAL_ERROR") {
        enqueueSnackbar(t("common:notification.zoneRangeConflict"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.save.failure", {
            subject: "ár",
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box width={((zoneList?.length || 0) + 1) * 180} marginLeft="auto" marginRight="auto">
          <Box display="flex">
            <PageLoading open={loading} />
            <Box
              display="flex"
              alignItems="center"
              height={48}
              width={180}
              paddingX={1}
              borderBottom="1px solid grey"
            >
              {type === "WEIGHT" ? (
                <>
                  <TextField
                    name="weightFrom"
                    margin="none"
                    defaultValue=""
                    InputProps={{ style: { height: 30 } }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={!!form.errors?.weightFrom}
                  />
                  <Box p={1}>-</Box>
                  <TextField
                    name="weightTo"
                    margin="none"
                    defaultValue=""
                    InputProps={{ style: { height: 30 } }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={!!form.errors?.weightTo}
                  />
                </>
              ) : (
                <>
                  <TextField
                    name="volumeFrom"
                    margin="none"
                    defaultValue=""
                    InputProps={{ style: { height: 30 } }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={!!form.errors?.volumeFrom}
                  />
                  <Box p={1}>-</Box>
                  <TextField
                    name="volumeTo"
                    margin="none"
                    defaultValue=""
                    InputProps={{ style: { height: 30 } }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={!!form.errors?.volumeTo}
                  />
                </>
              )}
            </Box>
            {zoneList?.map((zone, index) => {
              return (
                <Box
                  key={zone.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gridGap={8}
                  height={48}
                  width={180}
                  paddingX={1}
                  borderBottom="1px solid grey"
                >
                  <TextField
                    name={`deliveryPrice.${index}.price`}
                    margin="none"
                    defaultValue=""
                    InputProps={{
                      style: { height: 30 },
                    }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={!!form.errors?.deliveryPrice?.[index]?.price}
                  />
                </Box>
              );
            })}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gridGap={8}
              height={48}
              width={80}
              paddingX={1}
              borderBottom="1px solid grey"
            >
              <IconButton type="submit" size="small">
                <Check />
              </IconButton>
              <IconButton size="small" onClick={() => setOpenCreateRow(false)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default CreateDeliveryPriceRange;
