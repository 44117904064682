import parse from "html-react-parser";
import Box from "@material-ui/core/Box";
import GetApp from "@material-ui/icons/GetApp";
import Divider from "@material-ui/core/Divider";
import fileDownload from "shared/util/fileDownload";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PackageNoteShareModal from "./PackageNoteShareModal";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { Note, Package } from "shared/types";
import { getFileByPath } from "shared/network/download.api";

type Props = {
  pack: Package;
  note: Note;
};

const PackageNote = ({ note, pack }: Props) => {
  const temp = note.filePath?.split("/");
  const filename = temp?.[temp.length - 1];

  const download = async () => {
    try {
      if (note.filePath) {
        const { data } = await getFileByPath(note.filePath);
        fileDownload(data, filename);
      }
    } catch {}
  };

  return (
    <Box marginY={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          {note.note && parse(note.note)}
          {note.filePath && (
            <Box display="flex" alignItems="center" gridGap={8}>
              <IconButton onClick={download}>
                <GetApp />
              </IconButton>
              {filename}
            </Box>
          )}
        </div>
        <Box display="flex" alignItems="center" gridGap={8}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end">
            <Typography style={{ fontSize: 14 }}>
              {note.user?.lastName} {note.user?.firstName}
            </Typography>
            <Typography style={{ fontSize: 12, color: "#555" }}>
              {format(new Date(note.createdOn), "Pp", { locale: hu })}
            </Typography>
          </Box>
          <PackageNoteShareModal pack={pack} note={note} />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default PackageNote;
