import Layout from "components/layout/Layout";
import TourDetailsContent from "./components/TourDetailsContent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RouteComponentProps } from "react-router";
import PackageModal from "./components/PackageModal";
import PackageStatusModal from "components/package/PackageStatusModal";
import { Package } from "shared/types";
import { Edit, GetApp } from "@material-ui/icons";
import { exportTour } from "shared/network/tour.api";
import fileDownload from "shared/util/fileDownload";
import { format } from "date-fns";
import { hu } from "date-fns/locale";

const TourDetails = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const id = match.params.id;
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [licensePlateNumber, setlicensePlateNumber] = useState("");
  const [canExport, setCanExport] = useState(false);

  const today = new Date();
  const [isToday, setIsToday] = useState(true);

  const download = async () => {
    const { data } = await exportTour({
      date: date.toISOString(),
      vehicleId: Number(id),
    });
    fileDownload(data, `${format(date, "P", { locale: hu })} ${licensePlateNumber}.pdf`);
  };

  return (
    <Layout
      title={`${t("tour.details")}${licensePlateNumber ? ` (${licensePlateNumber})` : ""}`}
      maxWidth="xl"
      actionButton={
        <Box display="flex" gridGap={8}>
          {canExport && (
            <Button onClick={download} variant="outlined" color="primary" startIcon={<GetApp />}>
              {t("common:button.export")}
            </Button>
          )}
          {isToday ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setStatusOpen(true)}
              startIcon={<Edit />}
            >
              {t("common:button.packageStatus")}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              disabled
              onClick={() => setStatusOpen(true)}
              startIcon={<Edit />}
            >
              {t("common:button.packageStatus")}
            </Button>
          )}

          <PackageModal
            open={statusOpen}
            setOpen={setStatusOpen}
            setSelectedPackage={setSelectedPackage}
          />
          <PackageStatusModal pack={selectedPackage} setSelected={setSelectedPackage} />
          <div style={{ maxWidth: 200, marginRight: 8 }}>
            <KeyboardDatePicker
              format="yyyy. MM. dd."
              label={t("tour.date")}
              value={date ?? new Date()}
              onChange={e => {
                if (
                  e?.getFullYear() === today.getFullYear() &&
                  e.getMonth() === today.getMonth() &&
                  e.getDay() === today.getDay()
                ) {
                  setIsToday(true);
                } else {
                  setIsToday(false);
                }
                setDate(e ?? new Date());
              }}
              autoOk
              size="small"
              fullWidth
              margin="none"
              cancelLabel={t("common:button.cancel")}
            />
          </div>
        </Box>
      }
    >
      <TourDetailsContent
        id={id}
        date={date}
        setlicensePlateNumber={setlicensePlateNumber}
        setCanExport={setCanExport}
      />
    </Layout>
  );
};

export default TourDetails;
