import { SnackbarKey } from "notistack";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, Optional } from "shared/types";
import { resetState } from "shared/reducers/authentication";

interface Notification {
  key: SnackbarKey;
  options: any;
  message: React.ReactNode;
  dismissed?: boolean;
}

interface ApplicationState {
  notifications: Notification[];
  currentCompany: null | Company;
}

const initialState: ApplicationState = {
  notifications: [],
  currentCompany: null,
};

const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    selectCompany(state, { payload }: PayloadAction<Company | null>) {
      state.currentCompany = payload;
    },
    enqueueSnackbar(state, { payload }: PayloadAction<Optional<Notification, "key">>) {
      const key = payload.key;

      state.notifications.push({
        ...payload,
        key: key ?? Date.now() + Math.random(),
      });
    },
    closeSnackbar(state, { payload }: PayloadAction<{ dismissAll: boolean; key: SnackbarKey }>) {
      state.notifications = state.notifications.map(notification =>
        payload.dismissAll || notification.key === payload.key
          ? { ...notification, dismissed: true }
          : notification,
      );
    },
    removeSnackbar(state, { payload }: PayloadAction<SnackbarKey>) {
      state.notifications = state.notifications.filter(
        notification => notification.key !== payload,
      );
    },
  },
  extraReducers: {
    [resetState.name]: state => {
      state = initialState;
    },
  },
});

export default application.reducer;

export const {
  selectCompany,
  closeSnackbar,
  enqueueSnackbar,
  removeSnackbar,
} = application.actions;
