import axios from "config/axios";
import { GenericListResponse, Property } from "shared/types";

const ENDPOINT = "/properties";

export const getSystemProperties = () =>
  axios.get<GenericListResponse<Property>>(`${ENDPOINT}/getSystemProperties`);

export const getAllProperties = () => axios.post<GenericListResponse<Property>>(ENDPOINT);

export const getPropertyByName = (name: string) => axios.get(`${ENDPOINT}/byName?name=${name}`);

export const modifyProperty = (data: Property) => axios.put(ENDPOINT, { param: data });
