import { huHU } from "@material-ui/core/locale";
import { createMuiTheme } from "@material-ui/core/styles";

export const palette = {
  milk: "#F6F7F8",
  brickRed: "#DE3500",
  main: "#00224B",
  lightMain: "#00224B",
  orange: "#F26422",
  lightGray: "#fafafa",
  light: "#b3e1f5",
  sky: "#92d4f0",
  green: "#008040",
};

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: palette.main,
      },
      secondary: {
        main: palette.orange,
      },
    },
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            WebkitFontSmoothing: "auto",
          },
          body: {
            backgroundColor: palette.lightGray,
          },
          input: {
            "&[type=number]": {
              "-moz-appearance": "textfield",
            },
            "&::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
          "&$focused $notchedOutline": {
            borderColor: palette.main,
            borderWidth: 1,
          },
          "&:hover $notchedOutline": {
            borderColor: palette.main,
            borderWidth: 1,
          },
        },
        notchedOutline: {
          borderWidth: 1,
          borderColor: palette.lightMain,
        },
      },
    },
    props: {
      MuiTextField: {
        variant: "outlined",
        fullWidth: true,
        margin: "normal",
        InputLabelProps: { shrink: true },
        size: "small",
        onWheel: event => event.target instanceof HTMLElement && event.target.blur(),
      },
      MuiCheckbox: {
        color: "primary",
      },
      MuiSwitch: {
        color: "primary",
      },
      MuiCircularProgress: {
        color: "primary",
      },
      MuiRadio: {
        color: "primary",
      },
      MuiIconButton: {
        color: "primary",
      },
    },
  },
  huHU,
);

export default theme;
