import useStyles from "./styles";
import Loading from "components/Loading";
import Button from "components/form/Button";
import useLocale from "shared/hooks/useLocale";
import TextField from "@material-ui/core/TextField";
import { Article } from "shared/types";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import { useForm, Controller } from "react-hook-form";
import { Box, Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import { ARTICLE_TYPES, EDITOR_MENUBAR, EDITOR_PLUGINS, EDITOR_TOOLBAR } from "config/constants";

type FormValues = {
  title: string;
  content: string;
  isActive: boolean;
  subjectType: string;
};

export type ArticleFormValues = FormValues;

type Props = {
  defaultValues?: Article;
  onSubmit: (values: FormValues) => void;
  isLoading?: boolean;
};

const ArticleForm = ({ onSubmit, defaultValues, isLoading = false }: Props) => {
  const locale = useLocale();
  const classes = useStyles();
  const { t } = useTranslation();

  const { handleSubmit, register, errors, control } = useForm<FormValues>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="none"
            name="title"
            label={t("article.properties.title")}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
              maxLength: {
                value: 128,
                message: t("validation:maxLength", { length: 128 }),
              },
            })}
            error={errors.title && true}
            helperText={errors.title?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="subjectType"
            defaultValue=""
            render={props => (
              <TextField
                {...props}
                margin="none"
                name="subjectType"
                label={t("article.properties.subjectType")}
                style={{ marginBottom: 24 }}
                error={errors.subjectType && true}
                helperText={errors.subjectType?.message}
                select
              >
                {ARTICLE_TYPES.map((type, key) => (
                  <MenuItem key={key} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: { value: true, message: t("validation:required") },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Loading />
          ) : (
            <div className={classes.editor}>
              <Controller
                control={control}
                name="content"
                defaultValue={defaultValues?.content ?? t("common:field.editorInitialText")}
                render={props => (
                  <Editor
                    initialValue={props.value}
                    init={{
                      min_height: 300,
                      max_height: 400,
                      plugins: EDITOR_PLUGINS,
                      imagetools_cors_hosts: ["picsum.photos"],
                      entity_encoding: "raw",
                      menubar: EDITOR_MENUBAR,
                      toolbar: EDITOR_TOOLBAR,
                      toolbar_sticky: true,
                      autosave_ask_before_unload: true,
                      autosave_interval: "30s",
                      autosave_prefix: "{path}{query}-{id}-",
                      autosave_restore_when_empty: false,
                      autosave_retention: "2m",
                      language: locale.options.tinyMCELang,
                      insertdatetime_formats: locale.options.tinyMCEDates,
                    }}
                    value={props.value}
                    onEditorChange={(content, editor) => {
                      props.onChange(content);
                    }}
                  />
                )}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Controller
            name="isActive"
            id="isActive"
            control={control}
            defaultValue={false}
            render={props => (
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(_, checked) => {
                      props.onChange(checked);
                    }}
                  />
                }
                label={t("article.isActive")}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box justifyContent="center" display="flex" p={2} alignItems="center">
        <Button loading={isLoading} type="submit">
          {t("common:button.save")}
        </Button>
      </Box>
    </form>
  );
};

export default ArticleForm;
