import AddIcon from "@material-ui/icons/Add";
import Layout from "components/layout/Layout";
import Pagination from "@material-ui/lab/Pagination";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "components/router";
import { useTranslation } from "react-i18next";
import { getAllUser } from "shared/network/user.api";
import { Controller, useForm } from "react-hook-form";
import { Close, FilterList } from "@material-ui/icons";
import { useEffect } from "react";
import UserListItem from "./UserListItem";

type UserFilter = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userType: "UNDEFINED" | "ADMIN" | "CUSTOMER_SERVICE" | "NORMAL";
  status: "UNDEFINED" | "ACTIVE" | "NEEDS_ACTIVATION" | "BANNED" | "INACTIVE";
};

const UserList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(
    parseInt(window.sessionStorage.getItem("UserPageNumber") || JSON.stringify(0)),
  );
  const [filter, setFilter] = useState("");

  const usersQuery = useQuery(["userListQuery", page, filter], async () => {
    const { data } = await getAllUser(page, 10, filter);
    return data;
  });

  const { register, handleSubmit, control, setValue } = useForm<UserFilter>({
    shouldUnregister: false,
  });

  const onSubmit = (values: UserFilter) => {
    setFilter("");
    setPage(0);
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        setFilter(state => `${state}${state ? ";" : ""}${key}:${value}`);
      }
    });
    usersQuery.refetch();
  };

  useEffect(() => {
    window.sessionStorage.setItem("UserPageNumber", JSON.stringify(page));
  }, [page]);

  return (
    <Layout
      title={t("userList.title")}
      actionButton={
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          component={Link}
          to="/user-create"
          startIcon={<AddIcon />}
        >
          {t("userCreate.title")}
        </Button>
      }
    >
      {usersQuery.isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ overflow: "auto hidden" }}>
          <Box style={{ minWidth: 1000 }}>
            <Grid container alignItems="center">
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>{t("userList.name")}</Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>{t("userList.email")}</Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>{t("userList.phone")}</Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>{t("userList.userType")}</Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>{t("userList.status.title")}</Typography>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 12 }}>
              <Grid container spacing={2}>
                <Grid container spacing={2} item xs={2} style={{ paddingTop: 0, padding: 8 }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      margin="none"
                      name="lastName"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      label={t("user.properties.lastName")}
                      inputRef={register}
                      InputProps={{
                        style: { paddingRight: 6 },
                        endAdornment: (
                          <InputAdornment position="end" style={{ marginLeft: 6 }}>
                            <IconButton onClick={() => setValue("lastName", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      margin="none"
                      name="firstName"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      label={t("user.properties.firstName")}
                      inputRef={register}
                      InputProps={{
                        style: { paddingRight: 6 },
                        endAdornment: (
                          <InputAdornment position="end" style={{ marginLeft: 6 }}>
                            <IconButton onClick={() => setValue("firstName", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <TextField
                    margin="none"
                    name="email"
                    placeholder="Szűrés"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    InputProps={{
                      style: { paddingRight: 6 },
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: 6 }}>
                          <IconButton onClick={() => setValue("email", "")} size="small">
                            <Close style={{ color: "darkgray" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <TextField
                    margin="none"
                    name="phone"
                    placeholder="Szűrés"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    InputProps={{
                      style: { paddingRight: 6 },
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: 6 }}>
                          <IconButton onClick={() => setValue("phone", "")} size="small">
                            <Close style={{ color: "darkgray" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <Controller
                    control={control}
                    name="userType"
                    defaultValue={""}
                    render={props => (
                      <TextField {...props} margin="none" select InputLabelProps={{ shrink: true }}>
                        <MenuItem value={""}>{t("auditLog.noFilter")}</MenuItem>
                        <MenuItem value={"ADMIN"}>{t("common:userType.ADMIN")}</MenuItem>
                        <MenuItem value={"CUSTOMER_SERVICE"}>
                          {t("common:userType.CUSTOMER_SERVICE")}
                        </MenuItem>
                        <MenuItem value={"NORMAL"}>{t("common:userType.NORMAL")}</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={1} style={{ padding: 8 }}>
                  <Controller
                    control={control}
                    name="status"
                    defaultValue={""}
                    render={props => (
                      <TextField {...props} margin="none" select InputLabelProps={{ shrink: true }}>
                        <MenuItem value="">{t("auditLog.noFilter")}</MenuItem>
                        <MenuItem value="ACTIVE">{t("userList.status.ACTIVE")}</MenuItem>
                        <MenuItem value="NEEDS_ACTIVATION">
                          {t("userList.status.NEEDS_ACTIVATION")}
                        </MenuItem>
                        <MenuItem value="BANNED">{t("userList.status.BANNED")}</MenuItem>
                        <MenuItem value="INACTIVE">{t("userList.status.INACTIVE")}</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<FilterList />}
                    style={{ height: 40 }}
                  >
                    {t("package.filter")}
                  </Button>
                </Grid>
              </Grid>
            </form>
            {!!usersQuery.data?.page.content.length &&
              usersQuery.data?.page.content?.map((userData, index, array) => (
                <UserListItem
                  key={userData.id}
                  userData={userData}
                  index={index}
                  usersQuery={usersQuery}
                  array={array}
                />
              ))}
          </Box>
        </Box>
      )}
      {usersQuery.data && usersQuery?.data?.page.totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "24px 0",
          }}
          count={usersQuery?.data?.page.totalPages}
          color="primary"
          page={usersQuery.data.page.number + 1}
          onChange={(e, page) => {
            sessionStorage.setItem("UserPageNumber", JSON.stringify(page - 1));
            setPage(page - 1);
          }}
        />
      )}
    </Layout>
  );
};

export default UserList;
