import QrReader from "react-qr-reader";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Camera from "@material-ui/icons/Camera";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useState } from "react";
import { Package } from "shared/types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { transferPackage } from "shared/network/package.api";
import { MenuItem } from "@material-ui/core";

type Props = {
  pack?: Package | null;
  setSelectedStorageCode: (selected: Package | null) => void;
  refetch: () => void;
};

type StatusUpdate = {
  oldStorageCode: string;
  storageCode: string;
};

const StorageCodeModal = ({ pack, setSelectedStorageCode, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [qrOpen, setQrOpen] = useState(false);
  const form = useForm<StatusUpdate>({ shouldUnregister: false });

  const onClose = () => {
    setSelectedStorageCode(null);
  };

  const handleScan = (data: string | null) => {
    if (data) {
      form.setValue("storageCode", data);
      setQrOpen(false);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const onSubmit = async (values: StatusUpdate) => {
    try {
      await transferPackage({
        trackingNumber: pack?.trackingNumber,
        fromStorageCode: values.oldStorageCode,
        toStorageCode: values.storageCode.trim() || undefined,
      });
      enqueueSnackbar(t("package.moveSuccess"), { variant: "success" });
      onClose();
      refetch();
    } catch (error) {
      enqueueSnackbar(t("package.moveFailure"), { variant: "error" });
      form.reset();
    }
  };

  return (
    <Dialog open={!!pack} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{`${t("package.storageCodeTitle")} (${pack?.trackingNumber})`}</DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="oldStorageCode"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              }}
              render={props => (
                <TextField
                  {...props}
                  label={t("package.currentStorageCode")}
                  InputLabelProps={{ shrink: true, required: true }}
                  SelectProps={{ displayEmpty: true }}
                  select
                >
                  <MenuItem value="">
                    <em>{t("common:choose")}</em>
                  </MenuItem>
                  {pack?.actualStatus?.relPackagesStatusStorages?.map((value, index) => (
                    <MenuItem key={index} value={value.storage?.storageCode}>
                      {value.storage?.storageCode}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <>
              {!qrOpen && (
                <>
                  <TextField
                    label={t("package.newStorageCode")}
                    name="storageCode"
                    InputLabelProps={{ shrink: true, required: true }}
                    inputRef={form.register({
                      required: {
                        value: true,
                        message: t("validation:required"),
                      },
                    })}
                    error={form.errors?.storageCode && true}
                    helperText={form.errors?.storageCode?.message}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<Camera />}
                    onClick={() => setQrOpen(true)}
                    style={{
                      marginTop: 12,
                      marginBottom: 8,
                      width: "100%",
                    }}
                  >
                    {t("package.qrCodeButton")}
                  </Button>
                </>
              )}
              {qrOpen && (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => setQrOpen(false)}
                    style={{
                      marginBottom: 8,
                      width: "100%",
                    }}
                  >
                    {t("package.storageNumberButton")}
                  </Button>
                  <QrReader
                    delay={100}
                    resolution={100}
                    onError={handleError}
                    onScan={handleScan}
                  />
                </>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="text" onClick={() => onClose()}>
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("common:button.save")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default StorageCodeModal;
