import * as React from "react";

import Box from "@material-ui/core/Box";
import Loading from "components/Loading";
import Edit from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Layout from "components/layout/Layout";
import LinkIcon from "@material-ui/icons/Link";
import Delete from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useStyles from "./components/ArticleForm/styles";
import { palette } from "config/theme";
import { useQuery } from "react-query";
import { Link } from "components/router";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "shared/reducers/application";
import { deleteArticle, listArticles } from "shared/network/article.api";

const ListArticle = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const articleQuery = useQuery("articles", async () => {
    const { data } = await listArticles();
    return data;
  });

  const { isFetching } = articleQuery;

  async function removeArticle(id: number) {
    try {
      await deleteArticle(id);
      articleQuery.refetch();
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.delete.success", {
            subject: t("article.subject"),
          }),
          options: { variant: "success" },
        }),
      );
    } catch (e) {
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.delete.failure", {
            subject: t("article.subject"),
          }),
          options: { variant: "error" },
        }),
      );
    }
  }

  return (
    <Layout
      style={{ textTransform: "uppercase" }}
      title={t("article.ListTitle")}
      actionButton={
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          component={Link}
          to="/article/create"
          startIcon={<AddIcon />}
        >
          {t("common:button.create")}
        </Button>
      }
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box>
          {isFetching ? (
            <Loading />
          ) : (
            <>
              {!articleQuery?.data?.items.length ? (
                <Typography variant="body1">{t("article.notFound")}</Typography>
              ) : (
                articleQuery?.data?.items.map((article, index) => {
                  let link = "";
                  if (article.subjectType === "TOS") {
                    link = "/aszf";
                  } else if (article.subjectType === "GDPR") {
                    link = "/gdpr";
                  }
                  return (
                    <div className={classes.list} key={index}>
                      {articleQuery.isFetching ? (
                        <Skeleton animation="wave" variant="text" width="50%" />
                      ) : (
                        <Typography style={{ fontSize: 16 }}>{article.title}</Typography>
                      )}
                      <Box justifySelf="end" alignItems="center" display="flex">
                        {article.isActive && (
                          <Tooltip title={t("article.isActive").toString()}>
                            {articleQuery.isFetching ? (
                              <Skeleton
                                animation="wave"
                                variant="circle"
                                width="32px"
                                height="32px"
                              />
                            ) : (
                              <CheckIcon style={{ marginRight: 8, color: palette.main }} />
                            )}
                          </Tooltip>
                        )}
                        {!!link && article.isActive && (
                          <Tooltip title={t("Link").toString()}>
                            {articleQuery.isFetching ? (
                              <Skeleton
                                animation="wave"
                                variant="circle"
                                width="32px"
                                height="32px"
                              />
                            ) : (
                              <IconButton style={{ padding: 8 }} component={Link} to={link}>
                                <LinkIcon />
                              </IconButton>
                            )}
                          </Tooltip>
                        )}
                        <Tooltip title={t("common:button.modify").toString()}>
                          {articleQuery.isFetching ? (
                            <Skeleton
                              animation="wave"
                              variant="circle"
                              width="32px"
                              height="32px"
                            />
                          ) : (
                            <IconButton
                              style={{ padding: 8 }}
                              component={Link}
                              to={`/article/${article.id}/modify`}
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </Tooltip>
                        <Tooltip title={t("common:button.delete").toString()}>
                          {articleQuery.isFetching ? (
                            <Skeleton
                              animation="wave"
                              variant="circle"
                              width="32px"
                              height="32px"
                            />
                          ) : (
                            <IconButton
                              style={{ padding: 8 }}
                              onClick={() => {
                                if (article.id) {
                                  removeArticle(article.id);
                                }
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </Tooltip>
                      </Box>
                    </div>
                  );
                })
              )}
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default ListArticle;
