import { Box, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import Layout from "components/layout/Layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCompanyById, listCompanyPageable } from "shared/network/company.api";
import DeliveryExtraChargeCreate from "./DeliveryExtraChargeCreate";
import DeliveryExtraChargeListItem from "./DeliveryExtraChargeListItem";
import DeliveryExtraChargeModify from "./DeliveryExtraChargeModify";

const DeliveryExtraChargeView = () => {
  const { t } = useTranslation();

  const [filter] = useState<string>("");

  const companyListQuery = useQuery(["companyList", filter], async () => {
    const { data } = await listCompanyPageable(0, 1000000, filter);
    return data;
  });

  const [id, setId] = useState(0);

  const companyQuery = useQuery(
    ["companyQueryDeliveryExtraChargeView", id],
    async () => {
      const { data } = await getCompanyById(id);
      return data;
    },
    { enabled: id > 0 },
  );

  return (
    <>
      <Layout
        title={t("company.deliveryExtraCharge.title")}
        actionButton={
          <>
            <DeliveryExtraChargeCreate
              company={companyQuery.data?.company}
              onSubmitSuccess={companyQuery.refetch}
              refetch={companyQuery.refetch}
            />
            <Box width="250px" marginLeft="10px" height="73px">
              <TextField
                name="companyId"
                label={t("common:field.company")}
                defaultValue="Válassz"
                onChange={e => {
                  setId(Number.parseInt(e.target.value));
                }}
                select
              >
                <MenuItem value="Válassz" disabled>
                  <em>{t("common:choose")}</em>
                </MenuItem>
                {companyListQuery?.data?.page.content.map((company, index) => (
                  <MenuItem key={index} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </>
        }
      >
        {id > 0 ? (
          <>
            <DeliveryExtraChargeListItem
              company={companyQuery.data?.company}
              refetch={companyListQuery.refetch}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <DeliveryExtraChargeModify
                  company={companyQuery.data?.company}
                  onSubmitSuccess={companyQuery.refetch}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography variant="h5" style={{ textAlign: "center", color: "gray" }}>
            {t("common:noData")}
          </Typography>
        )}
      </Layout>
    </>
  );
};
export default DeliveryExtraChargeView;
