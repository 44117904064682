import { useQuery } from "react-query";
import { getShippingPrices } from "shared/network/shippingprices.api";
import React, { useEffect, useState } from "react";
import Layout from "components/layout/Layout";
import { useTranslation } from "react-i18next";
import ShippingPriceList from "components/shippingPrices/ShippingPriceList";

const ShippingPriceListView = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(
    parseInt(window.sessionStorage.getItem("ShippingPricePageNumber") || JSON.stringify(0)),
  );
  // const [search, setSearch] = useState("");

  // const form = useForm();

  const query = useQuery(
    ["shippingPrices", page],
    async () => {
      const { data } = await getShippingPrices({ page, size: 10 });
      return data;
    },
    { keepPreviousData: true },
  );

  useEffect(() => {
    window.sessionStorage.setItem("ShippingPricePageNumber", JSON.stringify(page));
  }, [page]);

  return (
    <Layout title={t("shippingPrice.title")}>
      {/*<form*/}
      {/*  onSubmit={form.handleSubmit(values => {*/}
      {/*    setSearch(*/}
      {/*      Object.entries(values).reduce((acc, [key, value]) => {*/}
      {/*        if (value) {*/}
      {/*          let predicate;*/}
      {/*          if ((key = "packageName")) {*/}
      {/*            predicate = `packages.name:${value}`;*/}
      {/*          } else {*/}
      {/*            predicate = `${key}:${value}`;*/}
      {/*          }*/}
      {/*          return acc ? `${acc},${predicate}` : predicate;*/}
      {/*        } else {*/}
      {/*          return acc;*/}
      {/*        }*/}
      {/*      }, ""),*/}
      {/*    );*/}
      {/*  })}*/}
      {/*>*/}
      {/*  <TextField*/}
      {/*    name="packageName"*/}
      {/*    label={t("shippingPrice.packageName")}*/}
      {/*    inputRef={form.register()}*/}
      {/*  />*/}
      {/*  <TextField*/}
      {/*    name="packageTrackingNumber"*/}
      {/*    label={t("shippingPrice.packageTrackingNumber")}*/}
      {/*    inputRef={form.register()}*/}
      {/*  />*/}
      {/*  <TextField*/}
      {/*    name="vehiclelicensePlateNumber"*/}
      {/*    label={t("shippingPrice.vehiclelicensePlateNumber")}*/}
      {/*    inputRef={form.register()}*/}
      {/*  />*/}
      {/*  <Button type="submit">Szűrás</Button>*/}
      {/*</form>*/}
      {query.data?.page.content && (
        <ShippingPriceList
          shippingPrices={query.data.page.content}
          setPage={setPage}
          page={query.data.page.number + 1}
          totalPages={query.data.page.totalPages}
          modifySubmitSuccess={query.refetch}
        />
      )}
    </Layout>
  );
};

export default ShippingPriceListView;
