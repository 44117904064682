import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { createSelector } from "@reduxjs/toolkit";
import { AVAIBLE_FUEL_TYPES, AVAIBLE_VEHICLE_TYPES, VEHICLE_BODY_TYPES } from "config/constants";
import { RootState } from "config/store";
import { round } from "lodash";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCompanyPageable } from "shared/network/company.api";
import { listUsersByCompany } from "shared/network/user.api";
import { floatPattern, numberPattern } from "shared/util/validation";

export type VehicleFormFields = {
  companyId: number;
  userId: number;
  weightCapacity: number;
  volume: number;
  licensePlateNumber: string;
  brand: string;
  type: string;
  environmentalCategory: string;
  axleNumber: number;
  firstRegisteredDate: string;
  comerenceDescription: string;
  chassisNumber: string;
  allWeight: number;
  selfWeight: number;
  carDocumentsExpirationDate: string;
  registeredDateInHungary: string;
  tachographExpirationDate: string;
  typeApproveNumber: string;
  cylinderCapacity: number;
  maxPerformance: number;
  propellant: string;
  performanceWeightRatio: number;
  seatNumber: number;
  standingPlaceNumber: number;
  vehicleCategory: string;
  haulData: string;
  motorCode: string;
  vehicleColor: string;
  colEncumbrance: number;
  euroPaletteCapacity: number;
  bodyType: string;
  eurotaxCode: string;
  gearboxType: string;
  tailLift: boolean;
  height: number;
  width: number;
  length: number;
  productionYear: number;
  co2Amount: string;
  phone: string;
};

const useStyles = makeStyles(() => ({
  title: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: 14,
    paddingBottom: 8,
    borderBottom: "1px solid lightgrey",
    margin: "12px 0 18px",
  },
}));

const VehicleForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { register, errors, watch, setValue } = useFormContext<VehicleFormFields>();

  const { user } = useSelector(
    createSelector(
      (state: RootState) => state.authentication,
      (state: RootState) => state.application.currentCompany,
      (authentication, currentCompany) => ({
        user: authentication.user,
        permissions: authentication.permissions,
        currentCompany,
      }),
    ),
  );

  const companyListQuery = useQuery(["companyQueryVehicleForm"], async () => {
    const { data } = await listCompanyPageable(0, 1000000, "isShipper:TRUE");
    return data.page.content;
  });

  const { height, width, length, companyId } = watch(["height", "width", "length", "companyId"]);

  useEffect(() => {
    setValue("volume", height && width && length ? round(height * width * length, 2) : 0);
  }, [height, width, length, setValue]);

  const usersQuery = useQuery(
    ["userListQueryVehicleForm", companyId],
    async () => {
      const { data } = await listUsersByCompany(companyId);
      return data.items;
    },
    { enabled: !!companyId },
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="companyId"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t("validation:required"),
              },
            }}
            render={props => (
              <TextField
                {...props}
                label={t("common:field.company")}
                InputLabelProps={{ shrink: true, required: true }}
                error={errors.companyId && true}
                SelectProps={{ displayEmpty: true }}
                disabled={user?.userType === "NORMAL"}
                select
              >
                <MenuItem value="">
                  <em>{t("common:choose")}</em>
                </MenuItem>
                {companyListQuery?.data?.map((company, index) => (
                  <MenuItem key={index} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {usersQuery.status === "error" ? (
            <Box display="flex" alignItems="center">
              <Typography style={{ color: "gray" }}>{t("vehicle.create.noUserData")}</Typography>
            </Box>
          ) : (
            <Controller
              name="userId"
              defaultValue=""
              render={props => (
                <TextField
                  {...props}
                  disabled={!companyId}
                  label={t("vehicle.properties.user")}
                  select
                >
                  <MenuItem value="">
                    <em>{t("common:choose")}</em>
                  </MenuItem>
                  {usersQuery?.data?.map((user, index) => (
                    <MenuItem key={index} value={user.id}>
                      {user.lastName + " " + user.firstName + "  (" + user.email + ")"}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="phone"
            label={t("vehicle.properties.phone")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
          />
        </Grid>
      </Grid>

      <Typography className={classes.title}>{t("vehicle.create.generalData")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="licensePlateNumber"
            label={t("vehicle.properties.licensePlateNumber")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.licensePlateNumber && true}
            helperText={errors?.licensePlateNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="brand"
            label={t("vehicle.properties.brand")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.brand && true}
            helperText={errors?.brand?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="type"
            label={t("vehicle.properties.type")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.type && true}
            helperText={errors?.type?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="vehicleColor"
            label={t("vehicle.properties.vehicleColor")}
            inputRef={register()}
            error={errors?.vehicleColor && true}
            helperText={errors?.vehicleColor?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="chassisNumber"
            label={t("vehicle.properties.chassisNumber")}
            inputRef={register()}
            error={errors?.chassisNumber && true}
            helperText={errors?.chassisNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="motorCode"
            label={t("vehicle.properties.motorCode")}
            inputRef={register()}
            error={errors?.motorCode && true}
            helperText={errors?.motorCode?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="cylinderCapacity"
            label={t("vehicle.properties.cylinderCapacity")}
            inputRef={register({
              pattern: floatPattern(),
            })}
            error={errors?.cylinderCapacity && true}
            helperText={errors?.cylinderCapacity?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="productionYear"
            label={t("vehicle.properties.productionYear")}
            inputRef={register({
              pattern: numberPattern(),
            })}
            error={errors?.productionYear && true}
            helperText={errors?.productionYear?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="gearboxType"
            label={t("vehicle.properties.gearboxType")}
            inputRef={register()}
            error={errors?.gearboxType && true}
            helperText={errors?.gearboxType?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="seatNumber"
            type="number"
            label={t("vehicle.properties.seatNumber")}
            inputRef={register({
              pattern: floatPattern(),
            })}
            error={errors?.seatNumber && true}
            helperText={errors?.seatNumber?.message}
          />
        </Grid>
      </Grid>
      <Typography className={classes.title}>{t("vehicle.create.cargospaceData")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="height"
            type="number"
            inputProps={{ step: ".01" }}
            label={`${t("vehicle.properties.height")} (m)`}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
              pattern: floatPattern(),
            })}
            error={errors?.height && true}
            helperText={errors?.height?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="length"
            type="number"
            inputProps={{ step: ".01" }}
            label={`${t("vehicle.properties.length")} (m)`}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
              pattern: floatPattern(),
            })}
            error={errors?.length && true}
            helperText={errors?.length?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="width"
            type="number"
            inputProps={{ step: ".01" }}
            label={`${t("vehicle.properties.width")} (m)`}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
              pattern: floatPattern(),
            })}
            error={errors?.width && true}
            helperText={errors?.width?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="volume"
            type="number"
            inputProps={{ step: ".01" }}
            defaultValue={0}
            label={`${t("vehicle.properties.volume")} (m³)`}
            disabled
            inputRef={register()}
            error={errors?.volume && true}
            helperText={errors?.volume?.message}
          />
        </Grid>
      </Grid>
      <Typography className={classes.title}>{t("vehicle.create.technicalData")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="weightCapacity"
            type="number"
            inputProps={{ step: ".01" }}
            label={`${t("vehicle.properties.weightCapacity")} (kg)`}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
              pattern: floatPattern(),
            })}
            error={errors?.weightCapacity && true}
            helperText={errors?.weightCapacity?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="vehicleCategory"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t("validation:required"),
              },
            }}
            render={props => (
              <TextField
                {...props}
                label={t("vehicle.properties.vehicleCategory")}
                InputLabelProps={{ shrink: true, required: true }}
                error={errors.vehicleCategory && true}
                SelectProps={{ displayEmpty: true }}
                select
              >
                {AVAIBLE_VEHICLE_TYPES.map(type => (
                  <MenuItem key={type} value={type}>
                    {t(`common:vehicleType.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="carDocumentsExpirationDate"
            defaultValue={new Date()}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                format="yyyy. MM. dd."
                label={t("vehicle.properties.carDocumentsExpirationDate")}
                autoOk
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="tachographExpirationDate"
            defaultValue={new Date()}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                format="yyyy. MM. dd."
                label={t("vehicle.properties.tachographExpirationDate")}
                autoOk
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="allWeight"
            type="number"
            inputProps={{ step: ".01" }}
            label={t("vehicle.properties.allWeight")}
            inputRef={register({
              pattern: floatPattern(),
            })}
            error={errors?.allWeight && true}
            helperText={errors?.allWeight?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="selfWeight"
            type="number"
            inputProps={{ step: ".01" }}
            label={t("vehicle.properties.selfWeight")}
            inputRef={register({
              pattern: floatPattern(),
            })}
            error={errors?.selfWeight && true}
            helperText={errors?.selfWeight?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="maxPerformance"
            type="number"
            inputProps={{ step: ".01" }}
            label={t("vehicle.properties.maxPerformance")}
            inputRef={register({
              pattern: floatPattern(),
            })}
            error={errors?.maxPerformance && true}
            helperText={errors?.maxPerformance?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="propellant"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t("validation:required"),
              },
            }}
            render={props => (
              <TextField
                {...props}
                label={t("vehicle.properties.propellant")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{ displayEmpty: true }}
                select
                error={errors?.propellant && true}
                helperText={errors?.propellant?.message}
              >
                {AVAIBLE_FUEL_TYPES.map(type => (
                  <MenuItem key={type} value={type}>
                    {t(`common:fuelTypes.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="co2Amount"
            label={t("vehicle.properties.co2Amount")}
            inputRef={register({
              pattern: floatPattern(),
            })}
            error={errors?.co2Amount && true}
            helperText={errors?.co2Amount?.message}
          />
        </Grid>
      </Grid>
      <Typography className={classes.title}>{t("vehicle.create.otherData")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="environmentalCategory"
            label={t("vehicle.properties.environmentalCategory")}
            inputRef={register()}
            error={errors?.environmentalCategory && true}
            helperText={errors?.environmentalCategory?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="performanceWeightRatio"
            label={t("vehicle.properties.performanceWeightRatio")}
            inputRef={register()}
            error={errors?.performanceWeightRatio && true}
            helperText={errors?.performanceWeightRatio?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="axleNumber"
            label={t("vehicle.properties.axleNumber")}
            inputRef={register()}
            error={errors?.axleNumber && true}
            helperText={errors?.axleNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="typeApproveNumber"
            label={t("vehicle.properties.typeApproveNumber")}
            inputRef={register()}
            error={errors?.typeApproveNumber && true}
            helperText={errors?.typeApproveNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="haulData"
            label={t("vehicle.properties.haulData")}
            inputRef={register()}
            error={errors?.haulData && true}
            helperText={errors?.haulData?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="colEncumbrance"
            label={t("vehicle.properties.colEncumbrance")}
            inputRef={register()}
            error={errors?.colEncumbrance && true}
            helperText={errors?.colEncumbrance?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="eurotaxCode"
            label={t("vehicle.properties.eurotaxCode")}
            inputRef={register()}
            error={errors?.eurotaxCode && true}
            helperText={errors?.eurotaxCode?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="euroPaletteCapacity"
            label={t("vehicle.properties.euroPaletteCapacity")}
            inputRef={register()}
            error={errors?.euroPaletteCapacity && true}
            helperText={errors?.euroPaletteCapacity?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstRegisteredDate"
            defaultValue={new Date()}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                format="yyyy. MM. dd."
                label={t("vehicle.properties.firstRegisteredDate")}
                autoOk
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="registeredDateInHungary"
            defaultValue={new Date()}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                format="yyyy. MM. dd."
                label={t("vehicle.properties.registeredDateInHungary")}
                autoOk
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="bodyType"
            defaultValue=""
            render={props => (
              <TextField
                {...props}
                label={t("vehicle.properties.bodyType")}
                error={errors.bodyType && true}
                helperText={errors.bodyType?.message}
                select
              >
                <MenuItem value="">
                  <em>{t("common:choose")}</em>
                </MenuItem>
                {VEHICLE_BODY_TYPES?.map(type => (
                  <MenuItem key={type} value={type}>
                    {t(`vehicle.bodyType.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="comerenceDescription"
            label={t("vehicle.properties.comerenceDescription")}
            inputRef={register()}
            error={errors?.comerenceDescription && true}
            helperText={errors?.comerenceDescription?.message}
            multiline
            rowsMax={5}
          />
        </Grid>
      </Grid>
      <Controller
        name="tailLift"
        defaultValue={false}
        render={props => (
          <FormControlLabel
            control={
              <Checkbox
                {...props}
                color="primary"
                checked={props.value}
                onChange={e => {
                  props.onChange(e.target.checked);
                }}
              />
            }
            label={t("vehicle.properties.tailLift")}
            labelPlacement="end"
          />
        )}
      />
    </>
  );
};

export default VehicleForm;
