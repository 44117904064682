import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DeliveryChargeRoutes from "components/deliveryextracharge";
import PrivateRoute from "components/router/PrivateRoute";
import { Notifier } from "components/snackbar";
import SplashScreen from "components/splashScreen/SplashScreen";
import { CAPTCHA_KEY } from "config/constants";
import supportedLocales from "config/locale";
import { RootState } from "config/store";
import Logout from "Logout";
import NotFound from "NotFound";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { fetchAccount, finishInitializing } from "shared/reducers/authentication";
import { autheticationTokenExists } from "shared/util/user";
import ArticleRoutes from "views/article";
import AuditLog from "views/audit-log/AuditLog";
import CompanyRoutes from "views/company";
import Dashboard from "views/dashboard/Dashboard";
import ForgottenPasswordRoutes from "views/forgotten-password/ForgottenPasswordRoutes";
import ResetPassword from "views/forgotten-password/ResetPassword";
import Gdpr from "views/gdpr";
import Login from "views/login/Login";
import PackageRoutes from "views/package";
import ProfileRoutes from "views/profile";
import PropertyRoutes from "views/property";
import Report from "views/report/Report";
import ShippingPriceRoutes from "views/shipping-price";
import Tos from "views/tos";
import TourRoutes from "views/tour";
import TrackingRoutes from "views/tracking";
import SignUpFinish from "views/user/SignUpFinish";
import UserCreate from "views/user/UserCreate";
import UserList from "views/user/UserList";
import UserModify from "views/user/UserModify";
import VehicleRoutes from "views/vehicle";
import WarehouseRoutes from "views/warehouse";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const initialized = useSelector((state: RootState) => state.authentication.initialized);

  useEffect(() => {
    if (autheticationTokenExists()) {
      dispatch(fetchAccount());
    } else {
      dispatch(finishInitializing());
    }
  }, [dispatch]);

  if (!initialized) {
    return <SplashScreen />;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={supportedLocales[i18n.language]}>
      <Switch>
        <Route
          exact
          path={["/login", "/"]}
          component={() => (
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
              <Login />
            </GoogleReCaptchaProvider>
          )}
        />
        <Route path="/user/activate/:token" component={SignUpFinish} />
        <Route path="/forgotten-password" component={ForgottenPasswordRoutes} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/company" component={CompanyRoutes} customerService />
        <PrivateRoute path="/user-list" component={UserList} permissions={["SHOPKEEPER"]} />
        <PrivateRoute path="/user-create" component={UserCreate} permissions={["SHOPKEEPER"]} />
        <PrivateRoute path="/user/modify/:id" component={UserModify} permissions={["SHOPKEEPER"]} />
        <PrivateRoute
          path="/warehouse"
          component={WarehouseRoutes}
          permissions={["SHOPKEEPER", "WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"]}
        />
        <PrivateRoute
          path="/vehicle"
          component={VehicleRoutes}
          permissions={["SHIPORGANIZER", "SHIPPER"]}
        />
        <PrivateRoute path="/profile" component={ProfileRoutes} />
        <PrivateRoute path="/tour" component={TourRoutes} />
        <PrivateRoute path="/audit-log" component={AuditLog} admin />
        <PrivateRoute path="/article" component={ArticleRoutes} admin />
        <PrivateRoute path="/property" component={PropertyRoutes} admin />
        <PrivateRoute path="/report" component={Report} admin />
        <PrivateRoute path="/deliveryextracharge" component={DeliveryChargeRoutes} />
        <PrivateRoute path="/package" component={PackageRoutes} />
        <PrivateRoute path="/shipping-price" component={ShippingPriceRoutes} />
        <Route path="/tracking" component={TrackingRoutes} />
        <Route path="/reset/finish/:id" component={ResetPassword} />
        <Route path="/aszf" component={Tos} />
        <Route path="/gdpr" component={Gdpr} />
        {/* <Route path="/sign-up" component={SignUpRoutes} /> */}
        <Route path="*" component={NotFound} />

        {/*<Route path="/cookieInfo" component={CookieInfo} />
        <Route path="/public" component={PublicSignUpRoutes} />*/}
      </Switch>
      <Notifier />
    </MuiPickersUtilsProvider>
  );
}

export default App;
