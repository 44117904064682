import { hu } from "date-fns/locale";

export type SupportedLanguages = "hu";

export type LocaleOptions = {
  tinyMCEDates: string[];
  tinyMCELang: string;
  weekStartsOn?: 0 | 3 | 1 | 2 | 4 | 5 | 6 | undefined;
};

export type SupportedLocales = {
  [key in SupportedLanguages]: {
    name: string;
    locale: Locale;
    options: LocaleOptions;
  };
};

const supportedLocales: { [key: string]: Locale } = { hu: hu };

export const SupportedLocale: SupportedLocales = {
  hu: {
    name: "Magyar",
    locale: hu,
    options: {
      tinyMCEDates: ["%H:%M:%S", "%Y. %m. %d.", "%Y. %a. %d. %H:%M:%S"],
      tinyMCELang: "hu_HU",
    },
  },
};

export default supportedLocales;
