import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "components/router";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import { palette } from "config/theme";
import UnauthorizedLayout from "components/layout/UnauthorizedLayout";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <UnauthorizedLayout title={t("common:notFound").toString()}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxHeight="400px"
        padding="18px"
      >
        <Box
          display="flex"
          alignItems="center"
          width="50%"
          flexDirection="column"
          justifyContent="center"
          gridGap={20}
        >
          <SentimentDissatisfiedIcon style={{ width: 100, height: 100, color: palette.main }} />
          <Typography variant="h2" style={{ color: palette.main }}>
            404
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={5}
        >
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {t("notFound.title")}
          </Typography>
          <Link component={RouterLink} to="/" style={{ fontSize: 18, marginTop: 12 }}>
            {t("notFound.backTo")}
          </Link>
        </Box>
      </Box>
    </UnauthorizedLayout>
  );
};

export default NotFound;
