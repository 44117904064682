import axios from "config/axios";
import { Package } from "shared/types";
import { DeliveryPrice } from "views/company/CompanyZone";

export type MatrixType = "STORE" | "SHIPPER";

const ENDPOINT = "/deliveryprice";

export const saveDeliveryPrice = (params: {
  companyId: string;
  deliveryPriceListWeight?: DeliveryPrice[];
  deliveryPriceListVolume?: DeliveryPrice[];
}) => axios.post(`${ENDPOINT}/save`, params);

export const getDeliveryPriceList = (companyId: string, type: MatrixType) =>
  axios.post<{
    deliveryPriceListWeight: DeliveryPrice[];
    deliveryPriceListVolume: DeliveryPrice[];
  }>(`${ENDPOINT}/company-grouped`, { companyId, type });

export const getDeliveryPriceForPackage = (
  packagesEntry: Package[],
  companyId: string,
  zipCode: number,
) => axios.post(`${ENDPOINT}/get-price-for-package`, { packagesEntry, companyId, zipCode });
