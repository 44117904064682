import displayAddress from "shared/util/displayAddress";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { modifyTourAddress } from "shared/network/tour.api";
import { getAllWarehouse } from "shared/network/warehouse.api";
import { Tour } from "./TourSortingModal";
import { Package } from "shared/types";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
  tour: Tour;
  pack: Package;
};

const PackageAddressModal = ({ open, setOpen, tour, pack, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<any>({ shouldUnregister: false });
  const [toWarehouseChecked, setToWarehouseChecked] = useState(false);
  const [targetWarehouse, setTargetWarehouse] = useState<number>(0);

  const warehouseQuery = useQuery(["warehouseQueryPackageAddress"], async () => {
    const { data } = await getAllWarehouse(0, 10000000);
    return data.page.content;
  });

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    const selectedWarehouse = warehouseQuery.data?.find(warehouse => {
      return warehouse.address.id === targetWarehouse;
    });
    try {
      await modifyTourAddress({
        tourId: tour.id,
        address: toWarehouseChecked ? selectedWarehouse?.address : values,
        packagesId: pack.id,
      });
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: "cím",
        }),
        { variant: "success" },
      );
      onClose();
      refetch();
      form.reset();
    } catch (error) {
      enqueueSnackbar(
        t("common:notification.update.failure", {
          subject: "cím",
        }),
        { variant: "error" },
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Szállítási cím módosítása</DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={toWarehouseChecked}
                  //@ts-ignore
                  onClick={() => setToWarehouseChecked(prevState => !prevState)}
                />
              }
              label={t("tour.toWarehouse")}
              labelPlacement="end"
            />
            {toWarehouseChecked ? (
              <>
                <TextField
                  label={t("warehouse.list.title")}
                  defaultValue={0}
                  onChange={event => setTargetWarehouse(Number.parseInt(event.target.value))}
                  select
                >
                  <MenuItem value="" disabled>
                    <em>{t("common:choose")}</em>
                  </MenuItem>
                  {warehouseQuery.data?.map((warehouse, index) => {
                    return (
                      <MenuItem key={index} value={warehouse.address.id}>
                        {warehouse && ` ${warehouse.name} (${displayAddress(warehouse.address)})`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            ) : (
              <>
                <Typography style={{ color: "gray", marginTop: 12 }}>
                  {t("package.tracking.newAddress")}
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      name="country"
                      type="text"
                      label={t("common:field.address.country")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={form.register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={form.errors?.country && true}
                      helperText={form.errors?.country?.message}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="zipCode"
                      type="number"
                      label={t("common:field.address.zipCode")}
                      style={{ paddingRight: 12 }}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={form.register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={form.errors?.zipCode && true}
                      helperText={form.errors?.zipCode?.message}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="city"
                      type="text"
                      label={t("common:field.address.city")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={form.register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={form.errors?.city && true}
                      helperText={form.errors?.city?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address"
                      type="text"
                      label={t("common:field.address.address")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={form.register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={form.errors?.address && true}
                      helperText={form.errors?.address?.message}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                onClose();
              }}
            >
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("common:button.save")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default PackageAddressModal;
