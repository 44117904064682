import { Grid, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { DeliveryRequest } from "shared/types";

export type DeliveryExtraChargeFormValues = {
  id: number;
  companyId: number;
  withLoaderExtraCharge: number;
  hazardousExtraChargePercent: number;
  fragileExtraCharge: number;
  cashOnDeliveryMinimumPrice: number;
  cashOnDeliveryPercent: number;
  uniqueInsurancePercent: number;
  expressDeliveryPrice: number;
  authorityToLeavePercent: number;
  weekendDelivery: number;
  weekendDeliveryWithLoader: number;
  storagePriceDaily: number;
  freeStorageDays: number;
  electricCarExtraPercent: number;
  whiteGoods: number;
  removalBathroom: number;
  removalFurniture: number;
  removalMattress: number;
  removalSofas: number;
  removalKitchen: number;
  removalElectronics: number;
  removalAdditional: number;
};

type Props = {
  companyId?: string;
};

const DeliveryExtraChargeForm = ({ companyId }: Props) => {
  const { t } = useTranslation();

  const { getValues, register, errors } = useFormContext<DeliveryRequest>();

  return (
    <>
      <Grid container spacing={1}>
        <input name="id" value={getValues().id} type="hidden" ref={register()} />
        <input
          name="companyId"
          value={companyId || getValues().companyId}
          type="hidden"
          ref={register()}
        />
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="withLoaderExtraCharge"
            label={t("company.deliveryExtraCharge.with_loader_extra_charge")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.withLoaderExtraCharge}
            helperText={errors?.withLoaderExtraCharge?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="hazardousExtraChargePercent"
            label={t("company.deliveryExtraCharge.hazardous_extra_charge_percent")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.hazardousExtraChargePercent && true}
            helperText={errors?.hazardousExtraChargePercent?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="fragileExtraCharge"
            label={t("company.deliveryExtraCharge.fragile_extra_charge")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.fragileExtraCharge && true}
            helperText={errors?.fragileExtraCharge?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="cashOnDeliveryMinimumPrice"
            label={t("company.deliveryExtraCharge.cash_on_delivery_minimum_price")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.cashOnDeliveryMinimumPrice}
            helperText={errors?.cashOnDeliveryMinimumPrice?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="cashOnDeliveryPercent"
            label={t("company.deliveryExtraCharge.cash_on_delivery_percent")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.cashOnDeliveryPercent && true}
            helperText={errors?.cashOnDeliveryPercent?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="expressDeliveryPrice"
            label={t("company.deliveryExtraCharge.expressDeliveryPrice")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.expressDeliveryPrice && true}
            helperText={errors?.expressDeliveryPrice?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="uniqueInsurancePercent"
            label={t("company.deliveryExtraCharge.uniqueInsurancePercent")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.uniqueInsurancePercent && true}
            helperText={errors?.uniqueInsurancePercent?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="authorityToLeavePercent"
            label={t("company.deliveryExtraCharge.authorityToLeavePercent")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.authorityToLeavePercent && true}
            helperText={errors?.authorityToLeavePercent?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="weekendDelivery"
            label={t("company.deliveryExtraCharge.weekendDelivery")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.weekendDelivery && true}
            helperText={errors?.weekendDelivery?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="weekendDeliveryWithLoader"
            label={t("company.deliveryExtraCharge.weekendDeliveryWithLoader")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.weekendDeliveryWithLoader && true}
            helperText={errors?.weekendDeliveryWithLoader?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="storagePriceDaily"
            label={t("company.deliveryExtraCharge.storagePriceDaily")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.storagePriceDaily && true}
            helperText={errors?.storagePriceDaily?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="freeStorageDays"
            label={t("company.deliveryExtraCharge.freeStorageDays")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.freeStorageDays && true}
            helperText={errors?.freeStorageDays?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="electricCarExtraPercent"
            label={t("company.deliveryExtraCharge.electricCarExtraPercent")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.electricCarExtraPercent && true}
            helperText={errors?.electricCarExtraPercent?.message}
          />
        </Grid>
        {/*TODO: some form of grouping here*/}
      </Grid>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
            marginTop: "8px",
            marginBottom: "4px",
            borderBottom: "1px solid grey",
          }}
        >
          <Typography variant="h6">{t("company.deliveryExtraCharge.removeGoodsTitle")}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="whiteGoods"
            label={t("company.deliveryExtraCharge.whiteGoods")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.whiteGoods && true}
            helperText={errors?.whiteGoods?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalBathroom"
            label={t("company.deliveryExtraCharge.removalBathroom")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalBathroom && true}
            helperText={errors?.removalBathroom?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalMattress"
            label={t("company.deliveryExtraCharge.removalMattress")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalMattress && true}
            helperText={errors?.removalMattress?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalSofas"
            label={t("company.deliveryExtraCharge.removalSofas")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalSofas && true}
            helperText={errors?.removalSofas?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalKitchen"
            label={t("company.deliveryExtraCharge.removalKitchen")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalKitchen && true}
            helperText={errors?.removalKitchen?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalElectronics"
            label={t("company.deliveryExtraCharge.removalElectronics")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalElectronics && true}
            helperText={errors?.removalElectronics?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalFurniture"
            label={t("company.deliveryExtraCharge.removalFurniture")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalFurniture && true}
            helperText={errors?.removalFurniture?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <TextField
            name="removalAdditional"
            label={t("company.deliveryExtraCharge.removalAdditional")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.removalAdditional && true}
            helperText={errors?.removalAdditional?.message}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default DeliveryExtraChargeForm;
