import { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import useWindowSize from "shared/hooks/useWindowSize";
import { useTranslation } from "react-i18next";

type Props = {
  offsetTop: number;
  offsetLeft: number;
};

const SignatureCanvas = ({ offsetTop, offsetLeft }: Props) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const ref = useRef<HTMLCanvasElement | null>(null);

  const [drawing, setDrawing] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(Math.min((width ?? 0) - 114, 396));
  const [{ ctx, rect }, setState] = useState<{
    ctx?: null | CanvasRenderingContext2D;
    rect?: null | DOMRect;
  }>({ ctx: null, rect: null });

  useEffect(() => {
    setState({
      ctx: ref.current?.getContext("2d"),
      rect: ref.current?.getBoundingClientRect(),
    });
  }, [ref.current]); //eslint-disable-line

  useEffect(() => {
    if (ctx) {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, 100000, 100000);
      ctx.strokeStyle = "black";
      ctx.lineCap = "round";
      ctx.lineWidth = 2;
    }
  }, [ctx]);

  useEffect(() => {
    setCanvasWidth(Math.min((width ?? 0) - 114, 396));
  }, [width]);

  return (
    <>
      <canvas
        id="signature"
        ref={ref}
        onMouseDown={e => {
          if (ctx) {
            ctx.beginPath();
            ctx.moveTo(
              e.clientX + offsetLeft - (rect?.left ?? 0),
              e.clientY + offsetTop - (rect?.top ?? 0),
            );
            setDrawing(true);
          }
        }}
        onTouchStart={e => {
          if (ctx) {
            ctx.beginPath();
            ctx.moveTo(
              e.touches[e.touches.length - 1].clientX + offsetLeft - (rect?.left ?? 0),
              e.touches[e.touches.length - 1].clientY + offsetTop - (rect?.top ?? 0),
            );
            setDrawing(true);
          }
        }}
        onMouseMove={e => {
          if (drawing && ctx) {
            ctx.lineTo(
              e.clientX + offsetLeft - (rect?.left ?? 0),
              e.clientY + offsetTop - (rect?.top ?? 0),
            );
            ctx.stroke();
          }
        }}
        onTouchMove={e => {
          if (drawing && ctx) {
            ctx.lineTo(
              e.touches[e.touches.length - 1].clientX + offsetLeft - (rect?.left ?? 0),
              e.touches[e.touches.length - 1].clientY + offsetTop - (rect?.top ?? 0),
            );
            ctx.stroke();
          }
        }}
        onMouseUp={() => {
          ctx?.closePath();
          setDrawing(false);
        }}
        onMouseOut={() => {
          ctx?.closePath();
          setDrawing(false);
        }}
        width={canvasWidth}
        height={(canvasWidth * 2) / 3}
        style={{
          border: "1px solid gray",
          touchAction: "none",
        }}
      />
      <Button
        onClick={() => {
          if (ctx) {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, 100000, 100000);
          }
        }}
      >
        {t("common:button.delete")}
      </Button>
    </>
  );
};

export default SignatureCanvas;
