import axios from "config/axios";
import {
  Address,
  Company,
  CompanyRequest,
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
  Shop,
} from "shared/types";

const ENDPOINT = "/company";

export type CompanyShopAddressRequest = {
  addressList: Partial<Address[]>;
  companyId: string;
};

export const createCompany = (data: CompanyRequest) =>
  axios.post<GenericResponse<Company>>(ENDPOINT, data);

export const modifyCompany = (data: CompanyRequest) =>
  axios.put<GenericResponse<Company>>(ENDPOINT, data);

export const listCompanies = () => axios.get<GenericListResponse<Company>>(`${ENDPOINT}/get-all`);

export const listCompaniesByUser = () =>
  axios.get<GenericListResponse<Company>>(`${ENDPOINT}/get-all-by-user`);

export const getCompanyById = (id: number) =>
  axios.get<{ company: Company; shop: Shop; addressList: Address[] }>(`${ENDPOINT}/${id}`);

export const listCompanyPageable = (page = 0, size = 10, predicates: string = "") => {
  return axios.post<GenericPageResponse<Company>>(
    `${ENDPOINT}/page?page=${page}&size=${size}&search=${encodeURIComponent(predicates)}`,
  );
};

export const banCompany = (companyId: number, unbanAllUsers: boolean) =>
  axios.put<GenericResponse<Company>>(`${ENDPOINT}/ban`, {
    companyId: companyId,
    unbanAllUsers: unbanAllUsers,
  });
