import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "components/form/Button";
import { AREA_TYPES } from "config/constants";
import { Fragment } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  title: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: 14,
    paddingBottom: 8,
    borderBottom: "1px solid lightgrey",
    margin: "12px 0 6px 0",
  },
}));

const CompanyPickUpPoint = () => {
  const { t } = useTranslation();
  const { register, errors, control } = useFormContext();

  const classes = useStyles();
  const { append, fields, remove } = useFieldArray({
    control,
    name: "shopAddressList",
    keyName: "key",
  });

  return (
    <Fragment>
      <Box mt={4}>
        <Typography className={classes.title} style={{ marginBottom: 12 }}>
          {t("company.create.packagePickUp")}
        </Typography>
        {fields.map((field, index) => (
          <Fragment key={field.key}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("common:notification.packagePoint", {
                  index: index + 1,
                })}
              </Typography>
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton size="small" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box display="flex">
              <Box display="flex" flexDirection="column" gridGap={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={5}>
                    <TextField
                      margin="none"
                      defaultValue={field.title}
                      name={`shopAddressList[${index}].title`}
                      label={t("common:field.title")}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      name={`shopAddressList[${index}].id`}
                      value={field.id}
                      type="hidden"
                      ref={register()}
                    />
                    <TextField
                      margin="none"
                      defaultValue={field.country || "Magyarország"}
                      name={`shopAddressList[${index}].country`}
                      label={t("common:field.address.country")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={errors?.company?.shopAddressList?.[index]?.country && true}
                      helperText={errors?.company?.shopAddressList?.[index]?.country?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="none"
                      defaultValue={field.zipCode}
                      name={`shopAddressList[${index}].zipCode`}
                      type="number"
                      label={t("common:field.address.zipCode")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={errors?.company?.shopAddressList?.[index]?.zipCode && true}
                      helperText={errors?.company?.shopAddressList?.[index]?.zipCode?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <TextField
                      margin="none"
                      defaultValue={field.city}
                      name={`shopAddressList[${index}].city`}
                      label={t("common:field.address.city")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={errors?.company?.shopAddressList?.[index]?.city && true}
                      helperText={errors?.company?.shopAddressList?.[index]?.city?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <TextField
                      margin="none"
                      defaultValue={field.areaName}
                      name={`shopAddressList[${index}].areaName`}
                      label={t("common:field.address.areaName")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={errors?.company?.shopAddressList?.[index]?.areaName && true}
                      helperText={errors?.company?.shopAddressList?.[index]?.areaName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Controller
                      defaultValue={field.areaType || "UTCA"}
                      name={`shopAddressList[${index}].areaType`}
                      render={props => (
                        <TextField
                          {...props}
                          margin="none"
                          select
                          label={t("common:field.address.areaType")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!errors?.company?.shopAddressList?.[index]?.areaType}
                          helperText={errors?.company?.shopAddressList?.[index]?.areaType?.message}
                        >
                          {AREA_TYPES.map(type => (
                            <MenuItem key={type} value={type}>
                              {t(`common:areaType.${type}`)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      margin="none"
                      defaultValue={field.houseNumber}
                      name={`shopAddressList[${index}].houseNumber`}
                      label={t("common:field.address.houseNumber")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={!!errors?.company?.shopAddressList?.[index]?.houseNumber}
                      helperText={errors?.company?.shopAddressList?.[index]?.houseNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      margin="none"
                      defaultValue={field.floor}
                      name={`shopAddressList[${index}].floor`}
                      label={t("common:field.address.floor")}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register()}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Divider style={{ width: "100%", margin: "24px 0 12px 0" }} />
          </Fragment>
        ))}
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: "12px auto" }}
          onClick={event => {
            event.stopPropagation();
            append({
              country: "",
              zipCode: "",
              city: "",
              areaName: "",
              areaType: "",
              houseNumber: "",
              floor: "",
            });
          }}
          startIcon={<AddIcon />}
        >
          {t("common:button.newPoint")}
        </Button>
      </Box>
    </Fragment>
  );
};
export default CompanyPickUpPoint;
