import {
  Box,
  Button,
  CircularProgress,
  //Dialog,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import Layout from "components/layout/Layout";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "components/router";
import { useQuery } from "react-query";
import { getAllWarehouse } from "shared/network/warehouse.api";
import { Pagination } from "@material-ui/lab";
import WarehouseListItem from "./components/WarehouseListItem";
import { makeStyles } from "@material-ui/core";
import usePermissions from "shared/hooks/usePermissions";

const useStyles = makeStyles(
  () => ({
    title: {
      fontWeight: "bold",
    },
  }),
  { name: "WarehouseList" },
);

const WarehouseList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAdmin } = usePermissions();

  const [page, setPage] = React.useState<number>(
    parseInt(window.sessionStorage.getItem("WarehousePageNumber") || JSON.stringify(0)),
  );

  const warehouseQuery = useQuery(["warehouseQueryList", page], async () => {
    const { data } = await getAllWarehouse(page, 10);
    return data;
  });

  useEffect(() => {
    window.sessionStorage.setItem("WarehousePageNumber", JSON.stringify(page));
  }, [page]);

  return (
    <Layout
      title={t("warehouse.list.title")}
      actionButton={
        isAdmin && (
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            component={Link}
            to="/warehouse/create"
            startIcon={<AddIcon />}
          >
            {t("warehouse.create.title")}
          </Button>
        )
      }
    >
      {warehouseQuery.isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ overflow: "auto hidden" }}>
          <Box style={{ minWidth: 1000 }}>
            <Grid
              container
              style={{
                alignItems: "center",
                padding: "12px 0",
              }}
            >
              <Grid item xs={2}>
                <Typography className={classes.title}>{t("warehouse.name")}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.title}>{t("warehouse.country")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title}>{t("warehouse.address")}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.title}>{t("warehouse.space")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title}>{t("warehouse.height")}</Typography>
              </Grid>
            </Grid>
            <Divider />
            {warehouseQuery.data?.page.content.length ? (
              warehouseQuery.data?.page.content?.map((warehouse, index, array) => (
                <WarehouseListItem
                  key={warehouse.id}
                  warehouse={warehouse}
                  index={index}
                  array={array}
                />
              ))
            ) : (
              <Typography variant="h5" style={{ textAlign: "center", color: "gray" }}>
                {t("common:noData")}
              </Typography>
            )}
            {warehouseQuery.data && warehouseQuery?.data?.page.totalPages > 1 && (
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "24px 0",
                }}
                count={warehouseQuery?.data?.page.totalPages}
                color="primary"
                page={warehouseQuery.data.page.number + 1}
                onChange={(e, page) => {
                  sessionStorage.setItem("WarehousePageNumber", JSON.stringify(page - 1));
                  setPage(page - 1);
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default WarehouseList;
