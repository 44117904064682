import { configureStore, combineReducers, Action, ThunkAction } from "@reduxjs/toolkit";
import authentication from "shared/reducers/authentication";
import application from "shared/reducers/application";

const rootReducer = combineReducers({
  authentication,
  application,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
