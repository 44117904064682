import axios from "config/axios";
import {
  GenericListResponse,
  GenericPageResponse,
  GenericResponse,
  Package,
  Warehouse,
  Storage,
  //GenericMapResponse,
  //Coordinates,
  //Address,
} from "shared/types";

const ENDPOINT = "/warehouse";

export const createWarehouse = (data: {
  warehouse: {
    name: string;
    height: string | number;
    space: string | number;
    companyId: string | number;
  };
  address: {
    country: string;
    zipCode: string | number;
    city: string;
    areaName: string;
    areaType: string;
    houseNumber: string;
    floor: string | null;
  };
}) => axios.post<GenericResponse<Warehouse>>(ENDPOINT, data);

export const modifyWarehouse = (data: {
  warehouse: {
    id: number | string;
    name: string;
    height: string | number;
    space: string | number;
    companyId: string | number;
  };
  address: {
    id: number;
    country: string;
    zipCode: string | number;
    city: string;
    areaName: string;
    areaType: string;
    houseNumber: string;
    floor: string | null;
  };
}) => axios.put<GenericResponse<Warehouse>>(ENDPOINT, data);

export const getAllWarehouse = (page: number, size: number, predicates: string = "") =>
  axios.get<GenericPageResponse<Warehouse>>(
    `${ENDPOINT}/page?page=${page}&size=${size}&search=${predicates}`,
  );

export const getWarehouses = () => axios.get<GenericListResponse<Warehouse>>(`${ENDPOINT}/get-all`);

export const getWarehouseById = (id: number) =>
  axios.get<GenericResponse<Warehouse>>(`${ENDPOINT}?id=${id}`);

export const getExtendedWarehouseById = (id: number) =>
  axios.get<
    GenericResponse<{
      warehouseEntry: Warehouse;
      packageList: Package[];
      storageList: Storage[];
    }>
  >(`${ENDPOINT}/extended?id=${id}`);

export const listByCompanyId = (companyId: number) =>
  axios.get<GenericListResponse<Warehouse>>(`${ENDPOINT}/company?companyId=${companyId}`);

export const linkUserToWarehouse = (data: { warehouseId: number; userId: number }) =>
  axios.post<{ status: string }>(`${ENDPOINT}/link`, data);
