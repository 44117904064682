import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

export type StorageFormValues = {
  storageItems: {
    rowName: string;
    numberOfColumns: string;
    numberOfLevels: string;
    numberOfSpaces: string;
    isCustom: boolean;
  }[];
};

const useStyles = makeStyles(() => ({
  title: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: 14,
    paddingBottom: 8,
    borderBottom: "1px solid lightgrey",
    margin: "12px 0 18px",
  },
}));

const StorageItemsForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { register, control, errors, watch } = useFormContext<StorageFormValues>();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "storageItems",
    keyName: "key",
  });

  // @ts-ignore
  const storageItems = watch("storageItems", fields);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography className={classes.title}>{t("warehouse.create.storage.title")}</Typography>
      {fields.map((field, index) => (
        <>
          <Typography style={{ fontWeight: "bold" }}>
            {t("common:notification.storage", {
              index: index + 1,
            })}
          </Typography>
          <Grid container key={field.key} alignItems="center" spacing={2}>
            <Grid item xs={12} sm={2}>
              <TextField
                name={`storageItems[${index}].rowName`}
                label={t("common:field.storage.rowName")}
                type="text"
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.storageItems?.[index]?.rowName && true}
                helperText={errors?.storageItems?.[index]?.rowName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                name={`storageItems[${index}].numberOfColumns`}
                type="number"
                label={t("common:field.storage.numberOfColumns")}
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: !storageItems[index]?.isCustom
                    ? {
                        value: true,
                        message: t("validation:required"),
                      }
                    : false,
                })}
                error={errors?.storageItems?.[index]?.numberOfColumns && true}
                helperText={errors?.storageItems?.[index]?.numberOfColumns?.message}
                disabled={storageItems[index]?.isCustom}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <TextField
                name={`storageItems[${index}].numberOfLevels`}
                label={t("common:field.storage.numberOfLevels")}
                type="number"
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: !storageItems[index]?.isCustom
                    ? {
                        value: true,
                        message: t("validation:required"),
                      }
                    : false,
                })}
                error={errors?.storageItems?.[index]?.numberOfLevels && true}
                helperText={errors?.storageItems?.[index]?.numberOfLevels?.message}
                disabled={storageItems[index]?.isCustom}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <TextField
                name={`storageItems[${index}].numberOfSpaces`}
                label={t("common:field.storage.numberOfSpaces")}
                type="number"
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: !storageItems[index]?.isCustom
                    ? {
                        value: true,
                        message: t("validation:required"),
                      }
                    : false,
                })}
                error={errors?.storageItems?.[index]?.numberOfSpaces && true}
                helperText={errors?.storageItems?.[index]?.numberOfSpaces?.message}
                disabled={storageItems[index]?.isCustom}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Controller
                name={`storageItems[${index}].isCustom`}
                defaultValue={false}
                render={props => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...props}
                        checked={props.value}
                        onChange={(event, checked) => props.onChange(checked)}
                      />
                    }
                    label={t("warehouse.uniqueStorage")}
                    labelPlacement="end"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={1}>
              <Tooltip title={t("common:button.delete").toString()}>
                <IconButton
                  style={{ height: 48 }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ))}

      <Box display="flex" justifyContent="center">
        <Tooltip title={t("common:button.add").toString()}>
          <Button
            onClick={event => {
              event.stopPropagation();
              append({
                numberOfStorage: 0,
                storageType: "",
                storageNameBase: "",
                storageNameStartingNumber: 0,
                isCustom: false,
              });
            }}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default StorageItemsForm;
