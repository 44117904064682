import { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";

import { Close, FilterList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { getVehicleGpsByDay } from "shared/network/vehicle.api";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { GpsCoordinate } from "shared/types";
import { format } from "date-fns";

type Props = {
  vehicleId: number | undefined;
  localDate?: Date | string | undefined;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
const VehicleGps = ({ vehicleId, localDate, open, setOpen }: Props) => {
  const { t } = useTranslation();

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState<GpsCoordinate[]>([]);
  const [date, setDate] = useState(new Date());

  async function onSubmit(vehicleId: number | undefined, localDate: Date | string | undefined) {
    setLoading(true);
    try {
      const { data } = await getVehicleGpsByDay(vehicleId, localDate);
      setCoordinates(data.items);
    } catch (err) {}
    setLoading(false);
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{t("vehicle.gps.getGps")}</Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Box display="flex" gridGap={8} alignItems="center" justifyContent="center">
            <KeyboardDatePicker
              name="localDate"
              format="yyyy. MM. dd."
              label={t("tour.date")}
              value={date ?? new Date()}
              onChange={date => setDate(date ?? new Date())}
              size="small"
              fullWidth
              margin="none"
              style={{ maxWidth: 250 }}
              animateYearScrolling
            />
            <Button
              onClick={() => onSubmit(vehicleId, date)}
              variant="contained"
              color="primary"
              startIcon={<FilterList />}
            >
              {t("package.filter")}
            </Button>
          </Box>
          <MapContainer
            center={[47, 20]}
            zoom={5}
            style={{ height: 380, width: 800, margin: "30px 10px" }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {coordinates?.map((coordinate, index) => (
              <Marker position={[coordinate.latitude, coordinate.longitude]}>
                <Popup>
                  <Box display="flex" gridGap={8}>
                    <Typography style={{ fontWeight: "bold" }}>{index + 1}.</Typography>
                    <Typography>
                      {format(new Date(coordinate.dateTime), "yyyy. MM. dd. HH:mm:ss")}
                    </Typography>
                  </Box>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VehicleGps;
