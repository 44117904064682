import * as React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const SplashScreen = () => {
  return (
    <Box
      style={{
        height: "100vh",
        backgroundPosition: "top",
        backgroundAttachment: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box pb={2}>
        <Typography
          variant="h5"
          style={{
            fontFamily: "'Montserrat Alternates', sans-serif",
            fontWeight: "bold",
            fontSize: "64px",
            color: "gray",
            textAlign: "center",
            marginBottom: 12,
          }}
        >
          arrakis
        </Typography>
      </Box>
      <Box>
        <CircularProgress size={48} style={{ color: "white" }} />
      </Box>
    </Box>
  );
};

export default SplashScreen;
