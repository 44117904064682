import { Box, Button, Typography } from "@material-ui/core";
import { AddCircle, Map } from "@material-ui/icons";
import Layout from "components/layout/Layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { getDeliveryPriceList, MatrixType } from "shared/network/deliveryprice.api";
import CreateZoneModal from "./components/CompanyZone/CreateZoneModal";
import DeliveryPriceTable from "./components/CompanyZone/DeliveryPriceTable";
import { getCompanyById } from "shared/network/company.api";

type Params = {
  id: string;
};

export type Zone = {
  id?: number;
  name: string;
  companyId: number;
  isActive?: boolean;
  type?: MatrixType;
};
export type DeliveryPrice = {
  id?: number;
  fromZone?: number | string;
  weightFrom: string;
  weightTo: string;
  volumeFrom: string;
  volumeTo: string;
  price: string;
  type: MatrixType;
};

const CompanyZone = ({ match }: RouteComponentProps<Params>) => {
  const id = match.params.id;

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const matrixType = urlParams.get("type") as MatrixType;

  const { t } = useTranslation();
  const [createZoneOpen, setCreateZoneOpen] = useState(false);

  const deliveryPriceListQuery = useQuery(["deliveryPriceListQuery", id, matrixType], async () => {
    if (matrixType === "SHIPPER" || matrixType === "STORE") {
      const { data } = await getDeliveryPriceList(id, matrixType);
      return data;
    }
    return Promise.reject();
  });

  function groupBy(arr: any[] = [], criteria: string) {
    const newObj = arr?.reduce(function (acc, currentValue) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);

      return acc;
    }, {});
    return newObj;
  }

  const weightMatrix: DeliveryPrice[][] = Object.entries(
    groupBy(deliveryPriceListQuery.data?.deliveryPriceListWeight, "weightFrom"),
  )
    .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
    .map((value: any) => value[1]);

  const volumeMatrix: DeliveryPrice[][] = Object.entries(
    groupBy(deliveryPriceListQuery.data?.deliveryPriceListVolume, "volumeFrom"),
  )
    .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
    .map((value: any) => value[1]);

  const companyQuery = useQuery("companyQueryCompanyDetails", async () => {
    const { data } = await getCompanyById(Number(id));
    return data;
  });
  const company = companyQuery.data?.company;

  const showPageContent =
    (company?.isShipper && matrixType === "SHIPPER") ||
    (company?.isShopKeeper && matrixType === "STORE");

  return (
    <Layout
      title={
        matrixType === "STORE"
          ? t("zoneZipList.storeZones")
          : matrixType === "SHIPPER"
          ? t("zoneZipList.shipperZones")
          : ""
      }
      maxWidth="xl"
      actionButton={
        showPageContent && (
          <Box gridGap={8} display="flex">
            <Button
              startIcon={<Map />}
              variant="outlined"
              color="primary"
              component={Link}
              to={`/company/${id}/zones/list?type=${matrixType}`}
            >
              {t("companyZone.buttons.zoneList")}
            </Button>
            <Button
              startIcon={<AddCircle />}
              variant="contained"
              color="primary"
              onClick={() => setCreateZoneOpen(true)}
            >
              {t("companyZone.buttons.newZone")}
            </Button>
            <CreateZoneModal
              id={id}
              open={createZoneOpen}
              setOpen={setCreateZoneOpen}
              matrixType={matrixType}
            />
          </Box>
        )
      }
    >
      <Box>
        {showPageContent ? (
          <>
            <DeliveryPriceTable
              companyId={id}
              data={weightMatrix}
              type="WEIGHT"
              matrixType={matrixType}
            />
            <DeliveryPriceTable
              companyId={id}
              data={volumeMatrix}
              type="VOLUME"
              matrixType={matrixType}
            />
          </>
        ) : (
          <Typography>Nincs típus megadva az oldal linkjében</Typography>
        )}
      </Box>
    </Layout>
  );
};

export default CompanyZone;
