import axios from "config/axios";
import { Article, ArticleSubjectType, GenericListResponse, GenericResponse } from "shared/types";

const ENDPOINT = "/article";

export const saveArticle = (data: Partial<Article>) =>
  axios.post<GenericResponse<Article>>(ENDPOINT, data);

export const listArticles = () => axios.get<GenericListResponse<Article>>(ENDPOINT);

export const listBySubject = (subjectType: ArticleSubjectType) =>
  axios.get<GenericListResponse<Article>>(`${ENDPOINT}/subject/${subjectType}`);

export const getTos = () => axios.get<GenericResponse<Article>>(`${ENDPOINT}/tos`);

export const getGdpr = () => axios.get<GenericResponse<Article>>(`${ENDPOINT}/gdpr`);

export const getArticleById = (id: number) =>
  axios.get<GenericResponse<Article>>(`${ENDPOINT}/${id}`);

export const deleteArticle = (articleId: number) =>
  axios.delete(`${ENDPOINT}?articleId=${articleId}`);

export const modifyArticle = (article: Partial<Article>) => axios.post(ENDPOINT, article);
