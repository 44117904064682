import * as L from "leaflet";
import Typography from "@material-ui/core/Typography";
import MarkerIcon from "views/tour/components/marker.png";
import { Marker, Popup } from "react-leaflet";
import { makeStyles } from "@material-ui/core";

type Props = {
  status: any;
  number: number;
};

const useStyles = makeStyles(
  () => ({
    divIcon: {
      background: "transparent",
      border: "none",
    },
    markerContainer: {
      position: "absolute",
      top: "4px",
      fontSize: "12px",
      width: "25px",
      textAlign: "center",
      fontWeight: "bold",
    },
    marker: {
      position: "relative",
      fontSize: "12px",
      width: "25px",
      textAlign: "center",
    },
  }),
  { name: "TourDetails" },
);

const TrackingMapMarker = ({ number, status }: Props) => {
  const classes = useStyles();

  // @ts-ignore
  L.NumberedDivIcon = L.Icon.extend({
    options: {
      iconUrl: MarkerIcon,
      number: "",
      shadowUrl: null,
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      popupAnchor: [0, -33],
      className: classes.divIcon,
    },
    createIcon: function () {
      var div = document.createElement("div");
      var img = this._createImg(this.options["iconUrl"]);
      var numdiv = document.createElement("div");
      numdiv.setAttribute("class", classes.markerContainer);
      img.setAttribute("class", classes.marker);
      numdiv.innerHTML = this.options["number"] || "";
      div.appendChild(img);
      div.appendChild(numdiv);
      this._setIconStyles(div, "icon");
      return div;
    },
  });

  return (
    <Marker
      position={[status.latitude || 0, status.longitude || 0]}
      icon={
        // @ts-ignore
        new L.NumberedDivIcon({
          number: number,
        })
      }
    >
      <Popup>
        <Typography key={status.id}>
          <b>{number}.</b>
        </Typography>
      </Popup>
    </Marker>
  );
};

export default TrackingMapMarker;
