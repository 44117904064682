import { Box, Container, Link, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Link as RouterLink } from "components/router";
import { palette } from "config/theme";
import BlueIcon from "pictures/blue-logo.svg";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getSystemProperties } from "shared/network/property.api";

type Props = {
  children: React.ReactNode;
  title?: string;
  textStyle?: React.CSSProperties;
  maxWidth?: "xl" | "lg" | "md" | "sm" | "xs";
};

const useStyles = makeStyles(
  () => ({
    title: {
      fontSize: 50,
      textAlign: "center",
      margin: 30,
      fontFamily: "'Montserrat Alternates', sans-serif",
      fontWeight: "bold",
    },
  }),
  { name: "UnauthorizedLayout" },
);

const UnauthorizedLayout = ({ children, title, textStyle, maxWidth = "lg" }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const query = useQuery("versionUnauthorizedLayout", async () => {
    const { data } = await getSystemProperties();
    return data.items;
  });
  const version = query.data?.find(property => property.name === "version")?.value;

  return (
    <main>
      <Container maxWidth={maxWidth}>
        <Box className={clsx(textStyle, classes.title)}>
          <Box display="flex" justifyContent="center" alignItems="center" padding="24px 12px">
            <img
              src={BlueIcon}
              alt=""
              style={{
                width: 100,
                height: 100,
                marginRight: 20,
              }}
            />
            <Box display="flex" justifyContent="center" alignItems="center">
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 48,
                  fontWeight: "bold",
                  color: palette.main,
                }}
              >
                {t("common:appNameUpper")}
              </span>
            </Box>
          </Box>
        </Box>
        <Typography style={{ fontSize: 24, textAlign: "center" }}>{title}</Typography>
        <Box
          style={{
            padding: 24,
          }}
        >
          {children}
        </Box>
        <footer
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            textAlign: "center",
            color: "gray",
          }}
        >
          {t("login.allRightsReserved", {
            date:
              new Date().getFullYear() === 2021
                ? new Date().getFullYear()
                : `2021 - ${new Date().getFullYear()}`,
            version: version,
          })}
          <div>
            <Link
              component={RouterLink}
              to="/aszf"
              style={{
                textDecoration: "underline",
                color: "inherit",
              }}
            >
              {t("tos")}
            </Link>
            {", "}
            <Link
              component={RouterLink}
              to="/gdpr"
              style={{
                textDecoration: "underline",
                color: "inherit",
              }}
            >
              {t("gdpr")}
            </Link>
          </div>
        </footer>
      </Container>
    </main>
  );
};

export default UnauthorizedLayout;
