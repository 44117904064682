import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import PageLoading from "components/PageLoading";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { modifyDeliveryZone } from "shared/network/deliveryzone.api";
import { Zone } from "views/company/CompanyZone";

type Props = {
  companyId: string;
  open: Zone | null;
  setOpen: Dispatch<SetStateAction<Zone | null>>;
};

type FormValues = {
  name: string;
  isActive: boolean;
};

const ModifyZoneModal = ({ companyId, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>();

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await modifyDeliveryZone({
        ...values,
        id: open?.id,
        companyId: parseInt(companyId),
      });
      setOpen(null);
      queryClient.invalidateQueries("companyZoneQuery");
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: "zóna",
        }),
        { variant: "success" },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.update.failure", {
          subject: "zóna",
        }),
        { variant: "error" },
      );
    }
    setLoading(false);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <PageLoading open={loading} />
        <Dialog open={!!open} onClose={() => setOpen(null)} maxWidth="xs" fullWidth disablePortal>
          <DialogTitle>{t("companyZone.modifyTitle")}</DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              label={t("companyZone.formValues.name")}
              defaultValue={open?.name}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={form.register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!form.errors?.name}
              helperText={form.errors?.name?.message}
            />
            <Controller
              name="isActive"
              id="isActive"
              defaultValue={open?.isActive}
              render={props => (
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(_, checked) => {
                        props.onChange(checked);
                      }}
                    />
                  }
                  label={t("companyZone.formValues.isActive")}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setOpen(null)}>
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t("common:button.ok")}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};
export default ModifyZoneModal;
