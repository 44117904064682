import * as React from "react";
import Alert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { enqueueSnackbar } from "shared/reducers/application";
import { PASSWORD_POLICY_REGEX } from "shared/util/validation";
import { forgottenPasswordFinish } from "shared/network/user.api";
import UnauthorizedLayout from "components/layout/UnauthorizedLayout";
import Button from "components/form/Button";
import PasswordTextField from "components/form/PasswordTextField";

type FormValues = {
  newPassword: string;
  newPasswordConfirm: string;
};

const ResetPassword = ({ history, match: { params } }: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { handleSubmit, register, errors, watch } = useForm<FormValues>();
  const newPassword = watch("newPassword");

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await forgottenPasswordFinish({
        newPassword: values.newPassword,
        newPassword2: values.newPasswordConfirm,
        token: params.id,
      });
      dispatch(
        enqueueSnackbar({
          message: t("notification:update.success", {
            subject: t("forgottenPassword.password"),
          }),
          options: { variant: "success" },
        }),
      );
      history.replace("/login");
    } catch (e: any) {
      setError(e?.data?.status);
    }
    setLoading(false);
  }

  return (
    <UnauthorizedLayout title={t("forgottenPassword.newTitle")}>
      {error && (
        <Alert severity="error">
          {t([`forgottenPassword.resetError.${error}`, "forgottenPassword.resetError.failure"])}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" maxWidth="600px" flexDirection="column" margin="auto">
          <PasswordTextField
            name="newPassword"
            label={t("common:field.profile.newPassword")}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
              validate: value => {
                if (!value.match(PASSWORD_POLICY_REGEX)) {
                  return t("validation:passwordPolicy").toString();
                }
                if (value !== newPassword) {
                  return t("validation:passwordMismatch").toString();
                }
              },
            })}
            error={errors.newPassword && true}
            helperText={errors.newPassword?.message}
          />
          <PasswordTextField
            name="newPasswordConfirm"
            label={t("common:field.profile.newPasswordConfirm")}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
              validate: value => {
                if (!value.match(PASSWORD_POLICY_REGEX)) {
                  return t("validation:passwordPolicy").toString();
                }
                if (value !== newPassword) {
                  return t("validation:passwordMismatch").toString();
                }
              },
            })}
            error={errors.newPasswordConfirm && true}
            helperText={t("validation:passwordPolicy")}
          />
        </Box>
        <Button
          loading={loading}
          type="submit"
          style={{
            marginTop: 14,
          }}
        >
          {t("common:button.save")}
        </Button>
      </form>
    </UnauthorizedLayout>
  );
};
export default ResetPassword;
