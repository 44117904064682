import { Box, Checkbox, FormControlLabel, FormHelperText, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import UnauthorizedLayout from "components/layout/UnauthorizedLayout";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { SliceStatus } from "shared/types";
import { Link as RouterLink } from "components/router";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import { PASSWORD_POLICY_REGEX } from "shared/util/validation";
import { palette } from "config/theme";
import { getUserByToken, signUpFinish } from "shared/network/user.api";
import Button from "components/form/Button";
import PasswordTextField from "components/form/PasswordTextField";

type FormFields = {
  password: string;
  passwordConfirmation: string;
  tosConsent: boolean;
};

type RouteParams = {
  token: string;
};

const REDIRECT_TIME = 10000;

const SignUpFinish = () => {
  const { t } = useTranslation();
  const { token } = useParams<RouteParams>();

  const history = useHistory();

  const { register, errors, handleSubmit, watch, control } = useForm<FormFields>();

  const { password, passwordConfirmation } = watch(["password", "passwordConfirmation"]);

  const [status, setStatus] = React.useState<SliceStatus>(SliceStatus.Idle);

  const userQuery = useQuery(
    ["getUserByTokenQuery", token],
    async () => {
      const { data } = await getUserByToken(token);
      return data.item;
    },
    {
      enabled: !isEmpty(token),
    },
  );

  React.useEffect(() => {
    if (status === SliceStatus.Success) {
      setTimeout(() => {
        history.replace("/login");
      }, REDIRECT_TIME);
    }
  }, [history, status]);

  async function onSubmit(values: FormFields) {
    setStatus(SliceStatus.Loading);
    try {
      await signUpFinish({
        password: values.password,
        token: token,
      });
      setStatus(SliceStatus.Success);
    } catch {
      setStatus(SliceStatus.Error);
    }
  }

  return (
    <UnauthorizedLayout title={t("signUp.finish.title")}>
      {status === SliceStatus.Success && (
        <>
          <Alert severity="success">
            {t("signUp.finish.success", { time: REDIRECT_TIME / 1000 })}
          </Alert>
          <Box p={1}>
            <Link component={RouterLink} to="/login">
              {t("common:button.login")}
            </Link>
          </Box>
        </>
      )}
      {status === SliceStatus.Error && <Alert severity="error">{t("signUp.finish.error")}</Alert>}
      {userQuery.isError && (
        <>
          <Alert severity="error">{t("signUp.finish.invalidToken")}</Alert>
          <Button variant="text" onClick={() => history.push("/login")} style={{ marginTop: 10 }}>
            {t("common:button.backToLogin")}
          </Button>
        </>
      )}
      {status !== SliceStatus.Success && !userQuery.isError && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <PasswordTextField
              id="password"
              name="password"
              type="password"
              label={t("common:field.password")}
              style={{ maxWidth: 400 }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
                validate: value => {
                  if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                    return t("validation:passwordPolicy").toString();
                  }
                  if (value !== passwordConfirmation) {
                    return t("validation:passwordMismatch").toString();
                  }
                },
              })}
              error={errors.password && true}
              helperText={t("validation:passwordPolicy")}
            />
            <PasswordTextField
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="passwordConfirmation"
              label={t("common:field.passwordConfirmation")}
              style={{ maxWidth: 400 }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
                validate: value => {
                  if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                    return t("validation:passwordPolicy").toString();
                  }
                  if (value !== password) {
                    return t("validation:passwordMismatch").toString();
                  }
                },
              })}
              error={errors.passwordConfirmation && true}
              helperText={errors.passwordConfirmation?.message}
            />
            <Controller
              name="tosConsent"
              id="tosConsent"
              control={control}
              defaultValue={false}
              rules={{
                required: {
                  value: true,
                  message: t("validation:requiredConsent"),
                },
              }}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      required={true}
                      checked={props.value}
                      onChange={(_, checked) => {
                        props.onChange(checked);
                      }}
                    />
                  }
                  style={{ margin: 0 }}
                  label={
                    <>
                      {t("signUp.finish.tos1")}
                      <Link
                        component={RouterLink}
                        to="/aszf"
                        style={{
                          color: palette.lightMain,
                          fontWeight: 500,
                          textDecoration: "underline",
                        }}
                      >
                        {t("signUp.finish.tos2")}
                      </Link>
                    </>
                  }
                />
              )}
            />
            {!!errors?.tosConsent && (
              <FormHelperText style={{ color: "red" }}>
                {errors?.tosConsent?.message}
              </FormHelperText>
            )}
          </Box>

          <Box display="flex" pt={2} justifyContent="space-evenly">
            <Button variant="text" onClick={() => history.push("/login")}>
              {t("common:button.backToLogin")}
            </Button>
            <Button loading={status === SliceStatus.Loading} type="submit">
              {t("common:button.submit")}
            </Button>
          </Box>
        </form>
      )}
    </UnauthorizedLayout>
  );
};

export default SignUpFinish;
