import Layout from "components/layout/Layout";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createCompany } from "shared/network/company.api";
import { SliceStatus } from "shared/types";
import CompanyForm, { CompanyFormFields } from "./components/CompanyForm";

const CompanyCreate = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(SliceStatus.Idle);

  const addressChecked = (addressChecked: boolean) => {
    setChecked(addressChecked);
  };

  async function onCompanySubmit(values: CompanyFormFields) {
    setStatus(SliceStatus.Loading);
    try {
      await createCompany({
        company: {
          ...values.company,
          isShopKeeper: values.isShop,
          postAddress: checked ? values.company.billingAddress : values.company.postAddress,
        },
        shop: values.isShop ? values.shop : undefined,
        shopAddressList: values.isShop ? values.shopAddressList : undefined,
        isDefaultDeliveryToWarehouse: values.isDefaultDeliveryToWarehouse,
      });
      history.push("/company");
      setStatus(SliceStatus.Success);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("company.create.subject"),
        }),
        { variant: "success" },
      );
    } catch (err) {
      setStatus(SliceStatus.Error);
      if ((err as any).data.status === "COMPANY_ALREADY_EXISTS") {
        enqueueSnackbar(t("company.create.COMPANY_ALREADY_EXISTS"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("company.create.subject"),
          }),
          { variant: "error" },
        );
      }
    }
  }

  const methods = useForm<CompanyFormFields>();

  return (
    <Layout title={t("company.create.title")} maxWidth="lg">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onCompanySubmit)}>
          <CompanyForm
            loading={status === SliceStatus.Loading}
            addressChecked={addressChecked}
            type="CREATE"
          />
        </form>
      </FormProvider>
    </Layout>
  );
};

export default CompanyCreate;
