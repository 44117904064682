import axios from "config/axios";
import { Address, GenericResponse } from "shared/types";

const ENDPOINT = "/address";

export const getAddressById = (id: number) =>
  axios.get<GenericResponse<Address>>(`${ENDPOINT}?id=${id}`);

export const coordinatesDraggableMarker = (
  addressId: number | undefined,
  latitude: number | undefined,
  longitude: number | undefined,
) =>
  axios.put(`${ENDPOINT}/coordinates`, {
    addressId,
    latitude,
    longitude,
  });
