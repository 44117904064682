import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { Link as RouterLink } from "components/router";
import { useTranslation } from "react-i18next";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ExploreRoundedIcon from "@material-ui/icons/ExploreRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import clsx from "clsx";
import menuBarStyles from "./styles";
import MenuBarItem from "./MenuBarItem";
import BusinessIcon from "@material-ui/icons/Business";
import WhiteIcon from "pictures/white-logo.svg";
import { useHistory } from "react-router";
import DescriptionIcon from "@material-ui/icons/Description";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import CompanyChooser from "components/CompanyChooser";
import usePermissions from "shared/hooks/usePermissions";
import { Equalizer, LocalAtm, Settings } from "@material-ui/icons";

const MenuBar = () => {
  const { t } = useTranslation();

  const classes = menuBarStyles();
  const theme = useTheme();
  const history = useHistory();

  const { user, isAdmin, checkPermissions } = usePermissions();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/dashboard")}
            >
              <img src={WhiteIcon} alt="" style={{ width: 36, height: 36 }} />
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  style={{
                    color: "#fff",
                    marginLeft: 16,
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  {t("common:appNameUpper")}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <CompanyChooser />
              <Tooltip title={t("profile.title").toString()}>
                <Link
                  component={RouterLink}
                  to={`/profile/${user?.id}`}
                  underline="none"
                  style={{ color: "unset" }}
                >
                  <ListItem button>
                    <ListItemIcon style={{ minWidth: "unset", color: "#fff" }}>
                      <PersonRoundedIcon />
                    </ListItemIcon>
                  </ListItem>
                </Link>
              </Tooltip>
              <Tooltip title={t("logout.title").toString()}>
                <Link
                  component={RouterLink}
                  to="/logout"
                  underline="none"
                  style={{ color: "unset" }}
                >
                  <ListItem button>
                    <ListItemIcon style={{ minWidth: "unset", color: "#fff" }}>
                      <ExitToAppRoundedIcon />
                    </ListItemIcon>
                  </ListItem>
                </Link>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <MenuBarItem
            icon={<HomeRoundedIcon />}
            to="/dashboard"
            open={open}
            text={t("common:mainPage")}
          />
          {isAdmin && (
            <>
              <MenuBarItem
                icon={<MenuBookRoundedIcon />}
                to="/audit-log"
                open={open}
                text={t("auditLog.title")}
              />
              <MenuBarItem
                icon={<DescriptionIcon />}
                to="/article"
                open={open}
                text={t("article.title")}
              />
              <MenuBarItem
                icon={<Settings />}
                to="/property"
                open={open}
                text={t("property.title")}
              />
              <MenuBarItem icon={<Equalizer />} to="/report" open={open} text={t("report.title")} />
            </>
          )}
          {isAdmin && (
            <>
              <MenuBarItem
                icon={<PeopleAltRoundedIcon />}
                to="/user-list"
                open={open}
                text={t("userList.title")}
              />
              <MenuBarItem
                icon={<BusinessIcon />}
                to="/company"
                open={open}
                text={t("company.list.title")}
              />
              <MenuBarItem
                icon={<LocalAtm />}
                to="/shipping-price"
                open={open}
                text={t("shippingPrice.title")}
              />
              <MenuBarItem
                icon={<MonetizationOnIcon />}
                to="/deliveryextracharge"
                open={open}
                text={"Extra költségek"}
              />
            </>
          )}
          {(isAdmin ||
            checkPermissions({
              requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
            })) && (
            <>
              <MenuBarItem
                icon={<HomeWorkRoundedIcon />}
                to="/warehouse"
                open={open}
                text={t("warehouse.list.title")}
              />
            </>
          )}
          {checkPermissions({
            requestedPermissions: [
              "SHOPKEEPER",
              "SHIPORGANIZER",
              "WAREHOUSEKEEPER",
              "WAREHOUSEKEEPER_ADMIN",
            ],
          }) && (
            <>
              <MenuBarItem
                icon={<ViewModuleIcon />}
                to="/package"
                open={open}
                text={t("package.list")}
              />
            </>
          )}
          {checkPermissions({
            requestedPermissions: ["SHIPORGANIZER", "SHIPPER"],
          }) && (
            <>
              <MenuBarItem
                icon={<LocalShippingRoundedIcon />}
                to="/vehicle"
                open={open}
                text={t("vehicle.list.title")}
              />
            </>
          )}
          {checkPermissions({
            requestedPermissions: ["SHIPORGANIZER"],
          }) && (
            <>
              <MenuBarItem
                icon={<ExploreRoundedIcon />}
                to="/tour"
                open={open}
                text={t("tour.title")}
              />
            </>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default MenuBar;
