import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import PropertySettings from "views/property/PropertySettings";

const PropertyRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={PropertySettings} />
    </Switch>
  );
};

export default PropertyRoutes;
