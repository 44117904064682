import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Warehouse } from "shared/types";
import StorageItemsForm, { StorageFormValues } from "components/storage/StorageItemsForm";
import { FormProvider, useForm } from "react-hook-form";
import Button from "components/form/Button";
import { useMutation } from "react-query";
import { createMultipleStorage } from "shared/network/storage.api";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

type Props = {
  warehouse: Warehouse;
  onSubmitSuccess?: () => void;
};

const StorageItemList = ({ warehouse, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);

  const form = useForm<StorageFormValues>({
    defaultValues: {
      storageItems: [
        {
          rowName: "",
          numberOfColumns: "",
          numberOfLevels: "",
          numberOfSpaces: "",
        },
      ],
    },
  });

  const mutation = useMutation<any, any, StorageFormValues>(
    async values => {
      setLoading(true);
      if (values.storageItems?.length) {
        const data = values.storageItems.map(storage => ({
          rowName: storage.rowName,
          numberOfColumns: Number.parseInt(storage.numberOfColumns, 10),
          numberOfLevels: Number.parseInt(storage.numberOfLevels, 10),
          numberOfSpaces: Number.parseInt(storage.numberOfSpaces, 10),
          warehouseId: warehouse.id,
          isCustom: storage.isCustom,
        }));
        return await createMultipleStorage(data);
      }
    },
    {
      onSuccess: () => {
        setLoading(false);
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("storage.subject"),
          }),
          { variant: "success" },
        );
        setOpen(false);
        onSubmitSuccess?.();
      },
      onError: () => {
        setLoading(false);
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("storage.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <>
      <Tooltip title={t("storage.newTitle").toString()}>
        <IconButton color="primary" onClick={() => setOpen(true)} size="small">
          <AddIcon />
        </IconButton>
      </Tooltip>
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit: form.handleSubmit(values => mutation.mutate(values)),
          }}
          maxWidth="md"
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              {t("storage.newTitle")}
              <Tooltip title={t("common:button.close").toString()}>
                <IconButton color="primary" onClick={() => setOpen(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box minWidth={300}>
              <StorageItemsForm />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="submit" loading={loading}>
              {t("common:button.create")}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default StorageItemList;
