import { Box, IconButton, Typography } from "@material-ui/core";
import { AddCircle, Edit } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getDeliveryZoneListByCompanyId } from "shared/network/deliveryzone.api";
import { DeliveryPrice, Zone } from "views/company/CompanyZone";
import CreateDeliveryPriceRange from "./CreateDeliveryPriceRange";
import DeliveryPriceRow from "./DeliveryPriceRow";
import ModifyZoneModal from "./ModifyZoneModal";
import { MatrixType } from "shared/network/deliveryprice.api";

type Props = {
  companyId: string;
  data: DeliveryPrice[][];
  type: "WEIGHT" | "VOLUME";
  matrixType: MatrixType;
};

export type PriceRow = {
  id: number;
  weightFrom?: string;
  weightTo?: string;
  volumeFrom?: string;
  volumeTo?: string;
  priceEntries: DeliveryPrice[];
};

const DeliveryPriceTable = ({ companyId, data, type, matrixType }: Props) => {
  const { t } = useTranslation();
  const [isEditingId, setEditingId] = useState<number | null | undefined>(null);
  const [openCreateRow, setOpenCreateRow] = useState(false);
  const [modifyZoneOpen, setModifyZoneOpen] = useState<Zone | null>(null);

  const companyZoneQuery = useQuery(["companyZoneQuery", companyId], async () => {
    const { data } = await getDeliveryZoneListByCompanyId(companyId, matrixType);
    return data.items;
  });

  const priceData = data.map((entry, index) => {
    return {
      id: index,
      weightFrom: type === "WEIGHT" ? entry[0].weightFrom : undefined,
      weightTo: type === "WEIGHT" ? entry[0].weightTo : undefined,
      volumeFrom: type === "VOLUME" ? entry[0].volumeFrom : undefined,
      volumeTo: type === "VOLUME" ? entry[0].volumeTo : undefined,
      priceEntries: entry,
    };
  });

  return (
    <Box display="flex" flexDirection="column" overflow="auto" pb={2}>
      <ModifyZoneModal companyId={companyId} open={modifyZoneOpen} setOpen={setModifyZoneOpen} />
      <Box
        display="flex"
        width={((companyZoneQuery.data?.length || 0) + 1) * 180}
        marginLeft="auto"
        marginRight="auto"
      >
        <Box
          display="flex"
          alignItems="center"
          height={48}
          width={180}
          paddingX={1}
          borderBottom="1px solid grey"
        >
          <Typography style={{ fontWeight: "bold" }}>
            {type === "WEIGHT"
              ? t("companyZone.deliveryPriceType.weight")
              : t("companyZone.deliveryPriceType.volume")}
          </Typography>
        </Box>
        {companyZoneQuery.data?.map(zone => (
          <Box
            key={zone.id}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height={48}
            width={180}
            paddingX={1}
            borderBottom="1px solid grey"
            gridGap={4}
          >
            <Typography style={{ fontWeight: "bold" }} noWrap>
              {zone.name} (Ft)
            </Typography>
            <IconButton color="primary" onClick={() => setModifyZoneOpen(zone)} size="small">
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        ))}
        <Box width={80} paddingX={1} borderBottom="1px solid grey" />
      </Box>
      {priceData.map((row, index) => (
        <DeliveryPriceRow
          key={index}
          type={type}
          row={row}
          zoneList={companyZoneQuery.data}
          isEditingId={isEditingId}
          setEditingId={setEditingId}
          index={index}
          companyId={companyId}
          rowData={priceData}
          matrixType={matrixType}
        />
      ))}
      {!openCreateRow ? (
        <Box margin="auto">
          <IconButton onClick={() => setOpenCreateRow(true)}>
            <AddCircle />
          </IconButton>
        </Box>
      ) : (
        <CreateDeliveryPriceRange
          companyId={companyId}
          type={type}
          zoneList={companyZoneQuery.data}
          setOpenCreateRow={setOpenCreateRow}
          rowData={priceData}
          matrixType={matrixType}
        />
      )}
    </Box>
  );
};
export default DeliveryPriceTable;
