import Layout from "components/layout/Layout";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { AUDITLOG_TYPE } from "config/constants";
import { getAuditLog } from "shared/network/audit-log.api";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Button,
  Divider,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Controller, useForm } from "react-hook-form";
import { FilterList } from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import { format } from "date-fns";

type AuditLogFilter = {
  createdOn: string;
  user: {
    firstName: string;
    lastName: string;
  };
  type: string;
  message: string;
  status: string;
};

const AuditLogPage = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);

  const auditLogQuery = useQuery(["auditLogQuery", page, filter], async () => {
    const { data } = await getAuditLog(page, 10, filter);
    return data;
  });

  const { register, handleSubmit, control, setValue } = useForm<AuditLogFilter>({
    shouldUnregister: false,
  });

  const onSubmit = (values: AuditLogFilter) => {
    setFilter("");
    setPage(0);
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        if (key === "user") {
          Object.entries(value).forEach(([key2, value2]) => {
            setFilter(state => `${state}${state ? ";" : ""}user.${key2}:${value2}`);
          });
        } else {
          setFilter(state => `${state}${state ? ";" : ""}${key}:${value}`);
        }
      }
    });
    auditLogQuery.refetch();
  };

  return (
    <Layout title={t("auditLog.title")}>
      {auditLogQuery.isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ overflow: "auto hidden" }}>
          <Box style={{ minWidth: 1000 }}>
            <Grid container alignItems="center">
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("auditLog.columns.creationTime")}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("auditLog.columns.userName")}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("auditLog.columns.message")}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>{t("auditLog.columns.type")}</Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("auditLog.columns.status")}
                </Typography>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 12 }}>
              <Grid container spacing={2}>
                <Grid item xs={2} style={{ padding: 8 }} />
                <Grid container spacing={2} item xs={2} style={{ paddingTop: 0, padding: 8 }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      margin="none"
                      name="user.lastName"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      label={t("user.properties.lastName")}
                      inputRef={register}
                      InputProps={{
                        style: { paddingRight: 6 },
                        endAdornment: (
                          <InputAdornment position="end" style={{ marginLeft: 6 }}>
                            <IconButton onClick={() => setValue("user.lastName", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      margin="none"
                      name="user.firstName"
                      placeholder="Szűrés"
                      InputLabelProps={{ shrink: true }}
                      label={t("user.properties.firstName")}
                      inputRef={register}
                      InputProps={{
                        style: { paddingRight: 6 },
                        endAdornment: (
                          <InputAdornment position="end" style={{ marginLeft: 6 }}>
                            <IconButton onClick={() => setValue("user.firstName", "")} size="small">
                              <Close style={{ color: "darkgray" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ padding: 8 }}>
                  <TextField
                    margin="none"
                    name="message"
                    placeholder="Szűrés"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    InputProps={{
                      style: { paddingRight: 6 },
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: 6 }}>
                          <IconButton onClick={() => setValue("message", "")} size="small">
                            <Close style={{ color: "darkgray" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <Controller
                    control={control}
                    name="type"
                    defaultValue={""}
                    render={props => (
                      <TextField {...props} margin="none" select InputLabelProps={{ shrink: true }}>
                        <MenuItem value="">{t("auditLog.noFilter")}</MenuItem>
                        {Object.entries(AUDITLOG_TYPE).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {key}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <TextField
                    margin="none"
                    name="status"
                    placeholder="Szűrés"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    InputProps={{
                      style: { paddingRight: 6 },
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: 6 }}>
                          <IconButton onClick={() => setValue("status", "")} size="small">
                            <Close style={{ color: "darkgray" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<FilterList />}
                    style={{ height: 40 }}
                  >
                    {t("package.filter")}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Divider />
            {!!auditLogQuery.data?.page.content.length &&
              auditLogQuery.data?.page.content?.map((log, index, array) => (
                <Grid
                  key={log.id}
                  container
                  style={{
                    borderBottom: array.length - 1 !== index ? "1px solid #ddd" : "unset",
                    alignItems: "center",
                    margin: "4px 0",
                  }}
                >
                  <Grid item xs={2} style={{ padding: 6 }}>
                    <Typography
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {format(new Date(log.createdOn), "yyyy. MM. dd. HH:mm:ss")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ padding: 6 }}>{log.userName}</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ padding: 6 }}>
                    <Typography
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {log.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 6 }}>
                    <Typography
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {log.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 6 }}>
                    <Typography
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {log.status}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Box>
        </Box>
      )}
      {auditLogQuery.data && auditLogQuery.data?.page.totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "24px 0",
          }}
          count={auditLogQuery?.data?.page.totalPages}
          color="primary"
          page={auditLogQuery.data?.page.number + 1}
          onChange={(e, page) => {
            sessionStorage.setItem("AuditLogPageNumber", JSON.stringify(page - 1));
            setPage(page - 1);
          }}
        />
      )}
    </Layout>
  );
};

export default AuditLogPage;
