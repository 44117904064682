import { Divider, Grid, makeStyles } from "@material-ui/core";
import { ShippingPrice } from "shared/types";
import ShippingPriceModifyModal from "components/shippingPrices/ShippingPriceModifyModal";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  shippingPrice: ShippingPrice;
  modifySubmitSuccess?: () => void;
};

const useStyles = makeStyles(theme => ({
  color: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const ShippingPriceListItem = ({ shippingPrice, modifySubmitSuccess }: Props) => {
  const { t } = useTranslation();

  return (
    <Fragment key={shippingPrice.id}>
      <Divider />
      <Grid
        container
        alignItems="center"
        style={{ minWidth: 1000, paddingTop: 5, paddingBottom: 5 }}
        className={useStyles().color}
      >
        <Grid item xs={3}>
          {shippingPrice.packages.name}
        </Grid>
        <Grid item xs={3}>
          {shippingPrice.packages.trackingNumber}
        </Grid>
        <Grid item xs={2}>
          {shippingPrice.vehicle.licensePlateNumber}
        </Grid>
        <Grid item xs={3}>
          {t("common:number", { num: shippingPrice.netPrice })} {"Ft"}
        </Grid>
        <Grid item xs={1}>
          <ShippingPriceModifyModal
            shippingPrice={shippingPrice}
            onSubmitSuccess={modifySubmitSuccess}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ShippingPriceListItem;
