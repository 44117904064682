import { Box, IconButton, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RelZipZoneEntry } from "shared/types";
import { Zone } from "views/company/CompanyZone";
import CompanyZoneZipEntry from "views/company/components/CompanyZone/CompanyZoneZipEntry";

type Props = {
  zipEntries: RelZipZoneEntry[];
  zone: Zone;
  isEditing: boolean;
  fieldIndex: number;
};

const CompanyZoneColumn = ({ zipEntries, zone, isEditing, fieldIndex }: Props) => {
  const { control } = useFormContext();

  const { append, fields, remove } = useFieldArray({
    control: control,
    name: `zones[${fieldIndex}].zipEntries`,
    keyName: "key",
  });

  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={48}
        width={200}
        paddingX={1}
        marginX={"5px"}
        borderBottom="1px solid grey"
      >
        <Typography style={{ fontWeight: "bold" }}>{zone.name}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" width={200}>
        <Box display="flex" flexDirection="column" overflow="auto">
          {fields.map((zipEntry, index) => {
            return !isEditing ? (
              <Box
                key={zipEntry.key}
                display="flex"
                justifyContent="center"
                style={{
                  height: "40px",
                  marginTop: "8px",
                  border: "1px solid lightgrey",
                  padding: "7px 9px 11px 9px",
                  borderRadius: "25px",
                  width: "125px",
                }}
              >
                <Typography>{`${zipEntry.zipFrom} - ${zipEntry.zipTo}`}</Typography>
              </Box>
            ) : (
              <CompanyZoneZipEntry
                key={zipEntry.key}
                zoneIndex={fieldIndex}
                zipEntryIndex={index}
                remove={remove}
                field={zipEntry}
              />
            );
          })}
        </Box>
      </Box>
      {isEditing && (
        <Box width="100%" display="flex" justifyContent="center">
          <IconButton
            size="small"
            onClick={() => {
              append({ zone: zone, zipFrom: "", zipTo: "" });
            }}
          >
            <AddCircle />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CompanyZoneColumn;
