import MapMarker from "./MapMarker";
import update from "immutability-helper";
import Loading from "components/Loading";
import CheckIcon from "@material-ui/icons/Check";
import VehicleMapMarker from "./VehicleMapMarker";
import { getVehicleById, getVehicleCoordinates } from "shared/network/vehicle.api";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Tour } from "views/tour/components/TourSortingModal";
import { MapContainer, TileLayer } from "react-leaflet";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { modifyTourOrder, tourListByVehicle } from "shared/network/tour.api";
import TourSortingListItem from "views/tour/components/TourSortingListItem";

type Props = {
  id: string;
  date: Date;
  setlicensePlateNumber?: Dispatch<SetStateAction<string>>;
  setCanExport?: Dispatch<SetStateAction<boolean>>;
};
const TourDetailsContent = ({ id, date, setlicensePlateNumber, setCanExport }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [cards, setCards] = useState<Tour[]>([]);
  const [wrongOrder, setWrongOrder] = useState<number[]>([]);
  const [orderChanged, setOrderChanged] = useState(false);

  const vehicleQuery = useQuery(
    ["vehicleTourDetails", id],
    async () => {
      const { data } = await getVehicleById(Number.parseInt(id));
      return data.item;
    },
    { enabled: !!id },
  );

  useEffect(() => {
    if (vehicleQuery.data?.licensePlateNumber) {
      setlicensePlateNumber?.(vehicleQuery.data?.licensePlateNumber);
    }
  }, [setlicensePlateNumber, vehicleQuery.data?.licensePlateNumber]);

  const toursQuery = useQuery(
    ["toursQuery", id, date],
    async () => {
      const { data } = await tourListByVehicle({
        vehicleId: id,
        date: format(date, "yyyy-MM-dd"),
      });
      return data;
    },
    { enabled: !!id, refetchInterval: 60 * 1000 },
  );

  const vehicleCoordinationsQuery = useQuery(
    ["vehicleCoordinationsQuery", toursQuery.data],
    async () => {
      if (toursQuery.data) {
        const { data } = await getVehicleCoordinates({
          vehicles: [vehicleQuery.data],
        });
        return data;
      } else return [];
    },
    {
      enabled: !!toursQuery.data && !!vehicleQuery.data,
      refetchInterval: 60 * 1000,
    },
  );

  const tourList = useMemo(() => {
    return toursQuery.data?.tourEntryList
      ? [...toursQuery?.data?.tourEntryList]?.sort((a, b) => a?.tourOrder - b?.tourOrder)
      : [];
  }, [toursQuery?.data]);

  useEffect(() => {
    if (tourList.length) {
      let sorted = [...tourList].sort((a, b) => a.tourOrder - b.tourOrder);
      setCards(sorted);
      setCanExport?.(true);
    } else {
      setCards([]);
      setCanExport?.(false);
    }
  }, [tourList]); //eslint-disable-line

  useEffect(() => {
    const packageList = cards
      .map(card =>
        card.relTourPackages.map(rel => ({
          packageId: rel.packages.id,
          packageType: rel.packagesType,
        })),
      )
      .flat();

    let idList: number[] = [];
    let tempWrongIds: number[] = [];

    packageList.forEach(pack => {
      if (pack.packageType === "UP") {
        idList.push(pack.packageId);
      } else if (!idList.includes(pack.packageId)) {
        tempWrongIds.push(pack.packageId);
      }
    });
    setWrongOrder(tempWrongIds);
  }, [cards]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      let temp = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      const modifiedOrder = temp.map((row, index) => {
        return { ...row, tourOrder: index + 1 };
      });
      setCards(modifiedOrder);
      if (!orderChanged) {
        setOrderChanged(true);
      }
    },
    [cards, orderChanged],
  );

  const categorized = useMemo(() => {
    return (
      toursQuery.data?.tourEntryList.reduce(
        (
          acc: {
            coords: { longitude: number | null; latitude: number | null };
            tours: Tour[];
          }[],
          tour,
        ) => {
          const item = acc.find(
            item =>
              item.coords.latitude === tour.address.latitude &&
              item.coords.longitude === tour.address.longitude,
          );
          if (item) {
            item.tours.push(tour);
            item.tours.sort((a, b) => a.tourOrder - b.tourOrder);
          } else {
            acc.push({
              coords: {
                latitude: tour.address.latitude,
                longitude: tour.address.longitude,
              },
              tours: [tour],
            });
          }
          return acc;
        },
        [],
      ) ?? []
    );
  }, [toursQuery.data?.tourEntryList]);

  return (
    <Grid container spacing={3}>
      {toursQuery.isLoading ? (
        <Box width="100%" height={300} display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <>
          <Grid item xs={12} sm={6} style={{ maxHeight: 700, overflow: "auto" }}>
            {cards.length ? (
              <>
                {cards?.map((tour, index) => {
                  let temp: number[] = [];
                  tour.relTourPackages.forEach(pack => {
                    if (wrongOrder.includes(pack.packages.id)) {
                      temp.push(pack.packages.id);
                    }
                  });
                  return (
                    <Fragment key={tour.id}>
                      <TourSortingListItem
                        cards={cards}
                        tour={tour}
                        index={index}
                        moveCard={moveCard}
                        address={tour?.address}
                        wrongOrderIds={temp}
                        refetch={toursQuery.refetch}
                        date={date}
                        orderChanged={orderChanged}
                      />
                      <Divider style={{ margin: "12px 0" }} />
                    </Fragment>
                  );
                })}
                {orderChanged && (
                  <Box display="flex" margin="12px" gridGap={12}>
                    <Button
                      onClick={() => {
                        setCards(
                          toursQuery.data?.tourEntryList
                            ? [...toursQuery?.data?.tourEntryList]?.sort(
                                (a, b) => a?.tourOrder - b?.tourOrder,
                              )
                            : [],
                        );
                        setOrderChanged(false);
                      }}
                    >
                      {t("tour.cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!!wrongOrder.length}
                      onClick={async () => {
                        try {
                          await modifyTourOrder(cards);
                          enqueueSnackbar(t("tour.saveSuccess"), {
                            variant: "success",
                          });
                          setOrderChanged(false);
                          toursQuery.refetch();
                        } catch {
                          enqueueSnackbar(t("tour.saveError"), {
                            variant: "error",
                          });
                        }
                      }}
                      startIcon={<CheckIcon />}
                    >
                      {t("common:button.save")}
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Typography variant="body1">{t("vehicle.error.empty")}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MapContainer
              center={[
                tourList?.[0]?.address?.latitude || 47,
                tourList?.[0]?.address?.longitude || 20,
              ]}
              zoom={6}
              style={{ height: 680 }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {categorized.map(catTour => (
                <MapMarker key={catTour.tours[0]?.id} categorizedTours={catTour} />
              ))}
              {vehicleCoordinationsQuery.data?.vehicleCoordinates?.map(
                (vehicleCoordinates: any, index: number) => (
                  <VehicleMapMarker
                    key={index}
                    vehicle={vehicleQuery.data}
                    longitude={vehicleCoordinates?.longitude || 0}
                    latitude={vehicleCoordinates?.latitude || 0}
                  />
                ),
              )}
            </MapContainer>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TourDetailsContent;
