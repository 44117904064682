import { Collapse, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Camera, Edit } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/form/Button";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import usePermissions from "shared/hooks/usePermissions";
import { getPackageByTrackingNumber } from "shared/network/package.api";
import { Package } from "shared/types";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPackage: Dispatch<SetStateAction<Package | null>>;
};

const PackageModal = ({ open, setOpen, setSelectedPackage }: Props) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [qrOpen, setQrOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm({ shouldUnregister: false });
  const { errors, handleSubmit, register, setValue, reset } = form;

  const { companyPermissions, isAdmin } = usePermissions();
  const isShipper =
    companyPermissions?.includes("SHIPPER") || companyPermissions?.includes("SHIPPERADMIN");

  const handleScan = (data: string | null) => {
    if (data) {
      setValue("trackingNumber", data);
      setQrOpen(false);
      // onSubmit({ trackingNumber: data });
    }
  };

  const handleError = (err: any) => {
    enqueueSnackbar(t("package.qrError"), {
      variant: "error",
    });
  };

  const onClose = () => {
    if (isShipper && !isAdmin) {
      setQrOpen(false);
    } else {
      setQrOpen(false);
    }
    setOpen(false);
    reset();
  };

  async function onSubmit(values: any) {
    setLoading(true);
    try {
      const { data } = await getPackageByTrackingNumber(values.trackingNumber);
      setSelectedPackage(
        data.items?.find(value => value.trackingNumber === values.trackingNumber) ||
          data.items?.find(value => value.cdunumber === values.trackingNumber) ||
          data.items?.[0],
      );
      onClose();
    } catch (err: any) {
      if (err?.data?.status === "NOT_FOUND") {
        enqueueSnackbar(t("package.notFound"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("package.error"), {
          variant: "error",
        });
      }
    }
    setLoading(false);
  }

  const onChange = useDebouncedCallback((event: any) => {
    handleSubmit(onSubmit)(event);
  }, 300);

  useEffect(() => {
    if (isShipper && !isAdmin) {
      setQrOpen(false);
    } else {
      setQrOpen(false);
    }
  }, [isShipper, isAdmin]);

  return (
    <FormProvider {...form}>
      <Dialog
        fullScreen={!matchesSm}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {t("package.status.changeStatus")}
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {!qrOpen && (
            <>
              <TextField
                name="trackingNumber"
                label={t("package.trackingNumber")}
                size="small"
                autoFocus={!qrOpen}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.storageCode && true}
                helperText={errors?.storageCode?.message}
                onChange={onChange}
              />

              <Button
                variant="outlined"
                startIcon={<Camera />}
                onClick={() => setQrOpen(true)}
                style={{
                  marginTop: 12,
                  marginBottom: 8,
                  width: "100%",
                }}
              >
                {t("package.qrCodeButton")}
              </Button>
            </>
          )}
          {qrOpen && (
            <>
              <Button
                variant="outlined"
                startIcon={<Edit />}
                onClick={() => setQrOpen(false)}
                style={{
                  marginBottom: 8,
                  width: "100%",
                }}
              >
                {t("package.trackingNumberButton")}
              </Button>
              <QrReader delay={100} resolution={500} onError={handleError} onScan={handleScan} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              onClose();
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Check />}
          >
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default PackageModal;
