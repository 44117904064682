import Layout from "components/layout/Layout";
import { RootState } from "config/store";
import { round } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import usePermissions from "shared/hooks/usePermissions";
import { getCompanyById } from "shared/network/company.api";
import { getPackageListById, modifyPackagesList } from "shared/network/package.api";
import { SliceStatus } from "shared/types";
import { toUTCDate } from "shared/util/date";
import PackageModifyForm, { PackageModifyFormFields } from "./components/PackageModifyForm";

const PackageModify = ({
  match,
}: RouteComponentProps<{
  id: string;
}>) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const id = match.params.id;

  const [status, setStatus] = React.useState(SliceStatus.Idle);

  const packages = useQuery("packageModifyList", async () => {
    const { data } = await getPackageListById(Number.parseInt(id));
    return data.items;
  });

  const form = useForm<PackageModifyFormFields>();
  const { reset, handleSubmit } = form;

  const companyId = form.watch("companyId");
  const addressListQuery = useQuery(
    ["addressList", companyId],
    async () => {
      const { data } = await getCompanyById(companyId);
      return data.addressList;
    },
    { enabled: !!companyId },
  );

  useEffect(() => {
    reset({
      ...packages.data?.[0],
      companyId: packages.data?.[0]?.shop?.company?.id,
      sourceAddressId: packages.data?.[0]?.sourceAddress?.id,
      packages: packages.data,
      sourceDateFrom:
        packages.data?.[0]?.sourceDateFrom && new Date(packages.data?.[0]?.sourceDateFrom),
      sourceDateTo: packages.data?.[0]?.sourceDateTo && new Date(packages.data?.[0]?.sourceDateTo),
      targetDateTo: packages.data?.[0]?.targetDateTo && new Date(packages.data?.[0]?.targetDateTo),
      targetDateFrom:
        packages.data?.[0]?.targetDateFrom && new Date(packages.data?.[0]?.targetDateFrom),
    });
  }, [packages.data, reset]);

  const currentCompany = useSelector((state: RootState) => state.application.currentCompany);

  const { isCustomerService, isAdmin } = usePermissions();

  async function onModifySubmit(values: PackageModifyFormFields) {
    setStatus(SliceStatus.Loading);
    try {
      if (currentCompany?.id) {
        await modifyPackagesList({
          packagesList: values.packages.map(entry => {
            const temp = packages.data?.find(pack => entry.id.toString() === pack.id.toString());
            return {
              ...(temp || packages.data?.[0]),
              id: temp?.id,
              name: values.name,
              targetAddress: values.targetAddress,
              customerBillingAddress: values.customerBillingAddress,
              customerName: values.customerName,
              customerEmail: values.customerEmail,
              customerPhone: values.customerPhone,
              customerComment: values.customerComment,
              isDeliveryToWarehouse: !!entry.isDeliveryToWarehouse,
              isAskLoader: !!entry.isAskLoader,
              isStackable: !!entry.isStackable,
              isHazardous: !!entry.isHazardous,
              isUniqueInsurance: !!entry.isUniqueInsurance,
              isCashOnDelivery: !!values.isCashOnDelivery,
              markedToReturn: !!entry.markedToReturn,
              isFragile: !!entry.isFragile,
              isExpressDelivery: !!entry.isExpressDelivery,
              authorityToLeave: !!entry.authorityToLeave,
              sourceDateFrom: values.sourceDateFrom
                ? toUTCDate(values.sourceDateFrom).toISOString()
                : undefined,
              sourceDateTo: values.sourceDateTo
                ? toUTCDate(values.sourceDateTo).toISOString()
                : undefined,
              targetDateFrom: values.targetDateFrom
                ? toUTCDate(values.targetDateFrom).toISOString()
                : undefined,
              targetDateTo: values.targetDateTo
                ? toUTCDate(values.targetDateTo).toISOString()
                : undefined,
              weight: round(entry.weight, 2),
              height: entry.height,
              length: entry.length,
              width: entry.width,
              volume: round(entry.height * entry.width * entry.length, 2),
              value: values.value,
              shopPackagesNumber: values.shopPackagesNumber,
              vatPercent: values.vatPercent || undefined,
              deliveryPriceToWebshop: values.deliveryPriceToWebshop || undefined,
              nextPackage: undefined,
            };
          }),
          companyId: isCustomerService || isAdmin ? values.companyId : currentCompany?.id,
          sourceAddress: values.customSourceAddressCountry
            ? ({
                country: values.customSourceAddressCountry,
                zipCode: values.customSourceAddressZipCode,
                city: values.customSourceAddressCity,
                areaName: values.customSourceAddressAreaName,
                areaType: values.customSourceAddressAreaType,
                houseNumber: values.customSourceAddressHouseNumber,
                floor: values.customSourceAddressFloor,
              } as any)
            : addressListQuery.data?.find(entry => entry.id === values.sourceAddressId) ||
              undefined,
          adminShippingPrice: values.adminShippingPrice,
        });
      }
      history.push("/package");
      setStatus(SliceStatus.Success);
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: t("package.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      setStatus(SliceStatus.Error);
      if ((error as any).data?.status === "DELIVERY_FINISHED") {
        enqueueSnackbar(t("package.deliveryFinished"), { variant: "error" });
      } else {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("package.subject"),
          }),
          { variant: "error" },
        );
      }
    }
  }

  return (
    <Layout title={t("package.modify")} maxWidth="lg">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onModifySubmit)}>
          <PackageModifyForm
            loading={status === SliceStatus.Loading}
            addressList={addressListQuery.data}
          />
        </form>
      </FormProvider>
    </Layout>
  );
};

export default PackageModify;
