import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import DeliveryExtraChargeCreate from "./DeliveryExtraChargeCreate";
import DeliveryExtraChargeModify from "./DeliveryExtraChargeModify";
import DeliveryExtraChargeView from "./DeliveryExtraChargeView";

const DeliveryChargeRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={DeliveryExtraChargeView} admin />
      <PrivateRoute path={`${match.path}/create`} component={DeliveryExtraChargeCreate} />
      <PrivateRoute path={`${match.path}/modify/:id`} component={DeliveryExtraChargeModify} />
    </Switch>
  );
};

export default DeliveryChargeRoutes;
