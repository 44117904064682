import axios from "config/axios";
import { GenericListResponse, RelZipZoneEntry } from "shared/types";

const ENDPOINT = "/relzipzone";

export const getDeliveryZoneZipListByCompanyId = (param: string) =>
  axios.post<GenericListResponse<RelZipZoneEntry>>(`${ENDPOINT}/by-company`, { param });

export const createRelZipZoneEntry = (params: RelZipZoneEntry[]) =>
  axios.post(`${ENDPOINT}`, { params });
