import Layout from "components/layout/Layout";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createMultipleStorage } from "shared/network/storage.api";
import { createWarehouse } from "shared/network/warehouse.api";
import WarehouseForm, { WarehouseFormValues } from "./components/WarehouseForm";
import StorageItemsForm, { StorageFormValues } from "components/storage/StorageItemsForm";
import { FormProvider, useForm } from "react-hook-form";
import Button from "components/form/Button";
import { useMutation } from "react-query";
import Box from "@material-ui/core/Box";

type FormValues = WarehouseFormValues & StorageFormValues;

const WarehouseCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<FormValues>({
    defaultValues: {
      companyId: "",
    },
  });

  const mutation = useMutation<any, any, FormValues>(
    async values => {
      const { data } = await createWarehouse({
        address: {
          country: values.country,
          zipCode: values.zipCode,
          city: values.city,
          areaName: values.areaName,
          areaType: values.areaType,
          houseNumber: values.houseNumber,
          floor: values.floor,
        },
        warehouse: {
          name: values.name,
          height: Number.parseInt(values.height, 10),
          space: Number.parseInt(values.space, 10),
          companyId: Number.parseInt(values.companyId, 10),
        },
      });
      if (values.storageItems?.length) {
        const storageData = values.storageItems.map(storage => ({
          rowName: storage.rowName,
          numberOfColumns: Number.parseInt(storage.numberOfColumns, 10),
          numberOfLevels: Number.parseInt(storage.numberOfLevels, 10),
          numberOfSpaces: Number.parseInt(storage.numberOfSpaces, 10),
          warehouseId: data.item.id,
          isCustom: storage.isCustom,
        }));
        return await createMultipleStorage(storageData);
      }
    },
    {
      onSuccess: () => {
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("warehouse.subject"),
          }),
          { variant: "success" },
        );
        history.push("/warehouse");
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("warehouse.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  return (
    <Layout title={t("warehouse.create.title")} maxWidth="lg">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(values => mutation.mutate(values))}>
          <WarehouseForm />
          <StorageItemsForm />
          <Box margin={2}>
            <Button loading={mutation.isLoading} color="primary" type="submit">
              {t("common:button.create")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default WarehouseCreate;
