import { IconButton, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from "@material-ui/icons";
import { palette } from "config/theme";
import { round } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { Vehicle } from "shared/types";
import { TourPlan } from "../Tour";

type Props = {
  tour: TourPlan;
  setSelected: Dispatch<SetStateAction<TourPlan>>;
  onClick: (open: boolean) => void;
  refetch: () => void;
  enableMultiSelect: boolean;
  selectedVehicle: Vehicle | null;
  setSelectedVehicle: Dispatch<SetStateAction<Vehicle | null>>;
  isntLight: boolean;
};

const VehicleRow = ({
  tour,
  setSelected,
  onClick,
  enableMultiSelect,
  selectedVehicle,
  setSelectedVehicle,
  isntLight,
}: Props) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const [volume, setVolume] = useState(0);
  const [weight, setWeight] = useState(0);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "box",
      drop: () => {
        return {
          tourPlan: tour,
        };
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [tour],
  );

  const isActive = canDrop && isOver;
  let backgroundColor;
  let color;
  if (isActive) {
    color = "white";
    backgroundColor = palette.main;
  } else if (canDrop) {
    backgroundColor = "lightGrey";
  }

  useEffect(() => {
    let tempVolume = 0;
    let tempWeight = 0;
    let flatList = tour?.tourEntryList?.map(tourEntry => tourEntry.relTourPackages).flat() || [];
    flatList?.forEach(relTour => {
      if (relTour.packagesType === "UP") {
        tempVolume += relTour.packages.volume;
        tempWeight += relTour.packages.weight;
      }
    });
    setVolume(round(tempVolume, 2));
    setWeight(round(tempWeight, 2));
  }, [tour.tourEntryList]);

  useEffect(() => {
    setIsSelected(tour.vehicleEntry.id === selectedVehicle?.id);
  }, [selectedVehicle, tour, setIsSelected]);

  return (
    <div
      ref={drop}
      style={{ cursor: "pointer", color, backgroundColor }}
      onClick={() => !enableMultiSelect && onClick(true)}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        height={40}
        onClick={() => setSelected(tour)}
        style={{
          padding: "0 8px",
          color:
            (volume &&
              volume <= tour.vehicleEntry.volume &&
              weight &&
              weight <= tour.vehicleEntry.weightCapacity) ||
            tour.hasTour
              ? palette.green
              : (volume && volume > tour.vehicleEntry.volume) ||
                (weight && weight > tour.vehicleEntry.weightCapacity)
              ? palette.brickRed
              : undefined,
        }}
      >
        {enableMultiSelect && (
          <Box width="9%">
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 12,
              }}
            >
              {!isSelected ? (
                <IconButton
                  onClick={() => {
                    setSelectedVehicle(tour.vehicleEntry);
                  }}
                >
                  <RadioButtonUncheckedOutlined color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setSelectedVehicle(null);
                  }}
                >
                  <RadioButtonCheckedOutlined color="primary" />
                </IconButton>
              )}
            </Typography>
          </Box>
        )}
        <Box width={enableMultiSelect ? "30%" : "33%"}>{tour.vehicleEntry.licensePlateNumber}</Box>
        <Box width={enableMultiSelect ? "30%" : "33%"} display="flex" justifyContent="flex-end">
          {!!isntLight && t("common:number", { num: volume })}
          {!!isntLight && "/"}
          {t("common:number", { num: tour.vehicleEntry.volume })} m³
        </Box>
        <Box width={enableMultiSelect ? "30%" : "33%"} display="flex" justifyContent="flex-end">
          {!!isntLight && t("common:number", { num: weight })}
          {!!isntLight && "/"}
          {t("common:number", { num: tour.vehicleEntry.weightCapacity })} kg
        </Box>
      </Box>
      <Divider />
    </div>
  );
};

export default VehicleRow;
