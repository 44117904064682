import Tracking from "./Tracking";
import { Route } from "react-router-dom";
import { RouteComponentProps, Switch } from "react-router-dom";
import TrackingSearch from "./TrackingSearch";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CAPTCHA_KEY } from "config/constants";

const TrackingRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
      <Switch location={location}>
        <Route exact path={match.path} component={TrackingSearch} />
        <Route path={`${match.path}/:trackingNumber`} component={Tracking} />
      </Switch>
    </GoogleReCaptchaProvider>
  );
};

export default TrackingRoutes;
