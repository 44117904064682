import axios from "config/axios";
import { AuthenticationResponse } from "shared/types";

const ENDPOINT = "/authentication";

export const authenticate = (email: string, password: string, rememberMe: boolean) =>
  axios.post<AuthenticationResponse>(ENDPOINT, {
    email,
    password,
    rememberMe,
  });
