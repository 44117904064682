import clsx from "clsx";
import MenuBar from "./components/MenuBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Container,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { palette } from "config/theme";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "components/router";
import { getSystemProperties } from "shared/network/property.api";

type Props = {
  children: React.ReactNode;
  title?: string;
  actionButton?: React.ReactNode;
  style?: React.CSSProperties;
  maxWidth?: "xl" | "lg" | "md" | "sm" | "xs";
};

const useStyles = makeStyles(
  () => ({
    title: {
      fontSize: 26,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      color: palette.main,
    },
    menuBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: palette.light,
    },
  }),
  { name: "Layout" },
);

const Layout = ({ children, title, actionButton, style, maxWidth = "xl" }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const query = useQuery("versionLayout", async () => {
    const { data } = await getSystemProperties();
    return data.items;
  });
  const version = query.data?.find(property => property.name === "version")?.value;

  return (
    <main>
      <MenuBar />
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        marginTop={8}
        marginLeft="73px"
      >
        <Box display="flex" p={2}>
          <Box display="flex" alignItems="center" gridGap={18}>
            <Tooltip title={t("common:button.back").toString()}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Typography className={clsx(style, classes.title)}>{title}</Typography>
          </Box>
        </Box>
        {actionButton && (
          <Box display="flex" p={2} justifyContent="flex-end" alignItems="center">
            {actionButton}
          </Box>
        )}
      </Box>
      <Container
        maxWidth={maxWidth}
        style={{
          paddingLeft: 73 + 24,
          marginBottom: 16,
        }}
      >
        <>{children}</>
      </Container>
      <footer
        style={{
          paddingLeft: 73 + 24,
          paddingRight: 24,
          textAlign: "center",
          color: "gray",
        }}
      >
        {t("login.allRightsReserved", {
          date:
            new Date().getFullYear() === 2021
              ? new Date().getFullYear()
              : `2021 - ${new Date().getFullYear()}`,
          version: version,
        })}
        <div>
          <Link
            component={RouterLink}
            to="/aszf"
            style={{
              textDecoration: "underline",
              color: "inherit",
            }}
          >
            {t("tos")}
          </Link>
          {", "}
          <Link
            component={RouterLink}
            to="/gdpr"
            style={{
              textDecoration: "underline",
              color: "inherit",
            }}
          >
            {t("gdpr")}
          </Link>
        </div>
      </footer>
    </main>
  );
};

export default Layout;
