import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Address } from "shared/types";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useCallback } from "react";
import { coordinatesDraggableMarker } from "shared/network/address.api";
import { useTranslation } from "react-i18next";
import Button from "./form/Button";

type Props = {
  address: Address;
  open: "current" | "target" | "source" | boolean;
  setOpen: Dispatch<SetStateAction<"current" | "target" | "source" | boolean>>;
};
const ModifyCoordinates = ({ address, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState({
    lat: address.latitude,
    lng: address.longitude,
  });

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;

        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),

    [],
  );

  const toggleDraggable = useCallback(() => {
    setDraggable(d => !d);
  }, []);

  async function onSubmit(addressId: number, latitude: number, longitude: number) {
    setLoading(true);
    try {
      await coordinatesDraggableMarker(addressId, latitude, longitude);
      handleClose();
    } catch (err) {}
    setLoading(false);
  }
  return (
    <Dialog open={!!open} onClose={handleClose}>
      <DialogTitle>{t("map.editorTitle")}</DialogTitle>
      <DialogContent>
        <MapContainer
          center={[address.latitude || 0, address.longitude || 0]}
          zoom={15}
          style={{ height: 500, width: 500 }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
          >
            <Popup minWidth={90}>
              <span onClick={toggleDraggable}>
                {draggable ? t("map.draggableMarker") : t("map.undraggableMarker")}
              </span>
            </Popup>
          </Marker>
        </MapContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("common:button.cancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(address.id, position.lat, position.lng)}
          loading={loading}
        >
          {t("common:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifyCoordinates;
