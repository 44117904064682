import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox, Remove } from "@material-ui/icons";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Button from "components/form/Button";
import { AREA_TYPES } from "config/constants";
import { palette } from "config/theme";
import { isEmpty, round } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import usePermissions from "shared/hooks/usePermissions";
import { listCompanyPageable } from "shared/network/company.api";
import { getSystemProperties } from "shared/network/property.api";
import { Address, Company } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import PackageDeliveryPriceModal from "./PackageDeliveryPriceModal";

export type PackageFormFields = {
  targetAddress: Address;
  customerBillingAddress: Address;
  sourceAddress: number;
  customSourceAddressCountry?: string;
  customSourceAddressZipCode?: string;
  customSourceAddressCity?: string;
  customSourceAddressAreaName?: string;
  customSourceAddressAreaType?: string;
  customSourceAddressHouseNumber?: string;
  customSourceAddressFloor?: string;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  customerComment: string;
  sourceDateFrom: Date;
  sourceDateTo: Date;
  targetDateFrom: Date;
  targetDateTo: Date;
  deliveryPriceToWebshop: number;
  vatPercent: number;
  value: number;
  isCashOnDelivery: boolean;
  name: string;
  companyId: number;
  sameTargetAddressWithBilling: boolean;
  packages: PackageData[];
  shopPackagesNumber: string;
};

export type PackageData = {
  weight: number;
  volume: number;
  height: number;
  width: number;
  length: number;
  isAskLoader: boolean;
  isHazardous: boolean;
  isFragile: boolean;
  isStackable: boolean;
  isUniqueInsurance: boolean;
  isDeliveryToWarehouse: boolean;
  failedDelivery: boolean;
  isExpressDelivery: boolean;
  markedToReturn: boolean;
  authorityToLeave: boolean;
  shopPackagesNumber: string;
};

const useStyles = makeStyles(
  () => ({
    title: {
      color: "grey",
      textTransform: "uppercase",
      fontSize: 14,
      paddingBottom: 8,
      borderBottom: "1px solid lightgrey",
      margin: "8px 0 8px",
    },
    dateInput: {
      "& svg": {
        color: palette.main,
        minWidth: 24,
        minHeight: 24,
      },
      "& input": {
        color: palette.main,
        textAlign: "center",
        fontSize: 18,
      },
    },
    dataDivider: {
      paddingBottom: 8,
      borderBottom: "1px solid lightgrey",
      margin: "8px 0 8px",
    },
  }),
  { name: "PackageForm" },
);

type Props = {
  loading?: boolean;
  addressList?: Address[];
  currentCompany?: Company | null;
};

const PackageForm = ({ loading, addressList, currentCompany }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isCustomerService, isAdmin, checkPermissions } = usePermissions();
  const { register, errors, control, watch, setValue, getValues } = useFormContext();

  const [isCustomSourceAddress, setIsCustomSourceAddress] = useState(false);

  const companyListQuery = useQuery(["companyQueryPackageForm"], async () => {
    const { data } = await listCompanyPageable(0, 1000000, "isShopKeeper:TRUE");
    return data.page.content;
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "packages",
    keyName: "key",
  });
  const { companyId, packages, deliveryPriceToWebshop } = watch([
    "companyId",
    "packages",
    "deliveryPriceToWebshop",
  ]);

  const [isDefaultDeliveryToWarehouse, setIsDefaultDeliveryToWarehouse] = useState<boolean>(false);

  useEffect(() => {
    append({});
  }, []); //eslint-disable-line

  const systemPropertiesQuery = useQuery("getSystemPropertiesPackageForm", async () => {
    const { data } = await getSystemProperties();
    return data.items;
  });

  const multiplePackageEnabled = !!(
    systemPropertiesQuery.data?.find(property => property.name === "multiple_package")?.value ===
    "true"
  );

  useEffect(() => {
    setIsDefaultDeliveryToWarehouse(
      companyListQuery?.data?.find(company => companyId === company.id)
        ?.isDefaultDeliveryToWarehouse || false,
    );
  }, [companyListQuery.data, companyId, setIsDefaultDeliveryToWarehouse]);

  useEffect(() => {
    if (packages) {
      (packages as PackageData[]).forEach((value, index) => {
        setValue(`packages.${index}.isDeliveryToWarehouse`, isDefaultDeliveryToWarehouse);
      });
    }
  }, [isDefaultDeliveryToWarehouse, setValue]); //eslint-disable-line

  function calcVolume(index: number) {
    const height = getValues(`packages.${index}.height`);
    const width = getValues(`packages.${index}.width`);
    const length = getValues(`packages.${index}.length`);
    setValue(
      `packages.${index}.volume`,
      height && width && length ? round(height * width * length, 2) : 0,
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="companyId"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t("validation:required"),
              },
            }}
            render={props => (
              <TextField
                {...props}
                margin="none"
                label={t("common:field.company")}
                InputLabelProps={{ shrink: true, required: true }}
                disabled={
                  !isCustomerService &&
                  !isAdmin &&
                  !checkPermissions({
                    requestedPermissions: ["SHIPORGANIZER"],
                  })
                }
                error={errors.companyId && true}
                helperText={errors.companyId?.message}
                SelectProps={{ displayEmpty: true }}
                select
              >
                <MenuItem value="">
                  <em>{t("common:choose")}</em>
                </MenuItem>
                {companyListQuery?.data?.map((company, index) => (
                  <MenuItem key={index} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="sourceAddress"
            defaultValue=""
            render={props => (
              <TextField
                {...props}
                margin="none"
                disabled={!companyId || isEmpty(addressList) || isCustomSourceAddress}
                label={t("common:field.sourceAddress")}
                InputLabelProps={{ shrink: true, required: !isCustomSourceAddress }}
                error={errors.sourceAddress && true}
                helperText={errors.sourceAddress?.message}
                SelectProps={{ displayEmpty: true }}
                select
              >
                <MenuItem value="">
                  <em>{t("common:choose")}</em>
                </MenuItem>
                {(addressList || []).map((address, index) => (
                  <MenuItem key={index} value={address.id}>
                    {displayAddress(address)}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: {
                value: !isCustomSourceAddress,
                message: t("validation:required"),
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box mb={1} display="flex" gridGap={8} justifyContent="center">
            <Button
              startIcon={!isCustomSourceAddress ? <AddBox /> : <Remove />}
              disabled={!companyId}
              color="primary"
              onClick={() => setIsCustomSourceAddress(!isCustomSourceAddress)}
            >
              {t("common:button.isCustomSourceAddress")}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={
            isCustomerService ||
            isAdmin ||
            checkPermissions({
              requestedPermissions: ["SHIPORGANIZER"],
            })
              ? 4
              : 12
          }
        >
          <TextField
            name="name"
            margin="none"
            label={t("package.properties.name")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
            error={errors?.name}
            helperText={errors?.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="shopPackagesNumber"
            margin="none"
            label={t("package.properties.shopPackagesNumber")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
            error={!!errors?.shopPackagesNumber}
            helperText={errors?.shopPackagesNumber?.message}
          />
        </Grid>
      </Grid>
      {/*---Custom Source Address---*/}
      {isCustomSourceAddress && (
        <>
          <Typography className={classes.title}>{t("package.isCustomSourceAddress")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                margin="none"
                name="customSourceAddressCountry"
                label={t("common:field.address.country")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: isCustomSourceAddress,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.targetAddress?.country}
                helperText={errors?.targetAddress?.country?.message}
                defaultValue="Magyarország"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                margin="none"
                name="customSourceAddressZipCode"
                type="number"
                label={t("common:field.address.zipCode")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: isCustomSourceAddress,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.targetAddress?.zipCode}
                helperText={errors?.targetAddress?.zipCode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="none"
                name="customSourceAddressCity"
                label={t("common:field.address.city")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: isCustomSourceAddress,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.targetAddress?.city}
                helperText={errors?.targetAddress?.city?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="none"
                name="customSourceAddressAreaName"
                label={t("common:field.address.areaName")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: isCustomSourceAddress,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.targetAddress?.areaName}
                helperText={errors?.targetAddress?.areaName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                name="customSourceAddressAreaType"
                defaultValue="UTCA"
                render={props => (
                  <TextField
                    {...props}
                    margin="none"
                    select
                    label={t("common:field.address.areaType")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!errors?.targetAddress?.areaType}
                    helperText={errors?.targetAddress?.areaType?.message}
                  >
                    {AREA_TYPES.map(type => (
                      <MenuItem key={type} value={type}>
                        {t(`common:areaType.${type}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value: isCustomSourceAddress,
                    message: t("validation:required"),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                margin="none"
                name="customSourceAddressHouseNumber"
                label={t("common:field.address.houseNumber")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: isCustomSourceAddress,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.targetAddress?.houseNumber}
                helperText={errors?.targetAddress?.houseNumber?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                margin="none"
                name="customSourceAddressFloor"
                label={t("common:field.address.floor")}
                InputLabelProps={{ shrink: true }}
                inputRef={register()}
              />
            </Grid>
          </Grid>
        </>
      )}
      {/*---Customer---*/}
      <Typography className={classes.title}>{t("package.customer")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            name="customerName"
            label={t("package.properties.customerName")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.customerName}
            helperText={errors?.customerName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="customerEmail"
            label={t("package.properties.customerEmail")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.customerEmail && true}
            helperText={errors?.customerEmail?.message}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="customerPhone"
            placeholder="+36"
            defaultValue="+36"
            label={t("package.properties.customerPhone")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.customerPhone && true}
            helperText={errors?.customerPhone?.message}
          />
        </Grid>
      </Grid>
      {/*---TargetAddress---*/}
      <Typography className={classes.title}>{t("package.targetAddress")}</Typography>
      <Grid container spacing={2} style={{ padding: "12px 0" }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            margin="none"
            name="targetAddress.country"
            label={t("common:field.address.country")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.targetAddress?.country}
            helperText={errors?.targetAddress?.country?.message}
            defaultValue="Magyarország"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            margin="none"
            name="targetAddress.zipCode"
            type="number"
            label={t("common:field.address.zipCode")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.targetAddress?.zipCode}
            helperText={errors?.targetAddress?.zipCode?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="none"
            name="targetAddress.city"
            label={t("common:field.address.city")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.targetAddress?.city}
            helperText={errors?.targetAddress?.city?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="none"
            name="targetAddress.areaName"
            label={t("common:field.address.areaName")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.targetAddress?.areaName}
            helperText={errors?.targetAddress?.areaName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Controller
            name="targetAddress.areaType"
            defaultValue="UTCA"
            render={props => (
              <TextField
                {...props}
                margin="none"
                select
                label={t("common:field.address.areaType")}
                InputLabelProps={{ shrink: true, required: true }}
                error={!!errors?.targetAddress?.areaType}
                helperText={errors?.targetAddress?.areaType?.message}
              >
                {AREA_TYPES.map(type => (
                  <MenuItem key={type} value={type}>
                    {t(`common:areaType.${type}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: {
                value: true,
                message: t("validation:required"),
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            margin="none"
            name="targetAddress.houseNumber"
            label={t("common:field.address.houseNumber")}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={!!errors?.targetAddress?.houseNumber}
            helperText={errors?.targetAddress?.houseNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            margin="none"
            name="targetAddress.floor"
            label={t("common:field.address.floor")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
          />
        </Grid>
      </Grid>

      {/*---Details---*/}
      <Box className={classes.title} display="flex" alignItems={"space-between"}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{
                color: "grey",
                textTransform: "uppercase",
                fontSize: 14,
              }}
            >
              {t("package.packageDetails")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {fields.map((field, index) => {
        return (
          <Grid container spacing={2} key={field.key}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name={`packages.${index}.height`}
                type="number"
                inputProps={{ step: ".01" }}
                onChange={e => {
                  calcVolume(index);
                }}
                label={`${t("package.properties.height")} (m)`}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.packages?.[index]?.height && true}
                helperText={errors?.packages?.[index]?.height?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name={`packages.${index}.width`}
                type="number"
                inputProps={{ step: ".01" }}
                onChange={e => {
                  calcVolume(index);
                }}
                label={`${t("package.properties.width")} (m)`}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={errors?.packages?.[index]?.width && true}
                helperText={errors?.packages?.[index]?.width?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name={`packages.${index}.length`}
                type="number"
                inputProps={{ step: ".01" }}
                onChange={e => {
                  calcVolume(index);
                }}
                label={`${t("package.properties.length")} (m)`}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.packages?.[index]?.length}
                helperText={errors?.packages?.[index]?.length?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name={`packages.${index}.volume`}
                type="number"
                inputProps={{ step: ".01" }}
                defaultValue={0}
                label={`${t("package.properties.volume")} (m³)`}
                disabled
                InputLabelProps={{ shrink: true }}
                inputRef={register()}
                error={!!errors?.packages?.[index]?.volume}
                helperText={errors?.packages?.[index]?.volume?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name={`packages.${index}.weight`}
                type="number"
                margin="none"
                inputProps={{ step: ".01" }}
                label={`${t("package.properties.weight")} (kg)`}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.packages?.[index]?.weight}
                helperText={errors?.packages?.[index]?.weight?.message}
              />
            </Grid>
            <Grid container alignItems="center">
              {(isCustomerService ||
                isAdmin ||
                checkPermissions({
                  requestedPermissions: ["SHIPORGANIZER"],
                })) && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      control={control}
                      name={`packages.${index}.isDeliveryToWarehouse`}
                      defaultValue={isDefaultDeliveryToWarehouse}
                      render={props => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...props}
                              color="primary"
                              checked={props.value}
                              onChange={e => props.onChange(e.target.checked)}
                            />
                          }
                          label={t("package.properties.isDeliveryToWarehouse")}
                          labelPlacement="end"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      control={control}
                      name={`packages.${index}.failedDelivery`}
                      defaultValue={false}
                      render={props => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...props}
                              color="primary"
                              checked={props.value}
                              onChange={e => props.onChange(e.target.checked)}
                            />
                          }
                          label={t("package.properties.failedDelivery")}
                          labelPlacement="end"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.isAskLoader`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("package.properties.isAskLoader")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.isHazardous`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("package.properties.isHazardous")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.isFragile`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("package.properties.isFragile")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.isStackable`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("package.properties.isStackable")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.isExpressDelivery`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("package.properties.isExpressDelivery")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
              {(isCustomerService || isAdmin) && (
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name={`packages.${index}.markedToReturn`}
                    defaultValue={false}
                    render={props => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...props}
                            color="primary"
                            checked={props.value}
                            onChange={e => props.onChange(e.target.checked)}
                          />
                        }
                        label={t("packageFilters.markedToReturn")}
                        labelPlacement="end"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.isUniqueInsurance`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("packageFilters.isUniqueInsurance")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  control={control}
                  name={`packages.${index}.authorityToLeave`}
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...props}
                          color="primary"
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                        />
                      }
                      label={t("packageFilters.authorityToLeave")}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.dataDivider}>
              <Box mb={1} display="flex" gridGap={8} justifyContent="center">
                {fields.length !== 1 && (
                  <Button variant="text" onClick={() => remove(index)}>
                    {t("common:button.delete")}
                  </Button>
                )}
                {index === fields.length - 1 && (multiplePackageEnabled || isAdmin) && (
                  <Button
                    startIcon={<AddBox />}
                    color="primary"
                    onClick={() =>
                      append({
                        isDeliveryToWarehouse: isDefaultDeliveryToWarehouse,
                      })
                    }
                  >
                    {t("common:button.addPackageData")}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      })}
      {/*---Finances---*/}
      <>
        <Typography className={classes.title}>Szállítási költség</Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="deliveryPriceToWebshop"
              type="number"
              inputProps={{ step: ".01" }}
              label={`${t("package.properties.deliveryPriceToWebshop")} (Ft)`}
              InputLabelProps={{ shrink: true }}
              disabled={!isCustomerService && !isAdmin}
              inputRef={register()}
              error={errors?.deliveryPriceToWebshop && true}
              helperText={errors?.deliveryPriceToWebshop?.message}
            />
          </Grid>
          {(isCustomerService || isAdmin) && (
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="vatPercent"
                type="number"
                inputProps={{ step: ".01" }}
                label={`${t("package.properties.vatPercent")} (%)`}
                InputLabelProps={{ shrink: true }}
                inputRef={register()}
                error={errors?.vatPercent && true}
                helperText={errors?.vatPercent?.message}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <PackageDeliveryPriceModal />
          </Grid>
        </Grid>
      </>
      <Typography className={classes.title}>{t("package.packageFinances")}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="value"
            type="number"
            inputProps={{ step: ".01" }}
            label={`${t("package.properties.value")} (Ft)`}
            InputLabelProps={{ shrink: true, required: true }}
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
            })}
            error={errors?.value && true}
            helperText={errors?.value?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="isCashOnDelivery"
            defaultValue={false}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...props}
                    color="primary"
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                }
                label={t("package.properties.isCashOnDelivery")}
                labelPlacement="end"
              />
            )}
          />
        </Grid>
      </Grid>
      {/*---Dates---*/}
      <Typography className={classes.title}>{t("package.dates")}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="sourceDateFrom"
            defaultValue={null}
            render={({ ref, ...props }) => (
              <KeyboardDateTimePicker
                {...props}
                style={{ marginTop: 16 }}
                InputLabelProps={{ shrink: true }}
                label={t("package.properties.sourceDateFrom")}
                onChange={date => props.onChange(date)}
                autoOk
                format="yyyy.MM.dd. HH:mm"
                ampm={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="sourceDateTo"
            defaultValue={null}
            render={({ ref, ...props }) => (
              <KeyboardDateTimePicker
                {...props}
                style={{ marginTop: 16 }}
                InputLabelProps={{ shrink: true }}
                label={t("package.properties.sourceDateTo")}
                onChange={date => {
                  props.onChange(date);
                }}
                autoOk
                format="yyyy.MM.dd. HH:mm"
                ampm={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="targetDateFrom"
            defaultValue={null}
            render={({ ref, ...props }) => (
              <KeyboardDateTimePicker
                {...props}
                style={{ marginTop: 16 }}
                InputLabelProps={{ shrink: true }}
                label={t("package.properties.targetDateFrom")}
                onChange={date => props.onChange(date)}
                autoOk
                format="yyyy.MM.dd. HH:mm"
                ampm={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="targetDateTo"
            defaultValue={null}
            render={({ ref, ...props }) => (
              <KeyboardDateTimePicker
                {...props}
                style={{ marginTop: 16 }}
                InputLabelProps={{ shrink: true }}
                label={t("package.properties.targetDateTo")}
                onChange={date => props.onChange(date)}
                autoOk
                format="yyyy.MM.dd. HH:mm"
                ampm={false}
              />
            )}
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: "16px 0" }} />
      <TextField
        name="customerComment"
        label={t("package.properties.customerComment")}
        multiline
        rows={2}
        rowsMax={5}
        InputLabelProps={{ shrink: true }}
        inputRef={register()}
        error={errors?.customerComment && true}
        helperText={errors?.customerComment?.message}
      />
      <Box mt={2}>
        <Button loading={loading} color="primary" type="submit" disabled={!deliveryPriceToWebshop}>
          {t("common:button.add")}
        </Button>
      </Box>
    </>
  );
};

export default PackageForm;
