import { PrivateRoute } from "components/router";
import { RouteComponentProps, Switch } from "react-router-dom";
import CompanyCreate from "./CompanyCreate";
import CompanyDetails from "./CompanyDetails";
import CompanyList from "./CompanyList";
import CompanyModify from "./CompanyModify";
import CompanyPickUpPointCreate from "./CompanyPickUpPointCreate";
import CompanyZone from "./CompanyZone";
import CompanyZoneList from "./components/CompanyZone/CompanyZoneList";

const CompanyRoutes = ({ location, match }: RouteComponentProps) => {
  return (
    <Switch location={location}>
      <PrivateRoute exact path={match.path} component={CompanyList} />
      <PrivateRoute path={`${match.path}/create`} component={CompanyCreate} />
      <PrivateRoute path={`${match.path}/:id/modify`} component={CompanyModify} />
      <PrivateRoute path={`${match.path}/:id/details`} component={CompanyDetails} />
      <PrivateRoute
        path={`${match.path}/:id/relshopaddress`}
        component={CompanyPickUpPointCreate}
      />

      <PrivateRoute exact path={`${match.path}/:id/zones`} component={CompanyZone} />
      <PrivateRoute path={`${match.path}/:id/zones/list`} component={CompanyZoneList} />
    </Switch>
  );
};

export default CompanyRoutes;
