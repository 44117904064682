import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ForgottenPassword from "./ForgottenPassword";

type Props = RouteComponentProps;

const ForgottenPasswordRoutes = ({ location, match }: Props) => {
  return (
    <Switch location={location}>
      <Route exact path={match.path} component={ForgottenPassword} />
    </Switch>
  );
};

export default ForgottenPasswordRoutes;
