import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { useSelector } from "react-redux";

const usePermissions = () => {
  const { currentCompany, user, permissions } = useSelector(
    createSelector(
      (state: RootState) => state.authentication,
      (state: RootState) => state.application.currentCompany,
      (authentication, currentCompany) => ({
        user: authentication.user,
        permissions: authentication.permissions,
        currentCompany,
      }),
    ),
  );
  const userType = user?.userType;

  const currentCompanyPermissions = permissions.find(perm => perm.companyId === currentCompany?.id)
    ?.userCompanyPermissions;

  function checkPermissions({
    requestedPermissions = [],
    customerService,
    admin,
  }: {
    requestedPermissions?: string[];
    customerService?: boolean;
    admin?: boolean;
  }) {
    if (admin) {
      return userType === "ADMIN";
    }
    if (customerService) {
      return userType === "ADMIN" || userType === "CUSTOMER_SERVICE";
    }
    return (
      userType === "ADMIN" ||
      userType === "CUSTOMER_SERVICE" ||
      requestedPermissions.some(permission => currentCompanyPermissions?.includes(permission))
    );
  }

  return {
    user: user,
    companyPermissions: currentCompanyPermissions,
    isAdmin: userType === "ADMIN",
    isCustomerService: userType === "ADMIN" || userType === "CUSTOMER_SERVICE",
    checkPermissions,
  };
};

export default usePermissions;
