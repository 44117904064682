import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { modifyShipmentWindow } from "shared/network/package.api";
import { Package } from "shared/types";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  packageList: Package[];
  refetch?: () => void;
};

const PackageTimeWindowModal = ({ open, setOpen, packageList, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [windowStart, setWindowStart] = useState<Date>(new Date());
  const [windowEnd, setWindowEnd] = useState<Date>(new Date());

  async function onSubmit() {
    try {
      let start = windowStart;
      let end = windowEnd;
      start.setUTCHours(6, 0, 0, 0);
      end.setUTCHours(18, 0, 0, 0);
      await modifyShipmentWindow(packageList, start.toISOString(), end.toISOString());
      enqueueSnackbar(
        t("common:notification.update.success", {
          subject: t("package.timeWindow.subject"),
        }),
        { variant: "success" },
      );
      setOpen(false);
      refetch?.();
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.update.failure", {
          subject: t("package.timeWindow.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{t("package.timeWindow.title")}</Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gridGap={12}
        >
          <Box display="flex" gridGap={8} alignItems="center" justifyContent="center">
            <KeyboardDatePicker
              name="windowStart"
              format="yyyy. MM. dd."
              label={t("package.timeWindow.start")}
              value={windowStart ?? new Date()}
              onChange={date => setWindowStart(date ?? new Date())}
              size="small"
              fullWidth
              margin="none"
              style={{ maxWidth: 250 }}
              animateYearScrolling
            />
          </Box>
          <Box display="flex" gridGap={8} alignItems="center" justifyContent="center">
            <KeyboardDatePicker
              name="windowEnd"
              format="yyyy. MM. dd."
              label={t("package.timeWindow.end")}
              value={windowEnd ?? new Date()}
              onChange={date => setWindowEnd(date ?? new Date())}
              size="small"
              fullWidth
              margin="none"
              style={{ maxWidth: 250 }}
              animateYearScrolling
            />
          </Box>
          <Button onClick={() => onSubmit()} variant="contained" color="primary">
            {t("common:button.save")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PackageTimeWindowModal;
