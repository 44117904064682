import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import Layout from "components/layout/Layout";
import { RootState } from "config/store";
import { round } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import usePermissions from "shared/hooks/usePermissions";
import { getCompanyById } from "shared/network/company.api";
import { createMorePackages, getPackageSampleCSV } from "shared/network/package.api";
import { SliceStatus } from "shared/types";
import { toUTCDate } from "shared/util/date";
import fileDownload from "shared/util/fileDownload";
import PackageFileUpload from "views/package/components/PackageFileUpload";
import PackageForm, { PackageFormFields } from "./components/PackageForm";

const PackageCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = React.useState(SliceStatus.Idle);
  const [fileUpload, setFileUpload] = useState(false);

  const currentCompany = useSelector((state: RootState) => state.application.currentCompany);

  const { isCustomerService, isAdmin, checkPermissions } = usePermissions();

  const form = useForm<PackageFormFields>();
  const companyId = form.watch("companyId");

  const addressListQuery = useQuery(
    ["addressListCreate", companyId],
    async () => {
      const { data } = await getCompanyById(companyId);
      return data.addressList;
    },
    { enabled: !!companyId },
  );

  useEffect(() => {
    if (
      !isCustomerService &&
      !isAdmin &&
      !checkPermissions({
        requestedPermissions: ["SHIPORGANIZER"],
      })
    ) {
      form.setValue("companyId", currentCompany?.id);
    }
  }, [currentCompany, isAdmin]); // eslint-disable-line

  async function onCreateSubmit(values: PackageFormFields) {
    setStatus(SliceStatus.Loading);
    try {
      await createMorePackages({
        packagesList: values.packages.map(entry => {
          return {
            name: values.name,
            targetAddress: values.targetAddress,
            customerName: values.customerName,
            customerEmail: values.customerEmail,
            customerPhone: values.customerPhone,
            customerComment: values.customerComment,
            isDeliveryToWarehouse: entry.isDeliveryToWarehouse,
            isAskLoader: entry.isAskLoader,
            isStackable: entry.isStackable,
            isHazardous: entry.isHazardous,
            isUniqueInsurance: entry.isUniqueInsurance,
            isCashOnDelivery: values.isCashOnDelivery,
            markedToReturn: entry.markedToReturn,
            isFragile: entry.isFragile,
            isExpressDelivery: entry.isExpressDelivery,
            authorityToLeave: entry.authorityToLeave,
            sameTargetAddressWithBilling: values.sameTargetAddressWithBilling,
            sourceDateFrom: values.sourceDateFrom
              ? toUTCDate(values.sourceDateFrom).toISOString()
              : undefined,
            sourceDateTo: values.sourceDateTo
              ? toUTCDate(values.sourceDateTo).toISOString()
              : undefined,
            targetDateFrom: values.targetDateFrom
              ? toUTCDate(values.targetDateFrom).toISOString()
              : undefined,
            targetDateTo: values.targetDateTo
              ? toUTCDate(values.targetDateTo).toISOString()
              : undefined,
            weight: round(entry.weight, 2),
            height: entry.height,
            length: entry.length,
            width: entry.width,
            volume: round(entry.height * entry.width * entry.length, 2),
            value: values.value,
            shopPackagesNumber: values.shopPackagesNumber,
            vatPercent: values.vatPercent || undefined,
            deliveryPriceToWebshop: values.deliveryPriceToWebshop || undefined,
          };
        }),
        companyId: isCustomerService || isAdmin ? values.companyId : currentCompany?.id,
        sourceAddress: values.customSourceAddressCountry
          ? ({
              country: values.customSourceAddressCountry,
              zipCode: values.customSourceAddressZipCode,
              city: values.customSourceAddressCity,
              areaName: values.customSourceAddressAreaName,
              areaType: values.customSourceAddressAreaType,
              houseNumber: values.customSourceAddressHouseNumber,
              floor: values.customSourceAddressFloor,
            } as any)
          : addressListQuery.data?.find(entry => entry.id === values.sourceAddress) || undefined,
      });

      history.push("/package");
      setStatus(SliceStatus.Success);
      enqueueSnackbar(
        t("common:notification.add.success", {
          subject: t("package.subject"),
        }),
        { variant: "success" },
      );
    } catch (error) {
      setStatus(SliceStatus.Error);
      enqueueSnackbar(
        t("common:notification.add.failure", {
          subject: t("package.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  return (
    <Layout
      title={t("package.create")}
      maxWidth="lg"
      actionButton={
        <>
          {fileUpload && (
            //TODO Languages
            <Button
              onClick={async () => {
                const { data } = await getPackageSampleCSV();
                fileDownload(data, "Minta.csv");
              }}
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
              startIcon={<GetApp />}
            >
              {t("package.csvSample")}
            </Button>
          )}
          <FormControlLabel
            control={
              <Switch checked={fileUpload} onChange={(event, checked) => setFileUpload(checked)} />
            }
            label={t("package.csvUpload")}
            labelPlacement="end"
          />
        </>
      }
    >
      {fileUpload ? (
        <PackageFileUpload />
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onCreateSubmit)}>
            <PackageForm
              loading={status === SliceStatus.Loading}
              addressList={addressListQuery.data}
              currentCompany={currentCompany}
            />
          </form>
        </FormProvider>
      )}
    </Layout>
  );
};

export default PackageCreate;
