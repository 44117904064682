import Button from "components/form/Button";
import Layout from "components/layout/Layout";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { createUser } from "shared/network/user.api";
import UserForm, { UserFormValues } from "./components/UserForm";

const UserCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<UserFormValues>();

  const mutation = useMutation<any, any, UserFormValues>(
    async values => {
      await createUser({
        user: {
          ...values.user,
          birthDate: values.user?.birthDate
            ? format(values.user?.birthDate, "yyyy-MM-dd")
            : undefined,
          validityOfMedicalFitness: values.user.validityOfMedicalFitness.toISOString(),
          postAddress: values.samePostAddressWithPermanent
            ? values.user.permanentAddress
            : values.samePostAddressWithTemporary
            ? values.user.temporaryAddress
            : values.user.postAddress,
          temporaryAddress: values.user.temporaryAddress || undefined,
          relUserCompanies:
            values.user.relUserCompanies?.map(rel => ({
              ...rel,
              employeeNumber: values.employeeNumber,
              licenseCategory: values.licenseCategory,
              licenseNumber: values.licenseNumber,
              licenseExpirationDate: values.licenseExpirationDate
                ? format(values.licenseExpirationDate, "yyyy-MM-dd")
                : undefined,
            })) ?? [],
        },
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar(
          t("common:notification.add.success", {
            subject: t("userCreate.subject"),
          }),
          { variant: "success" },
        );
        history.push("/user-list");
      },
      onError: error => {
        if (error.data.status === "EMAIL_ALREADY_EXISTS") {
          enqueueSnackbar(t("userCreate.EMAIL_ALREADY_EXISTS"), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            t("common:notification.add.failure", {
              subject: t("userCreate.subject"),
            }),
            { variant: "error" },
          );
        }
      },
    },
  );

  return (
    <Layout title={t("user.create")} maxWidth="lg">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(values => mutation.mutate(values))}>
          <UserForm isModify={false} />
          <Button style={{ margin: 20 }} loading={mutation.isLoading} color="primary" type="submit">
            {t("common:button.create")}
          </Button>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default UserCreate;
