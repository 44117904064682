import axios from "config/axios";
import { GenericListResponse, Shop } from "shared/types";
import {
  EarlyDeliveriesType,
  LateDeliveriesType,
  SuccessfulAndFailedType,
} from "views/report/Report";

const ENDPOINT = "/report";

export const getLastMonthDelivery = () =>
  axios.post("/invoiceitem/last-month-delivery-for-shippers", null, {
    responseType: "blob",
  });

export const getPaymentOnDeliverySummary = (
  start: Date | string,
  end: Date | string,
  paymentDeliveryType: string,
) =>
  axios.post<GenericListResponse<{ shop: Shop; summary: number }>>(
    `${ENDPOINT}/payment-on-delivery-summary`,
    {
      start,
      end,
      paymentDeliveryType,
    },
  );

export const getLateDeliveries = (start: Date | string, end: Date | string) =>
  axios.post<LateDeliveriesType>(`${ENDPOINT}/late-deliveries`, {
    start,
    end,
  });

export const getEarlyDeliveries = (start: Date | string, end: Date | string) =>
  axios.post<EarlyDeliveriesType>(`${ENDPOINT}/early-deliveries`, {
    start,
    end,
  });

export const getCountSuccessfulAndFailed = (start: Date | string, end: Date | string) =>
  axios.post<SuccessfulAndFailedType>(`${ENDPOINT}/count-successful-and-failed`, {
    start,
    end,
  });
