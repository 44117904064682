import Button from "components/form/Button";
import Layout from "components/layout/Layout";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getUserById, modifyUser } from "shared/network/user.api";
import UserForm, { UserFormValues } from "./components/UserForm";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const UserModify = ({ match }: Props) => {
  const { id } = match.params;
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<UserFormValues>();

  const userQuery = useQuery("user", async () => {
    const { data } = await getUserById(Number.parseInt(id));
    return data.item;
  });

  const mutation = useMutation<any, any, UserFormValues>(
    async values => {
      await modifyUser({
        param: {
          ...userQuery.data,
          ...values.user,
          birthDate: values.user?.birthDate
            ? format(values.user?.birthDate, "yyyy-MM-dd")
            : undefined,
          validityOfMedicalFitness: values.user.validityOfMedicalFitness.toISOString(),
          permanentAddress: values.sameAddress
            ? values.user.temporaryAddress
            : values.user.permanentAddress,
          postAddress: values.sameAddress ? values.user.temporaryAddress : values.user.postAddress,
          relUserCompanies:
            values.user.relUserCompanies?.map(rel => ({
              ...rel,
              employeeNumber: values.employeeNumber,
              licenseCategory: values.licenseCategory,
              licenseNumber: values.licenseNumber,
              licenseExpirationDate: values.licenseExpirationDate
                ? format(values.licenseExpirationDate, "yyyy-MM-dd")
                : undefined,
            })) ?? [],
        },
      });
    },

    {
      onSuccess: () => {
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: t("userCreate.subject"),
          }),
          { variant: "success" },
        );
        history.push("/user-list");
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.update.failure", {
            subject: t("userCreate.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  useEffect(() => {
    if (userQuery.data) {
      const user = userQuery.data;
      const rel = user.relUserCompanies?.find(rel => rel.licenseNumber);
      form.reset({
        user: {
          ...user,
          birthDate: user.birthDate ? new Date(user.birthDate) : null,
          relUserCompanies: user.relUserCompanies ?? [],
          validityOfMedicalFitness: user.validityOfMedicalFitness
            ? new Date(user.validityOfMedicalFitness)
            : new Date(),
        },
        licenseCategory: rel?.licenseCategory ?? "",
        licenseNumber: rel?.licenseNumber ?? "",
        licenseExpirationDate: rel?.licenseExpirationDate
          ? new Date(rel.licenseExpirationDate)
          : new Date(),
        employeeNumber: rel?.employeeNumber ?? "",
      });
    }
  }, [userQuery.data]); //eslint-disable-line

  return (
    <Layout title={t("user.modify")} maxWidth="lg">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(values => mutation.mutate(values))}>
          <UserForm userId={Number.parseInt(id)} isModify={true} />
          <Button
            style={{ marginTop: 20 }}
            loading={mutation.isLoading}
            color="primary"
            type="submit"
          >
            {t("common:button.save")}
          </Button>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default UserModify;
