import { Link, ListItem, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { palette } from "config/theme";
import { Link as RouterLink } from "components/router";

export type Props = {
  to: string;
  text: string;
  open: boolean;
  icon?: React.ReactNode;
};

const MenuBarItem = ({ to, text, open, icon }: Props) => {
  return (
    <Link component={RouterLink} to={to} underline="none" style={{ color: "unset" }}>
      <Tooltip
        title={text}
        placement="bottom-start"
        disableHoverListener={open}
        disableFocusListener={open}
        disableTouchListener={open}
      >
        <ListItem button style={{ height: 48, minWidth: 250 }}>
          <ListItemIcon style={{ paddingLeft: 8, color: palette.main }}>{icon}</ListItemIcon>
          <ListItemText style={{ whiteSpace: "normal" }} primary={text} />
        </ListItem>
      </Tooltip>
    </Link>
  );
};

export default MenuBarItem;
