import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "shared/util/validation";
import { enqueueSnackbar } from "shared/reducers/application";
import { useHistory } from "react-router-dom";
import UnauthorizedLayout from "components/layout/UnauthorizedLayout";
import { forgottenPasswordStart } from "shared/network/user.api";
import Button from "components/form/Button";

type EmailForm = {
  email: string;
};

const ForgottenPassword = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm<EmailForm>();
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  async function onSubmit(values: EmailForm) {
    setLoading(true);
    try {
      await forgottenPasswordStart(values.email);
      dispatch(
        enqueueSnackbar({
          message: t("forgottenPassword.sentEmail"),
          options: { variant: "success" },
        }),
      );
    } catch (e) {
      setError(e?.data?.status);
    }
    setLoading(false);
  }

  return (
    <UnauthorizedLayout title={t("forgottenPassword.title")} maxWidth="md">
      {error && (
        <Alert severity="error">
          {t([`forgottenPassword.error.${error}`, "forgottenPassword.error.failure"])}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Box display="flex" justifyContent="center">
          <TextField
            name="email"
            label={t("common:field.email")}
            type="text"
            inputRef={register({
              required: {
                value: true,
                message: t("validation:required"),
              },
              pattern: {
                value: EMAIL_REGEX,
                message: t("validation:invalidFormat", {
                  subject: t("validation:caption.subject.email"),
                }),
              },
            })}
            style={{ maxWidth: 400 }}
            error={errors.email && true}
            helperText={errors.email?.message}
          />
        </Box>
        <div style={{ marginTop: 14 }}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
            p={2}
            pr={10}
            pl={10}
          >
            <Link onClick={history.goBack} style={{ cursor: "pointer" }}>
              {t("common:button.back")}
            </Link>
            <Button variant="contained" type="submit" color="primary" loading={loading}>
              {t("common:button.submit")}
            </Button>
          </Box>
        </div>
      </form>
    </UnauthorizedLayout>
  );
};

export default ForgottenPassword;
