import { Divider, Grid, IconButton, makeStyles, Tooltip, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Warehouse } from "shared/types";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "components/router";
import displayAddress from "shared/util/displayAddress";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import usePermissions from "shared/hooks/usePermissions";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import { useState } from "react";
import ModifyCoordinates from "components/ModifyCoordinates";

type Props = {
  warehouse: Warehouse;
  index: number;
  array: Warehouse[];
};

const useStyles = makeStyles(theme => ({
  color: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const WarehouseListItem = ({ warehouse, index, array }: Props) => {
  const { t } = useTranslation();

  const { isAdmin } = usePermissions();
  const [open, setOpen] = useState<"current" | "target" | "source" | boolean>(false);

  return (
    <>
      <Grid
        key={warehouse.id}
        container
        style={{
          alignItems: "center",
          padding: "4px 0",
        }}
        className={useStyles().color}
      >
        <Grid item xs={2}>
          <Typography>{warehouse.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{warehouse.address?.country}</Typography>
        </Grid>
        <Grid item xs={3}>
          {isAdmin ? (
            <Box display="flex" alignItems="center">
              <Tooltip title={t("map.coordinatesModify").toString()}>
                <IconButton size="small" onClick={() => setOpen(true)}>
                  <EditLocationIcon />
                </IconButton>
              </Tooltip>
              <Typography>{displayAddress(warehouse.address)}</Typography>
              <ModifyCoordinates address={warehouse.address} open={open} setOpen={setOpen} />
            </Box>
          ) : (
            <Typography>{displayAddress(warehouse.address)}</Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <Typography>{`${t("common:number", {
            num: warehouse.space,
          })} m²`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{`${t("common:number", {
            num: warehouse.height,
          })} m`}</Typography>
        </Grid>
        <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
          <>
            {isAdmin && (
              <Tooltip title={t("common:button.modify").toString()}>
                <IconButton component={Link} to={`/warehouse/modify/${warehouse.id}`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t("common:button.details").toString()}>
              <IconButton component={Link} to={`/warehouse/details/${warehouse.id}`}>
                <DescriptionOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default WarehouseListItem;
