import Grid from "@material-ui/core/Grid";
import Button from "components/form/Button";
import useModal from "shared/hooks/useModal";
import Layout from "components/layout/Layout";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ChangePasswordModal from "./components/ChangePasswordModal";
import { User } from "shared/types";
import { Link } from "components/router";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const Profile = ({ match }: Props) => {
  const { t } = useTranslation();
  const id = match.params.id;

  const user = useSelector((state: RootState) => state.authentication.user);

  const { state, modalOpen, modalClose } = useModal<User>();

  const birthDate = user?.birthDate.split("-");

  return (
    <Layout
      title={t("profile.title")}
      actionButton={
        <>
          {user?.id.toString() === id && (
            <>
              <Button
                color="primary"
                type="submit"
                variant="outlined"
                onClick={() => modalOpen("modify", user)}
              >
                {t("common:button.changePassword")}
              </Button>
              <ChangePasswordModal state={state} onClose={modalClose} />
            </>
          )}
          <Tooltip title={t("profile.modify.title").toString()}>
            <IconButton size="medium" component={Link} to={`/profile/modify/${user?.id}`}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold" }}>{t("profile.name")}</Typography>
          <Typography>
            {user?.firstName} {user?.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold" }}>Fiók típusa</Typography>
          <Typography style={{ textTransform: "uppercase" }}>
            {t(`common:userType.${user?.userType}`)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold" }}>{t("profile.email")}</Typography>
          <Typography>{user?.email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold" }}>{t("profile.phone")}</Typography>
          <Typography>{user?.phone}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold" }}>{t("profile.birthDate")}</Typography>
          <Typography>{birthDate && `${birthDate[0]}.${birthDate[1]}.${birthDate[2]}.`}</Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Profile;
