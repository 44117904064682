import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Warehouse } from "shared/types";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { userList } from "shared/network/user.api";
import { linkUserToWarehouse } from "shared/network/warehouse.api";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type Props = {
  warehouse: Warehouse;
};

const WarehouseUserModal = ({ warehouse }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("");

  const usersQuery = useQuery("userListQueryWarehouseUserModal", async () => {
    const { data } = await userList();
    return data.items;
  });

  const mutation = useMutation<
    AxiosResponse<{ status: string }>,
    AxiosResponse<{ status: string }>,
    number
  >(
    async userId => {
      return await linkUserToWarehouse({ userId, warehouseId: warehouse.id });
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t("warehouse.userlink.success"), {
          variant: "success",
        });
        setOpen(false);
      },
      onError: response => {
        enqueueSnackbar(t(`warehouse.userlink.error.${response.data.status}`), {
          variant: "error",
        });
      },
    },
  );

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined" color="primary">
        {t("userList.title")}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>{t("warehouse.addUserToWarehouse")}</DialogTitle>
        <DialogContent>
          <TextField
            value={userFilter}
            onChange={e => setUserFilter(e.target.value)}
            label={t("common:button.search")}
          />
          {usersQuery.data
            ?.filter(
              user =>
                `${user.lastName} ${user.firstName}`
                  .toUpperCase()
                  .includes(userFilter.toUpperCase()) ||
                user.email.toUpperCase().includes(userFilter.toUpperCase()),
            )
            .map(user => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gridGap={16}
                mt={1}
                key={user.id}
              >
                <Box display="flex" justifyContent="space-between" gridGap={32} flex={1}>
                  <div>
                    {user.lastName} {user.firstName}
                  </div>
                  <div>{user.email}</div>
                </Box>
                <Button onClick={() => mutation.mutate(user.id)} color="primary">
                  {t("common:button.connect")}
                </Button>
              </Box>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Mégse</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WarehouseUserModal;
