import * as React from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import ArticleForm, { ArticleFormValues } from "../components/ArticleForm/ArticleForm";
import { enqueueSnackbar } from "shared/reducers/application";
import { getArticleById, modifyArticle } from "shared/network/article.api";
import Layout from "components/layout/Layout";
import { Box } from "@material-ui/core";
import Loading from "components/Loading";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const ModifyArticle = ({ match, history }: Props) => {
  const id = match.params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const articleQuery = useQuery(["article", id], async () => {
    const { data } = await getArticleById(Number.parseInt(id));
    return data.item;
  });
  const { isLoading, isIdle } = articleQuery;

  async function onSubmit(values: ArticleFormValues) {
    setLoading(true);
    try {
      await modifyArticle({ id: articleQuery.data?.id, ...values });
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.update.success", {
            subject: t("article.subject"),
          }),
          options: { variant: "success" },
        }),
      );
      history.replace("/article");
      setLoading(false);
    } catch (e) {
      dispatch(
        enqueueSnackbar({
          message: t("common:notification.update.failure", {
            subject: t("article.subject"),
          }),
          options: { variant: "error" },
        }),
      );
      setLoading(false);
    }
  }

  return (
    <Layout title={t("article.modify")} maxWidth="lg">
      {isLoading || isIdle ? (
        <Loading />
      ) : (
        <Box display="flex" flexDirection="column" width="100%">
          <ArticleForm onSubmit={onSubmit} isLoading={loading} defaultValues={articleQuery.data} />
        </Box>
      )}
    </Layout>
  );
};

export default ModifyArticle;
